import React, { FC, useEffect, useState } from 'react';
import { Password, Tags, InputT } from '@gourban/ui-components';
import { t, Trans } from '@lingui/macro';
import { useField } from 'formik';

interface PasswordCheckerT {
  name: string;
  fieldProps?: InputT['fieldProps'];
  fieldAttr?: Partial<InputT['fieldAttr']>;
}

const PasswordChecker: FC<PasswordCheckerT> = ({ name, fieldProps, fieldAttr }) => {
  const [field] = useField(name);

  const [passwordChecker, setPasswordChecker] = useState({
    hasUppercase: false,
    hasLowercase: false,
    hasNumber: false,
  });

  useEffect(() => {
    const hasUppercase = /[A-Z]/.test(field.value);
    const hasLowercase = /[a-z]/.test(field.value);
    const hasNumber = /[0-9]/.test(field.value);

    setPasswordChecker({
      hasUppercase,
      hasLowercase,
      hasNumber,
    });
  }, [field.value]);

  return (
    <Password
      name={name}
      fieldAttr={{
        id: name,
        placeholder: t({ id: 'auth.forms.password', message: 'Password' }),
        autoComplete: 'password',
        required: true,
        ...(fieldAttr ?? {}),
      }}
      fieldProps={{
        helperText: (
          <>
            <Trans id="components.passwordChecker.helperText">
              Choose a password with at least{' '}
            </Trans>{' '}
            <Tags
              bgColor={passwordChecker.hasUppercase ? 'var(--g-100)' : undefined}
              size="tiny"
              style="squared"
              content={<Trans id="components.passwordChecker.oneUppercase">one upper case</Trans>}
            />
            <Tags
              bgColor={passwordChecker.hasLowercase ? 'var(--g-100)' : undefined}
              size="tiny"
              style="squared"
              content={<Trans id="components.passwordChecker.oneLowercase">one lower case</Trans>}
            />
            <Tags
              size="tiny"
              style="squared"
              bgColor={passwordChecker.hasNumber ? 'var(--g-100)' : undefined}
              content={<Trans id="components.passwordChecker.oneNumber">one number</Trans>}
            />
          </>
        ),
        size: 'large',
        label: t({ id: 'auth.forms.password', message: 'Password' }),
        ...(fieldProps ?? {}),
      }}
    />
  );
};

export default PasswordChecker;
