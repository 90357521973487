import { Route } from '@/core/types';
import {
  myTrips,
  myActiveTrips,
  myUpcomingTrips,
  myPastTrips,
  tripDrivingLog,
  tripDrivingLogCreate,
  tripDrivingLogEdit,
  myCanceledTrips,
} from './routes';
import MyTrips from '@/core/pages/MyTrips/MyTrips';
import DrivingLog from '@/core/pages/MyTrips/DrivingLog';
import MyTripsList from '@/features/myTrips/components/Trips/MyTripsList';
import TripDrivingLogCreate from '@/features/myTrips/components/TripDrivingLog/TripDrivingLogCreate';
import TripDrivingLogEdit from '@/features/myTrips/components/TripDrivingLog/TripDrivingLogEdit';

const myTripsRoutes: Route = {
  ...myTrips,
  element: <MyTrips />,
  routes: [
    { ...myActiveTrips, element: <MyTripsList view="ACTIVE" key="active" /> },
    { ...myPastTrips, element: <MyTripsList view="PAST" key="past" /> },
    { ...myUpcomingTrips, element: <MyTripsList view="UPCOMING" key="upcoming" /> },
    { ...myCanceledTrips, element: <MyTripsList view="CANCELED" key="canceled" /> },
  ],
};

const tripDrivingLogRoutes: Route = {
  ...tripDrivingLog,
  element: <DrivingLog />,
  routes: [
    { ...tripDrivingLogCreate, element: <TripDrivingLogCreate /> },
    { ...tripDrivingLogEdit, element: <TripDrivingLogEdit /> },
  ],
};

export default [myTripsRoutes, tripDrivingLogRoutes];
