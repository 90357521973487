import { useMemo } from 'react';
import { useTypedSelector } from '@/core/redux/hooks';
import { getStepsStatus } from '@/features/account/redux/account.selectors';

export interface StepsStatus {
  completed: number;
  total: number;
}

export const useStepStatus = (): StepsStatus => {
  const steps = useTypedSelector(getStepsStatus);

  return useMemo(() => {
    return {
      completed: Object.values(steps).filter((step) => step.isComplete).length,
      total: Object.keys(steps).length,
    };
  }, [steps]);
};
