import React from 'react';
import MainMenuItem from './MainMenuItem';
import cssModules from '@/core/assets/scss/components/Dashboard/MainMenu.module.scss';
import MainMenuParentItem from './MainMenuParentItem';
import {
  businessAccountCompanySubroutes,
  businessAccountTeamSubroutes,
} from '@/core/pages/BusinessAccounts/routerConfig';
import { t } from '@lingui/macro';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';

const MainMenu = () => {
  const { data: settings } = useRequestBookingSettingQuery();

  return (
    <nav className={cssModules['main-navigation']}>
      <ul>
        {settings?.value.booking && (
          <MainMenuItem
            to="/business-account/trips"
            content={t({ id: 'general.mainMenu.bookings', message: 'Pre-booked trips' })}
            icon="calendar"
          />
        )}
        {settings?.value.freeFloating && (
          <MainMenuItem
            to="/business-account/on-demand-trips"
            content={t({ id: 'general.mainMenu.onDemand', message: 'On-demand trips' })}
            icon="trips"
          />
        )}

        <MainMenuParentItem
          path="/business-account/team"
          content={t({ id: 'general.mainMenu.team', message: 'Team' })}
          icon="users"
          subRoutes={businessAccountTeamSubroutes}
        />

        <MainMenuItem
          to="/business-account/cost-centers"
          content={t({ id: 'general.mainMenu.costCenters', message: 'Cost centers' })}
          icon="chartAscending"
        />

        <MainMenuParentItem
          path="/business-account/company"
          content={t({ id: 'general.mainMenu.company', message: 'Company' })}
          icon="buildings"
          subRoutes={businessAccountCompanySubroutes}
        />
      </ul>
    </nav>
  );
};

export default MainMenu;
