import { boolean, object, string } from 'yup';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';

const phoneNumberRegex = /^[+]?\d{10,15}$/;
export const phoneLoginSchema = object().shape({
  terms: boolean()
    .required(() => CommonValidationTranslations.requiredField())
    .oneOf([true], () => CommonValidationTranslations.requiredField()),
  privacy: boolean()
    .required(() => CommonValidationTranslations.requiredField())
    .oneOf([true], () => CommonValidationTranslations.requiredField()),
  phone: string()
    .required(() => CommonValidationTranslations.requiredField())
    .matches(phoneNumberRegex, () => CommonValidationTranslations.invalidPhoneNumber()),
});
