import { entries, isEmpty } from 'lodash';
import { Checkbox } from '@gourban/ui-components';
import React from 'react';
import { useRequestWhitelabelQuery } from '@/core/services/WhiteLabeling.service';
import MarkdownParser from '@/core/components/MarkdownParser';

const NewsletterCheckboxes = () => {
  const { data: whiteLabeling } = useRequestWhitelabelQuery();

  if (isEmpty(whiteLabeling?.newsletters)) {
    return null;
  }

  return (
    <>
      {entries(whiteLabeling?.newsletters)
        .filter((nl) => nl[1].enabled)
        .map(([key, { text }]) => {
          return (
            <Checkbox
              key={key}
              name={`newsletterOptions.${key}`}
              fieldAttr={{
                id: `receiveNewsletter_${key}`,
              }}
              fieldProps={{
                label: <MarkdownParser marginBottom={0} markdown={text} />,
              }}
            />
          );
        })}
    </>
  );
};

export default NewsletterCheckboxes;
