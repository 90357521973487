import { Outlet } from 'react-router-dom';
import { Route } from '@/core/types';
import { auth, login, register, forgotPassword } from '@/core/pages/Auth/routes';
import Login from '@/core/pages/Auth/Login';
import ForgotPassword from '@/core/pages/Auth/ForgotPassword';
import Register from '@/core/pages/Auth/Register';

const authRoutes: Route = {
  ...auth,
  element: <Outlet />,
  routes: [
    {
      ...login,
      element: <Login />,
    },
    {
      ...forgotPassword,
      element: <ForgotPassword />,
    },
    {
      ...register,
      element: <Register />,
    },
  ],
};

export default [authRoutes];
