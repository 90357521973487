import { formatResponse } from '@/core/api/formatAPIResponse';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { Invoice, InvoiceArgs, InvoiceDownloadArgs, TransformedResponse } from '@/core/types';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

const BASE_PATH = '/front/invoices';

export const InvoiceService = createApi({
  reducerPath: 'api-invoice',
  baseQuery: RestClientWrapper,
  tagTypes: [],
  endpoints: (builder) => ({
    requestInvoices: builder.query<TransformedResponse<Invoice[]>, InvoiceArgs>({
      query: ({ userGroupId, page }) => ({
        url: BASE_PATH,
        requestParams: {
          params: {
            userGroupId,
            page,
            size: 10,
            invoicedOnly: true,
          },
        },
      }),
      transformResponse: formatResponse,
    }),

    requestInvoiceDownloadUrl: builder.query<string, InvoiceDownloadArgs>({
      query: ({ invoiceId, userGroupId }) => ({
        url: `${BASE_PATH}/${invoiceId}/download`,
        requestParams: userGroupId
          ? {
              params: {
                userGroupId,
              },
            }
          : {},
      }),
    }),
  }),
});

export const { useRequestInvoicesQuery, useLazyRequestInvoiceDownloadUrlQuery } = InvoiceService;
