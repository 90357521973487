import { RouteObject } from 'react-router-dom';
import { Route } from '@/core/types';
import ComposeScreen from '@/core/components/ComposeScreen';

export const getChildRoutes = (routeArray: Route[]): RouteObject[] =>
  routeArray.reduce<RouteObject[]>((allRoutes, routes) => {
    // As these two functions are recursive, this rule has to be ignored
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const routesArr = getRoutesForRouter(routes);
    return [...allRoutes, ...routesArr];
  }, []);

const routeFactory = (path: Route['path'], route: Route): RouteObject => ({
  path,
  element: <ComposeScreen route={route} />,
});

const getRoutesForRouter = (route: Route): RouteObject[] => {
  const { path, additionalPaths } = route;
  const mainRoute = routeFactory(path, route);
  if (route.routes && route.routes.length > 0) {
    mainRoute.children = getChildRoutes(route.routes);
  }

  const otherRoutes = (additionalPaths || []).map((additionalPath) =>
    routeFactory(additionalPath, route),
  );

  return [mainRoute, ...otherRoutes];
};
