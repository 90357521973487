import React, { FC } from 'react';
import { useLazyRequestCountryGeocodingQuery } from '@/features/geomap/services/Map.service';
import { AutocompleteSelect, FormSelectComponent, Autocomplete } from '@gourban/ui-components';
import { t } from '@lingui/macro';
import { Autocomplete as AutocompleteComponent } from '@gourban/ui-components/dist/lib/components/FormElements/Autocomplete/Autocomplete.types';

interface CountrySelectT {
  name?: string;
  fieldProps?: Partial<FormSelectComponent<Autocomplete>['fieldProps']>;
  fieldAttr?: Partial<FormSelectComponent<AutocompleteComponent>['fieldAttr']>;
  additionalMapping?: Record<string, string>;
  readonly?: boolean;
}

const CountrySelect: FC<CountrySelectT> = ({
  name = 'country',
  fieldProps = {
    label: t({ id: 'geomap.countrySelect.label', message: 'Country' }),
    clearable: true,
  },
  fieldAttr = { placeholder: t({ id: 'geomap.countrySelect.placeholder', message: 'Country' }) },
  additionalMapping = {},
  readonly,
}) => {
  const [requestCountries] = useLazyRequestCountryGeocodingQuery();

  return (
    <AutocompleteSelect
      name={name}
      fieldAttr={{ id: 'country', ...fieldAttr, readonly }}
      fieldProps={{
        label: '',
        minSearchCharacters: 2,
        onChange: fieldProps?.onChange,
        options: {
          getData: (query) => requestCountries(query).unwrap(),
          mapData: {
            value: 'place_name',
            label: 'place_name',
            additional: additionalMapping,
          },
        },
        ...fieldProps,
      }}
    />
  );
};

export default CountrySelect;
