import React, { FC, PropsWithChildren, ReactNode } from 'react';
import FullScreen from '@/core/components/Layouts/FullScreen';
import { Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { booking } from '@/core/pages/Booking/routes';
import LayoutAside from '@/features/account/components/Edit/LayoutAside';

interface AccountEditLayout {
  actions?: ReactNode;
}

const Layout: FC<PropsWithChildren<AccountEditLayout>> = ({ children, actions }) => {
  const navigate = useNavigate();

  return (
    <FullScreen
      mainSize="medium"
      aside={<LayoutAside />}
      actions={actions}
      onClose={() => navigate(booking.path)}
      title={<Trans id="accountEdit.title">Account</Trans>}
      main={children}
    />
  );
};

export default Layout;
