import { useMemo } from 'react';
import { VehicleCategory } from '@/features/vehicles/types';
import { useRequestAllCategoriesQuery } from '@/features/vehicles/services/Vehicles.service';
import { keyBy } from 'lodash';

export const useVehicleCategories = (): Record<number, VehicleCategory> | null => {
  const { data } = useRequestAllCategoriesQuery();

  return useMemo(() => (data ? keyBy(data, 'id') : null), [data]);
};
