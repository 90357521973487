import { Form, Formik } from 'formik';
import { t, Trans } from '@lingui/macro';
import { Button, Column, PhoneNumber, Row } from '@gourban/ui-components';
import React, { FC } from 'react';
import AcceptTermsAndConditions from '@/features/auth/components/AcceptTermsAndConditions';
import { useRequestBranchTermsAndConditionsQuery } from '@/features/branches/services/Branches.service';
import { phoneLoginSchema } from '@/features/auth/utils/validations/phoneLogin.schema';
import DisclaimerText from '@/features/auth/components/DisclaimerText';

export interface PhoneLoginFormValues {
  phone: string;
  newsletterOptions?: any;
  code?: string;
}

export interface PhoneLoginFormProps {
  onSubmit: (values: PhoneLoginFormValues) => Promise<void>;
  branchId?: number;
}

const PhoneLoginForm: FC<PhoneLoginFormProps> = ({ onSubmit, branchId }) => {
  const { data: branchTerms } = useRequestBranchTermsAndConditionsQuery(branchId!, {
    skip: !branchId,
  });

  return (
    <Formik initialValues={{ phone: '' }} onSubmit={onSubmit} validationSchema={phoneLoginSchema}>
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Form>
            <PhoneNumber
              fieldAttr={{
                id: 'phone',
                placeholder: t({
                  id: 'account.basicInfo.phonePlaceholder',
                  message: 'Phone number',
                }),
                required: true,
              }}
              name="phone"
              fieldProps={{
                size: 'large',
                label: t({
                  id: 'account.basicInfo.phoneLabel',
                  message: 'Phone number',
                }),
              }}
            />
            <AcceptTermsAndConditions
              termsAndConditionsUrl={branchTerms?.termsAndConditions!.termsAndConditionsUrl}
              privacyGuidelinesUrl={branchTerms?.termsAndConditions!.privacyGuidelinesUrl}
            />
            <DisclaimerText />

            <Row>
              <Column sm={12}>
                <Button
                  width="full"
                  size="large"
                  attributes={{ type: 'submit' }}
                  disabled={!dirty || !isValid}
                  loading={isSubmitting}
                >
                  <Trans id="auth.phoneLogin.submit">Send code</Trans>
                </Button>
              </Column>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PhoneLoginForm;
