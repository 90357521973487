import { useRequestSingleBranchQuery } from '@/features/branches/services/Branches.service';
import { useTypedSelector } from '@/core/redux/hooks';
import { getActiveFilterValues } from '@/features/account/redux/account.selectors';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { useMemo } from 'react';
import { TermsAndConditionsStatus } from '@/features/auth/types/User.types';
import { BookingExtended, BookingOverviewForm } from '@/features/booking/types';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { useRequestBookingByIdQuery } from '@/features/booking/services/Booking.service';
import { format } from 'date-fns';
import { useRequestPaymentSourcesQuery } from '@/features/payment/services/Payment.service';

interface BookingPreparationReturnValue {
  isFetchingBookingData: boolean;
  bookingInitialValues: BookingOverviewForm;
  isError?: boolean;
  bookingData?: BookingExtended;
}

/**
 * Initial values and initial data in general for booking are coming form different sources
 * that depends on each other, so this hooks helps to prepare all the data needed for booking
 */
export const useBookingPreparation = (
  branchId?: string,
  bookingId?: string,
  termsStatus?: TermsAndConditionsStatus,
): BookingPreparationReturnValue => {
  const {
    data: branchData,
    isFetching: isFetchingBranchData,
    isError: branchFetchingError,
  } = useRequestSingleBranchQuery(branchId!, {
    skip: termsStatus !== 'ACCEPTED',
  });
  const {
    data: bookingData,
    isLoading: isLoadingBookingData,
    isError: errorFetchingBooking,
  } = useRequestBookingByIdQuery({ id: Number(bookingId) }, { skip: !bookingId });

  const { data: personalPayments } = useRequestPaymentSourcesQuery(
    {
      branchId: +branchId!,
    },
    { skip: !branchId },
  );

  const activeFilters = useTypedSelector(getActiveFilterValues);

  const {
    data: bookingSettings,
    isFetching: isFetchingBookingSettings,
    isError: errorFetchingBookingSettings,
  } = useRequestBookingSettingQuery(undefined, {
    skip: !branchData,
  });

  const initialValues = useMemo<BookingOverviewForm>(() => {
    if (bookingData && bookingId) {
      const start = new Date(bookingData.startTime);
      const end = new Date(bookingData.endTime);

      return {
        bookingDate: {
          from: start,
          to: end,
        },
        bookingTime: {
          from: format(start, 'HH:mm'),
          to: format(end, 'HH:mm'),
        },
        selectedPaymentType: bookingData ? bookingData.rentalType : undefined,
        selectedPaymentMethod:
          bookingData?.rentalType === 'BUSINESS'
            ? bookingData.userGroupCode
            : personalPayments?.[0]?.id,
        addition: bookingData.additions,
        reason: bookingData?.reason,
        selectedAdditions: bookingData.additions,
        selectedVehicleCategory: bookingData.vehicleCategoryId,
      };
    }

    return {
      bookingDate: activeFilters?.startTime
        ? {
            from: new Date(activeFilters.startTime),
            to: new Date(activeFilters.endTime!),
          }
        : undefined,
      bookingTime: activeFilters?.startTime
        ? {
            from: format(new Date(activeFilters.startTime), 'HH:mm'),
            to: format(new Date(activeFilters.endTime!), 'HH:mm'),
          }
        : {
            from: formatInitialTime(
              bookingSettings?.value?.properties?.preselectedStartingTime,
              60,
              bookingSettings?.value?.properties?.timeGap,
            ),
            to: formatInitialTime(
              bookingSettings?.value?.properties?.preselectedEndingTime,
              120,
              bookingSettings?.value?.properties?.timeGap,
            ),
          },
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSettings, branchId, bookingData, activeFilters]);

  return {
    isFetchingBookingData:
      isFetchingBranchData || isFetchingBookingSettings || !!(branchId && isLoadingBookingData),
    bookingInitialValues: initialValues,
    isError: branchFetchingError || errorFetchingBookingSettings || errorFetchingBooking,
    bookingData,
  };
};
