/**
 * @param name - string
 * @param limit - number
 * @returns Initials of a given name
 */
export const getInitials = (name: string, limit: number = 2): string => {
  return name
    .split(' ')
    .slice(0, limit)
    .map((chunk) => chunk.charAt(0).toUpperCase())
    .join('');
};
