import EmailAuthentication from '@/core/pages/Auth/EmailAuthentication';
import PhoneAuthentication from '@/core/pages/Auth/PhoneAuthentication';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { SettingsKeys } from '@/core/enums';

const Login = () => {
  const loginSettings = useTypedSelector((state) => getSetting(state, SettingsKeys.LOGIN));

  if (loginSettings?.phone) {
    return <PhoneAuthentication />;
  }

  return <EmailAuthentication />;
};

export default Login;
