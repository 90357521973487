import React, { FC } from 'react';
import { Addition } from '@/features/vehicles/types';
import { Card, Column, Paragraph, Row } from '@gourban/ui-components';
import styles from '../../assets/scss/components/AdditionCard.module.scss';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import cn from 'classnames';
import AdditionPriceBreakdown from './AdditionPriceBreakdown';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import CurrencyDisplay from '@/core/components/CurrencyDisplay';

interface AdditionCardT {
  addition: Addition;
  currency?: string;
}

const AdditionCard: FC<AdditionCardT> = ({ addition, currency }) => {
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { showGrossPrice } = bookingSettings?.value ?? {};
  const { setFieldValue, values } = useFormikContext<BookingOverviewForm>();
  const estimatedTotal = addition.pricingInformation.estimation;

  const onAdditionSelection = () => {
    if (!values?.selectedAdditions) {
      void setFieldValue('selectedAdditions', [addition.code]);
      return;
    }

    if (values?.selectedAdditions?.includes(addition.code)) {
      void setFieldValue(
        'selectedAdditions',
        values.selectedAdditions.filter((additionCode) => additionCode !== addition.code),
      );

      return;
    }

    void setFieldValue('selectedAdditions', [...values.selectedAdditions, addition.code]);
  };

  const additionSelected = values?.selectedAdditions?.includes(addition.code);

  return (
    <Column sm={12}>
      <Card
        onClick={() => onAdditionSelection()}
        className={cn(styles.card, additionSelected && styles['card--active'])}
      >
        <Row marginBottom={8} justify="space-between">
          <Column>
            <Paragraph marginBottom={0} weight="regular">
              {addition.name}
            </Paragraph>
          </Column>
          <Column>
            <Paragraph className={styles.card__price} marginBottom={0} size={2} weight="regular">
              <AdditionPriceBreakdown addition={addition} currency={currency} />

              <CurrencyDisplay
                value={showGrossPrice ? estimatedTotal.gross : estimatedTotal.net}
                currency={currency!}
              />
              <span
                className={cn(
                  styles.card__checkbox,
                  additionSelected && styles['card__checkbox--checked'],
                )}
              />
            </Paragraph>
          </Column>
        </Row>
        {addition.description && (
          <Paragraph size={3} color="var(--gs-500)" marginBottom={0}>
            {addition.description}
          </Paragraph>
        )}
      </Card>
    </Column>
  );
};

export default AdditionCard;
