import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthenticationLayout from '@/features/auth/components/AuthenticationLayout';
import { login } from '@/core/pages/Auth/routes';
import { Paragraph, Button } from '@gourban/ui-components';
import ForgotPasswordForm from '@/features/auth/components/ForgotPasswordForm';
import { Trans } from '@lingui/macro';

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const [forgotPasswordSent, setForgotPasswordSent] = useState(false);

  return (
    <AuthenticationLayout
      title={
        forgotPasswordSent ? (
          <Trans id="auth.forgotPasswordForm.emailSent">E-mail sent</Trans>
        ) : (
          <Trans id="auth.forgotPasswordForm.pageTitle">Forgot password</Trans>
        )
      }
      form={
        forgotPasswordSent ? (
          <Paragraph size={2} marginBottom={24} align="center">
            <Trans id="auth.forgotPasswordForm.emailSent.title">
              Please check your email inbox and open the link we sent.
            </Trans>
          </Paragraph>
        ) : (
          <>
            <Paragraph size={2}>
              <Trans id="auth.forgotPasswordForm.action">
                Enter your email and we'll send you a link to reset your password.
              </Trans>
            </Paragraph>
            <ForgotPasswordForm onForgotPasswordSuccess={() => setForgotPasswordSent(true)} />
          </>
        )
      }
      footer={
        forgotPasswordSent ? (
          <Button variation="secondary" onClick={() => navigate(login.path)}>
            <Trans id="auth.forgotPasswordForm.backToLogin">Back to Login</Trans>
          </Button>
        ) : (
          <Link to={login.path}>
            <Trans id="auth.forgotPasswordForm.backToLogin">Back to Login</Trans>
          </Link>
        )
      }
      alignCenter={forgotPasswordSent}
    />
  );
};

export default ForgotPassword;
