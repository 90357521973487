import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, Paragraph, Row } from '@gourban/ui-components';
import styles from '@/features/businessAccounts/assets/scss/Restrictions/Approval.module.scss';
import { useNavigate } from 'react-router-dom';
import Banner from '@/core/components/Banner';
import useFeatureCheck from '@/features/auth/hooks/useFeatureCheck';
import { TenantFeatures } from '@/features/auth/enums';
import useMailtoLink from '@/core/hooks/useMailtoLink';
import PageContent from '@/core/components/PageContent';

const ApprovalPending = () => {
  const navigate = useNavigate();
  const { mailto, email } = useMailtoLink();
  const hasFeature = useFeatureCheck();

  return (
    <PageContent>
      <Banner
        className={styles.approval}
        header={<Trans id="businessAccounts.approvalPending.title">Approval pending</Trans>}
        message={
          <Row justify="center">
            <Paragraph marginBottom={16} align="center" size={2}>
              <Trans id="businessAccounts.approvalPending.description">
                Your account is currently under review and has not been approved yet. We aim to
                complete the review process within 48 hours. Once approved, you will gain full
                access to all features and functionalities.
              </Trans>
            </Paragraph>
            <Paragraph marginBottom={32} align="center" size={2}>
              <Trans id="businessAccounts.approvalPending.contactSupport">
                For any urgent inquiries, please contact our support team at{' '}
                <a href={mailto}>{email}</a>.
              </Trans>
            </Paragraph>

            {hasFeature(TenantFeatures.ENDUSER_WEB_APP) && (
              <Button onClick={() => navigate('/')}>
                <Trans id="businessAccount.approvalPending.booking">Booking</Trans>
              </Button>
            )}
          </Row>
        }
        icon="profile"
        iconColor="var(--gs-900)"
      />
    </PageContent>
  );
};

export default ApprovalPending;
