export enum BusinessAccountCacheTags {
  COST_CENTERS = 'COST_CENTERS',
  COST_CENTER = 'COST_CENTER',
  BUSINESS_ACCOUNT = 'BUSINESS_ACCOUNT',
  BUSINESS_ACCOUNT_MEMBERSHIP = 'BUSINESS_ACCOUNT_MEMBERSHIP',
  JOIN_REQUESTS = 'JOIN_REQUESTS',
  JOIN_REQUESTS_MEMBERSHIP = 'JOIN_REQUESTS_MEMBERSHIP',
  MEMBER = 'MEMBER',
  MEMBERS = 'MEMBERS',
}
