import React, { FC } from 'react';
import { RegisterArgs } from '@/features/auth/types/Auth.types';
import { Alert, Button, Column, Loader, Row, Text } from '@gourban/ui-components';
import { Form, Formik } from 'formik';
import { registerSchema } from '@/features/auth/utils/validations/register.schema';
import { useRequestRegisterMutation } from '@/core/features/account/services/Account.service';
import {
  useRequestBranchTermsAndConditionsQuery,
  useRequestResolvedBranchQuery,
} from '@/core/features/branches/services/Branches.service';
import { ApiError } from '@/core/types';
import AcceptTermsAndConditions from '@/features/auth/components/AcceptTermsAndConditions';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import NewsletterCheckboxes from '@/features/auth/components/NewsletterCheckboxes';
import DisclaimerText from '@/features/auth/components/DisclaimerText';
import PasswordChecker from '@/core/components/UI/PasswordChecker';

interface FormValues extends Omit<RegisterArgs, 'branchId'> {
  terms: boolean;
  privacy: boolean;
}

export interface RegisterFormT {
  onSuccessfullyRegistered: (email: string) => void;
}

const RegisterForm: FC<RegisterFormT> = ({ onSuccessfullyRegistered }) => {
  const { data: resolvedBranch, isLoading: isBranchLoading } = useRequestResolvedBranchQuery();
  const { data: branchTerms, isLoading: areTermsLoading } = useRequestBranchTermsAndConditionsQuery(
    resolvedBranch?.id!,
    { skip: isBranchLoading || !resolvedBranch },
  );

  const [register, { error }] = useRequestRegisterMutation();
  const { i18n } = useLingui();

  if (isBranchLoading || areTermsLoading) return <Loader cover />;

  const onSubmit = (formValues: FormValues): void | Promise<void> => {
    if (!resolvedBranch!.id) return Promise.resolve();
    const { email, password, firstName, lastName, newsletterOptions } = formValues;

    return register({
      firstName,
      lastName,
      email,
      password,
      branchId: resolvedBranch!.id,
      newsletterOptions,
      language: i18n.locale ?? 'en',
    })
      .unwrap()
      .then(() => {
        onSuccessfullyRegistered(email);
      });
  };

  const translations = {
    firstName: t({
      id: 'auth.forms.firstName',
      message: 'First name',
    }),
    lastName: t({
      id: 'auth.forms.lastName',
      message: 'Last name',
    }),
    email: t({
      id: 'auth.forms.email',
      message: 'E-mail',
    }),
    password: t({
      id: 'auth.forms.password',
      message: 'Password',
    }),
  };

  return (
    <Formik<FormValues>
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        terms: false,
        privacy: false,
        language: i18n.locale,
      }}
      validationSchema={registerSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Form>
            {!!error && (
              <Alert
                full
                type="error"
                title={t({
                  id: 'error',
                  message: 'Error',
                })}
                description={(error as ApiError).userMessage!}
              />
            )}
            <Row gapSm="sm">
              <Column sm={12} md={6}>
                <Text
                  name="firstName"
                  fieldAttr={{
                    id: 'firstName',
                    placeholder: translations.firstName,
                    autoComplete: 'firstName',
                    required: true,
                  }}
                  fieldProps={{
                    size: 'large',
                    label: translations.firstName,
                  }}
                />
              </Column>
              <Column sm={12} md={6}>
                <Text
                  name="lastName"
                  fieldAttr={{
                    id: 'lastName',
                    placeholder: translations.lastName,
                    autoComplete: 'lastName',
                    required: true,
                  }}
                  fieldProps={{
                    size: 'large',
                    label: translations.lastName,
                  }}
                />
              </Column>

              <Column sm={12}>
                <Text
                  name="email"
                  fieldAttr={{
                    id: 'email',
                    placeholder: translations.email,
                    autoComplete: 'email',
                    type: 'email',
                    required: true,
                  }}
                  fieldProps={{
                    size: 'large',
                    label: translations.email,
                  }}
                />
              </Column>

              <Column sm={12}>
                <PasswordChecker name="password" />
              </Column>
            </Row>

            <AcceptTermsAndConditions
              termsAndConditionsUrl={branchTerms?.termsAndConditions!.termsAndConditionsUrl}
              privacyGuidelinesUrl={branchTerms?.termsAndConditions!.privacyGuidelinesUrl}
            />

            <NewsletterCheckboxes />
            <DisclaimerText />

            <Row>
              <Column sm={12}>
                <Button
                  loading={isSubmitting}
                  width="full"
                  size="large"
                  attributes={{ type: 'submit' }}
                  disabled={!dirty || !isValid}
                >
                  <Trans id="auth.registerForm.submit">Register</Trans>
                </Button>
              </Column>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RegisterForm;
