import { PaymentSource } from '@/features/payment/types/Payment.types';
import { capitalize } from 'lodash';

export const getPersonalPaymentName = (payment?: PaymentSource) => {
  if (!payment) {
    return '';
  }
  switch (payment.type) {
    case 'PAYPAL':
      return 'Paypal';
    case 'GOOGLEPAY':
      return 'Google pay';
    default:
      return `**** ${payment?.last4} ${capitalize(payment?.brandNormalized)}`;
  }
};
