import { TransformedResponse } from '@/core/types';
import { RestClientWrapperReturnValue } from '@/core/api/restClientWrapper';

/**
 * RTK Query doesn't give us metadata where our headers and pagination is located
 * We need to transform each request to include those metadata in hooks
 * @param data - response data
 * @param meta - request and headers
 */
export const formatResponse = <Data extends any[]>(
  data: Data,
  { headers }: RestClientWrapperReturnValue['meta'],
): TransformedResponse<Data> => {
  const {
    'x-pagination-number': currentPage,
    'x-pagination-totalpages': totalPages,
    'x-pagination-totalelements': totalElements,
    'x-pagination-first': firstPage,
    'x-pagination-last': lastPage,
    'x-pagination-numberofelements': elementsPerPage,
  } = headers;

  /**
   * If any of the required pagination headers are missing, throw an error.
   */
  if (!currentPage) {
    throw new Error('Missing pagination headers, the API endpoint does not support pagination.');
  }

  return {
    data,
    meta: {
      currentPage: +currentPage,
      totalPages: +totalPages!,
      totalElements: +totalElements!,
      firstPage: firstPage === 'true',
      lastPage: lastPage === 'true',
      elementsPerPage: +elementsPerPage!,
    },
  };
};
