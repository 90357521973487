import { Route } from '@/core/types';
import {
  account,
  basicInfo,
  payment,
  verification,
  accountEditBasicInfo,
  accountEditPaymentMethods,
  accountEditBusinessAccount,
  accountEditVerification,
  accountEditNewsletter,
} from '@/core/pages/Account/routes';
import OnboardingBasicInfo from '@/core/pages/Account/AccountOnboarding/BasicInfo';
import Payment from '@/core/pages/Account/AccountOnboarding/Payment';
import Verification from '@/core/pages/Account/AccountOnboarding/Verification';
import Account from '@/core/pages/Account/AccountOnboarding/Account';
import AccountEdit from '@/core/pages/Account/AccountEdit/AccountEdit';
import AccountEditBasicInfo from '@/core/pages/Account/AccountEdit/BasicInfo';
import AccountEditPaymentMethod from '@/core/pages/Account/AccountEdit/PaymentMethod';
import AccountEditBusinessAccount from '@/core/pages/Account/AccountEdit/BusinessAccount';
import AccountEditVerification from '@/core/pages/Account/AccountEdit/Verification';
import AccountEditNewsletter from '@/core/pages/Account/AccountEdit/Newsletter';

const onboardingStepsRoutes: Route = {
  ...account,
  element: <Account />,
  routes: [
    { ...basicInfo, element: <OnboardingBasicInfo /> },
    { ...payment, element: <Payment /> },
    { ...verification, element: <Verification /> },
  ],
};

const accountEditRoutes: Route = {
  ...account,
  element: <AccountEdit />,
  routes: [
    { ...accountEditBasicInfo, element: <AccountEditBasicInfo /> },
    { ...accountEditPaymentMethods, element: <AccountEditPaymentMethod /> },
    { ...accountEditBusinessAccount, element: <AccountEditBusinessAccount /> },
    { ...accountEditNewsletter, element: <AccountEditNewsletter /> },
    { ...accountEditVerification, element: <AccountEditVerification /> },
  ],
};

export default [onboardingStepsRoutes, accountEditRoutes];
