import React, { FC, PropsWithChildren, ReactNode } from 'react';
import FullScreen from '@/core/components/Layouts/FullScreen';
import BusinessAccountStepper from '@/features/businessAccounts/components/Create/Stepper';
import {
  businessAccountCreateCompanyInfoStep,
  businessAccountCreatePaymentStep,
} from '@/core/pages/BusinessAccounts/routes';

interface BusinessAccountLayoutT {
  actions: ReactNode;
}

const BusinessAccountLayout: FC<PropsWithChildren<BusinessAccountLayoutT>> = ({
  children,
  actions,
}) => {
  return (
    <FullScreen
      actions={actions}
      title={
        <BusinessAccountStepper
          steps={[
            { stepName: 'ba-company-info', ...businessAccountCreateCompanyInfoStep },
            { stepName: 'ba-payment', ...businessAccountCreatePaymentStep },
          ]}
        />
      }
      main={children}
    />
  );
};

export default BusinessAccountLayout;
