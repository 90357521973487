import { Component, Suspense, ReactNode, PropsWithChildren } from 'react';
import Fallback from '@/core/components/ErrorHandlers/ErrorFallback';
import { LocalStorageKeys } from '@/core/enums';

type ErrorBoundarySuspenseP = PropsWithChildren & {
  fallback: ReactNode;
};

type ErrorBoundarySuspenseS = {
  hasError: boolean;
};

class ErrorBoundarySuspense extends Component<ErrorBoundarySuspenseP, ErrorBoundarySuspenseS> {
  chunkAlreadyRetried: boolean;

  constructor(props: ErrorBoundarySuspenseP) {
    super(props);
    this.chunkAlreadyRetried = localStorage.getItem(LocalStorageKeys.CHUNK_RETRY)
      ? JSON.parse(localStorage.getItem(LocalStorageKeys.CHUNK_RETRY)!).status
      : false;
    this.state = { hasError: false };
  }

  componentDidCatch(err: Error) {
    const isChunkLoadingErr =
      err?.toString()?.includes('Failed to fetch dynamically imported module') ?? false;

    if (isChunkLoadingErr) {
      if (!this.chunkAlreadyRetried) {
        localStorage.setItem(LocalStorageKeys.CHUNK_RETRY, JSON.stringify({ status: true }));
        window.location.reload();
      } else {
        localStorage.setItem(LocalStorageKeys.CHUNK_RETRY, JSON.stringify({ status: false }));
        this.setState({ hasError: true });
      }
    }
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;

    if (hasError) {
      return <Fallback />;
    }

    return <Suspense fallback={fallback}>{children}</Suspense>;
  }
}

export default ErrorBoundarySuspense;
