import { useCallback } from 'react';
import { resetAccountState } from '@/features/account/redux/account.reducer';
import { useTypedDispatch } from '@/core/redux/hooks';
import { useRequestSignOutMutation } from '@/features/auth/services/Auth.service';

const useRequestUserSignOut = () => {
  const dispatch = useTypedDispatch();
  const [signOutUser] = useRequestSignOutMutation();

  return useCallback(() => {
    signOutUser()
      .unwrap()
      .then(() => {
        dispatch(resetAccountState());
      });
  }, [dispatch, signOutUser]);
};

export default useRequestUserSignOut;
