import React, { FC } from 'react';
import { Modal, Paragraph } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';

interface LocationGuidelinesModalT {
  onClose: () => void;
  opened: boolean;
  locationGuidelines: string;
}

const LocationGuidelinesModal: FC<LocationGuidelinesModalT> = ({
  onClose,
  opened,
  locationGuidelines,
}) => {
  return (
    <Modal
      variation="small"
      modalHeader={<Trans id="booking.locationGuidelines">Location guidelines</Trans>}
      onClose={onClose}
      opened={opened}
    >
      <Paragraph color="var(--gs-500)">
        <span
          dangerouslySetInnerHTML={{
            __html: locationGuidelines.replaceAll('\n', '<br>'),
          }}
        />
      </Paragraph>
    </Modal>
  );
};

export default LocationGuidelinesModal;
