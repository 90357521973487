import React, { FC, ReactNode } from 'react';
import { Column, Svg, Caps, Row, IconName } from '@gourban/ui-components';
import styles from '@/features/myTrips/assets/scss/components/Trips/StatusLayout.module.scss';
import cn from 'classnames';

export interface StatusLayoutT {
  icon: IconName;
  title: ReactNode;
  content: ReactNode;
}

const StatusLayout: FC<StatusLayoutT> = ({ icon, title, content }) => {
  return (
    <Row noWrap marginBottom={20}>
      <Column marginBottom={0}>
        <div className={cn(styles['status__main-icon'], styles['status--rest-line-height'])}>
          <Svg icon={icon} height={16} width={16} iconColor="var(--gs-300)" />
        </div>
      </Column>
      <Column grow marginBottom={0}>
        <Caps size={3} color="var(--gs-500)" marginBottom={4}>
          {title}
        </Caps>
        <div className={styles['status__main-content']}>{content}</div>
      </Column>
    </Row>
  );
};

export default StatusLayout;
