import React, { FC } from 'react';
import { useRequestWhitelabelQuery } from '@/core/services/WhiteLabeling.service';
import MarkdownParser from '@/core/components/MarkdownParser';

const DisclaimerText: FC = () => {
  const { data: whiteLabeling } = useRequestWhitelabelQuery();

  if (!whiteLabeling?.disclaimer?.enabled) {
    return null;
  }

  return (
    <MarkdownParser
      size={3}
      marginBottom={24}
      color="var(--gs-600)"
      markdown={whiteLabeling.disclaimer.text}
    />
  );
};

export default DisclaimerText;
