import { RouteStaticConfig } from '@/core/types';

export const auth: RouteStaticConfig = {
  guarded: false,
  path: '/auth',
  label: 'Auth',
};

export const login: RouteStaticConfig = {
  guarded: false,
  path: '/auth/login',
  label: 'Login',
};

export const forgotPassword: RouteStaticConfig = {
  guarded: false,
  path: '/auth/forgot-password',
  label: 'Forgot password',
};

export const resetPassword: RouteStaticConfig = {
  guarded: false,
  path: '/auth/reset-password/:token',
  label: 'Reset password',
};

export const register: RouteStaticConfig = {
  guarded: false,
  path: '/auth/sign-up',
  label: 'Register',
};
