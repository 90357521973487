import { object, string } from 'yup';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';
import { t } from '@lingui/macro';

const requiredString = () =>
  string()
    .trim()
    .required(() => CommonValidationTranslations.requiredField());

export const companyInfoSchema = object().shape({
  name: requiredString(),
  email: requiredString().email(() =>
    CommonValidationTranslations.invalidField(
      t({ id: 'businessAccount.create.contactEmailLabel', message: 'Contact email' }),
    ),
  ),
  vatNumber: requiredString(),
  companyRegistrationNumber: string().trim().optional(),
  city: requiredString(),
  postcode: requiredString(),
  country: requiredString(),
  street: requiredString(),
});
