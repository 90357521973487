import React from 'react';
import cn from 'classnames';
import { Svg, IconName, Collapse } from '@gourban/ui-components';
import cssModules from '@/core/assets/scss/components/Dashboard/MainMenu.module.scss';
import useNavigationLinkState from '@/core/hooks/useNavigationLinkState';
import { RouteStaticConfig } from '@/core/types';
import SubMenu from './SubMenu';

interface MainMenuParentItemT {
  subRoutes: RouteStaticConfig[];
  content: string;
  path: string;
  icon: IconName;
}

const MainMenuParentItem: React.FC<MainMenuParentItemT> = ({ subRoutes, content, icon, path }) => {
  const { active } = useNavigationLinkState(path);

  if (!subRoutes.length) return null;

  const linkContent = (
    <>
      <Svg icon={icon} className={cssModules['main-navigation__item__icon']} />
      <span className={cssModules['main-navigation__item__content']}>{content} </span>
    </>
  );

  return (
    <li className={cssModules['main-navigation__item']}>
      <Collapse
        // forces remount on change of active state to allow new mount with updated initiallyExpanded value
        key={`${path} ${active}`}
        initialyExpanded={active}
        header={linkContent}
        headerClassname={cn(
          cssModules['main-navigation__item__button'],
          cssModules['main-navigation__item__button--collapsible'],
        )}
      >
        <ul className={cssModules['main-navigation__item__submenu']}>
          <SubMenu routes={subRoutes} />
        </ul>
      </Collapse>
    </li>
  );
};

export default MainMenuParentItem;
