import React from 'react';
import styles from '../../assets/Onboarding/CompleteProfile.module.scss';
import { Button, Loader, Paragraph, Svg } from '@gourban/ui-components';
import { useTypedSelector } from '@/core/redux/hooks';
import { getMeData } from '@/features/account/redux/account.selectors';
import { useNavigate } from 'react-router-dom';
import { useVerificationStatus } from '@/features/account/hooks/useVerificationStatus';
import { accountChildRoutes, verification } from '@/core/pages/Account/routes';
import { useStepStatus } from '@/features/account/hooks/useStepStatus';
import { Trans } from '@lingui/macro';
import { useAccountProfileComplete } from '@/features/account/hooks/useAccountProfileComplete';

const CompleteProfile = () => {
  const navigate = useNavigate();
  const verificationStatus = useVerificationStatus();
  const meData = useTypedSelector(getMeData);
  const accountCompleted = useAccountProfileComplete();
  const stepsStatus = useStepStatus();
  const calculatedWidth = (stepsStatus.completed / stepsStatus.total) * 100;

  if (!meData || accountCompleted) return null;

  const verificationPending = verificationStatus === 'pending';
  const verificationRejected = verificationStatus === 'rejected';

  return (
    <div
      tabIndex={-1}
      role="button"
      onKeyDown={() => {}}
      onClick={() => navigate(accountChildRoutes[stepsStatus.completed]?.path ?? verification.path)}
      className={styles['complete-profile']}
    >
      <div className={styles['complete-profile__avatar']}>
        <Svg icon="user" iconColor="white" width={32} height={32} />
      </div>
      <div className={styles['complete-profile__content']}>
        <div className={styles['complete-profile__completion']}>
          <div className={styles['complete-profile__completion-info']}>
            <Paragraph marginBottom={0}>
              {verificationPending ? (
                <Trans id="account.completeProfile.drivingLicenceVerification">
                  Your driving license is being verified
                </Trans>
              ) : (
                <Trans id="account.completeProfile.complete">Complete profile</Trans>
              )}
            </Paragraph>
            <Paragraph marginBottom={0} color="var(--gs-500)">
              {stepsStatus.completed}/{stepsStatus.total}
            </Paragraph>
          </div>
          {verificationRejected && (
            <Paragraph size={3} color="var(--r-300)">
              <Trans id="account.completeProfile.drivingLicenceVerificationFailed">
                There was a problem verifying your drivers licence.
              </Trans>
            </Paragraph>
          )}
          <div className={styles['complete-profile__completion-rate']}>
            <div
              style={{ width: `${calculatedWidth}%` }}
              className={styles['complete-profile__completion-completed']}
            />
          </div>
        </div>

        {verificationPending ? (
          <Loader width={32} />
        ) : (
          <Button noPadding variation="link" iconName="arrowRight" />
        )}
      </div>
    </div>
  );
};

export default CompleteProfile;
