export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return undefined;
  }

  if (!phoneNumber.includes('+')) {
    return `+${phoneNumber}`;
  }

  return phoneNumber;
};
