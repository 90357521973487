import { object, boolean } from 'yup';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';

export const termsAndConditionValidationSchema = object().shape({
  terms: boolean()
    .required(() => CommonValidationTranslations.requiredField())
    .oneOf([true], () => CommonValidationTranslations.requiredField()),
  privacy: boolean()
    .required(() => CommonValidationTranslations.requiredField())
    .oneOf([true], () => CommonValidationTranslations.requiredField()),
});
