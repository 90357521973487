import { FC, useState } from 'react';
import RegisterForm from '@/core/features/auth/components/RegisterForm';
import AuthenticationLayout from '@/features/auth/components/AuthenticationLayout';
import { Link } from 'react-router-dom';
import { login } from '@/core/pages/Auth/routes';
import ResendEmailButton from '@/features/auth/components/ResendEmailButton';
import { Trans, t } from '@lingui/macro';

const Register: FC = () => {
  const [email, setEmail] = useState<string | null>(null);
  const registered = !!email;

  return (
    <AuthenticationLayout
      title={
        registered ? (
          <Trans id="auth.registerForm.action">Confirm your email</Trans>
        ) : (
          <Trans id="auth.registerForm.pageTitle">Register</Trans>
        )
      }
      form={
        registered ? (
          <ResendEmailButton
            email={email}
            message={t({
              id: 'auth.registerForm.action.subtitle',
              message:
                'Please click the link in the email to confirm your address and activate your account.',
            })}
          />
        ) : (
          <RegisterForm onSuccessfullyRegistered={setEmail} />
        )
      }
      footer={
        registered ? (
          <>
            <Trans id="auth.registerForm.footer.title">Still having trouble?</Trans>
            <br />
            <a href="https://www.railanddrive.at/de/kontakt" target="_blank">
              <Trans id="auth.registerForm.footer.contact">Contact support</Trans>
            </a>
            .
          </>
        ) : (
          <>
            <Trans id="auth.registerForm.footer.haveAccount">Already have an account?</Trans>{' '}
            <Link to={login.path}>
              <Trans id="auth.registerForm.login">Sign in</Trans>
            </Link>
            .
          </>
        )
      }
      alignCenter={registered}
    />
  );
};

export default Register;
