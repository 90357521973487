import React from 'react';
import { Column, Row, Text } from '@gourban/ui-components';
import { t } from '@lingui/macro';
import CitySelect from '@/features/geomap/components/Form/CitySelect';
import CountrySelect from '@/features/geomap/components/Form/CountrySelect';
import AddressSelect from '@/features/geomap/components/Form/AddressSelect';

const CompanyInfoFormFields = () => {
  return (
    <Row gapSm="sm">
      <Column sm={12}>
        <Text
          fieldAttr={{
            id: 'name',
            placeholder: t({
              id: 'businessAccount.create.legalNamePlaceholder',
              message: 'Legal name',
            }),
            required: true,
          }}
          name="name"
          fieldProps={{
            label: t({ id: 'businessAccount.create.legalNameLabel', message: 'Legal name' }),
          }}
        />
      </Column>

      <Column sm={12}>
        <Text
          fieldAttr={{
            id: 'vatNumber',
            placeholder: t({
              id: 'businessAccount.create.vatIDPlaceholder',
              message: 'VAT ID',
            }),
            required: true,
          }}
          name="vatNumber"
          fieldProps={{
            label: t({ id: 'businessAccount.create.vatIDLabel', message: 'VAT ID' }),
          }}
        />
      </Column>

      <Column sm={12}>
        <Text
          fieldAttr={{
            id: 'companyRegistrationNumber',
            placeholder: t({
              id: 'businessAccount.create.companyRegistrationNumberPlaceholder',
              message: 'Company registration number',
            }),
          }}
          name="companyRegistrationNumber"
          fieldProps={{
            label: t({
              id: 'businessAccount.create.companyRegistrationNumberLabel',
              message: 'Company registration number',
            }),
          }}
        />
      </Column>

      <Column sm={12}>
        <Text
          fieldAttr={{
            id: 'email',
            placeholder: t({
              id: 'businessAccount.create.contactEmailPlaceholder',
              message: 'Contact email',
            }),
            required: true,
          }}
          name="email"
          fieldProps={{
            label: t({ id: 'businessAccount.create.contactEmailLabel', message: 'Contact email' }),
          }}
        />
      </Column>

      <Column sm={12}>
        <AddressSelect
          fieldAttr={{
            placeholder: t({ id: 'geomap.addressSelect.placeholder', message: 'Address' }),
            required: true,
          }}
        />
      </Column>

      <Row marginBottom={0} gapSm="sm" justify="space-between">
        <Column sm={12} md={7}>
          <CitySelect
            fieldAttr={{
              placeholder: t({ id: 'geomap.citySelect.placeholder', message: 'City' }),
              required: true,
            }}
          />
        </Column>
        <Column sm={12} md={5}>
          <Text
            fieldAttr={{
              id: 'postcode',
              placeholder: t({
                id: 'businessAccount.create.zipPlaceholder',
                message: 'ZIP',
              }),
              required: true,
            }}
            name="postcode"
            fieldProps={{
              label: t({ id: 'businessAccount.create.zipLabel', message: 'ZIP' }),
            }}
          />
        </Column>
      </Row>

      <Column sm={12}>
        <CountrySelect
          fieldAttr={{
            placeholder: t({ id: 'geomap.countrySelect.label', message: 'Country' }),
            required: true,
          }}
        />
      </Column>
    </Row>
  );
};

export default CompanyInfoFormFields;
