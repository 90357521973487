import { useVerificationStatus } from '@/features/account/hooks/useVerificationStatus';
import { useRequestBusinessAccountMembershipQuery } from '@/features/businessAccounts/services/BusinessAccount.service';

export const useAccountProfileComplete = () => {
  const verificationStatus = useVerificationStatus();
  const { data: businessAccountMember } = useRequestBusinessAccountMembershipQuery();
  const isBusinessAccountMember = !!businessAccountMember?.length;

  return verificationStatus === 'successful' || isBusinessAccountMember;
};
