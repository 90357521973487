import React, { FC } from 'react';
import { BookingStep } from '@/features/booking/types';
import styles from '../assets/scss/components/Breadcrumbs.module.scss';
import { useLingui } from '@lingui/react';

interface BookingBreadcrumbsT {
  steps: BookingStep[];
}

const Breadcrumbs: FC<BookingBreadcrumbsT> = ({ steps }) => {
  const { i18n } = useLingui();
  const activeSteps = steps.reduce<string[]>((stepsLabel, step) => {
    if ((step.status === 'active' || step.status === 'completed') && step.label?.id) {
      stepsLabel.push(i18n._(step.label));
    }

    return stepsLabel;
  }, []);

  return <span className={styles.breadcrumbs}>{activeSteps.join(' / ')}</span>;
};

export default Breadcrumbs;
