import { VehicleCategoriesDistanceUnit } from '@/features/vehicles/types';

export const getNormalizedUnit = (unit: VehicleCategoriesDistanceUnit) => {
  switch (unit) {
    case 'KILOMETERS':
      return 'kilometer';
    case 'MILES':
      return 'mile';
    default:
      return 'kilometer';
  }
};
