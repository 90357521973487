import { object, string } from 'yup';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';
import { t } from '@lingui/macro';

export const forgotPasswordSchema = object().shape({
  email: string()
    .trim()
    .email(() =>
      CommonValidationTranslations.invalidField(
        t({
          id: 'auth.forms.email',
          message: 'E-mail',
        }),
      ),
    )
    .required(() => CommonValidationTranslations.requiredField()),
});
