import React from 'react';
import { Loader, Modal } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';
import { FormikProvider, useFormik, Form } from 'formik';
import TripDrivingLogFormFields from '@/features/myTrips/components/TripDrivingLog/TripDrivingLogFormFields';
import { DrivingLogCreateForm } from '@/features/myTrips/types/DrivingLog';
import TripDrivingLogModalActions from '@/features/myTrips/components/TripDrivingLog/TripDrivingLogModalActions';
import { drivingLogSchema } from '@/features/myTrips/utils/validations/drivingLog.schema';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import { BookingExtended } from '@/features/booking/types';
import {
  useRequestDrivingLogEntryQuery,
  useRequestUpdateDrivingLogEntryMutation,
} from '@/features/myTrips/services/DrivingLog.service';
import { format } from 'date-fns';

const TripDrivingLogEdit = () => {
  const navigate = useNavigate();
  const { drivingLogEntryId } = useParams();
  const { rentalId } = useOutletContext<BookingExtended>();
  const { data: drivingLogEntry } = useRequestDrivingLogEntryQuery(
    { rentalId: rentalId!, drivingLogEntryId: +drivingLogEntryId! },
    { skip: !rentalId || !drivingLogEntryId },
  );

  const [updateDrivingLogEntry] = useRequestUpdateDrivingLogEntryMutation();

  const onSubmit = (values: DrivingLogCreateForm) => {
    return updateDrivingLogEntry({
      ...values,
      startDate: format(new Date(values.startDate!), 'yyyy-MM-dd'),
      rentalId: rentalId!,
      drivingLogEntryId: +drivingLogEntryId!,
    })
      .unwrap()
      .then(() => {
        navigate(-1);
      });
  };

  const form = useFormik<DrivingLogCreateForm>({
    initialValues: {
      startDate: drivingLogEntry?.startDate ? new Date(drivingLogEntry.startDate) : undefined,
      endLocation: drivingLogEntry?.endLocation,
      startLocation: drivingLogEntry?.startLocation,
      reason: drivingLogEntry?.reason,
      startKilometers: drivingLogEntry?.startKilometers
        ? parseFloat(drivingLogEntry.startKilometers.toFixed(2))
        : undefined,
      endKilometers: drivingLogEntry?.endKilometers
        ? parseFloat(drivingLogEntry.endKilometers.toFixed(2))
        : undefined,
      note: drivingLogEntry?.note,
      tripType: drivingLogEntry?.tripType,
    },
    enableReinitialize: true,
    validationSchema: drivingLogSchema,
    onSubmit,
  });

  return (
    <FormikProvider value={form}>
      <Modal
        modalHeader={<Trans id="myTrips.tripDrivingLogEdit.title">Edit driving log</Trans>}
        modalFooter={<TripDrivingLogModalActions mode="edit" />}
        variation="small"
        onClose={() => navigate(-1)}
        opened
      >
        <Form>
          {form.isSubmitting && <Loader cover />}
          <TripDrivingLogFormFields />
        </Form>
      </Modal>
    </FormikProvider>
  );
};

export default TripDrivingLogEdit;
