import React, { FC, PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from '@/core/assets/scss/components/UI/Link.module.scss';

interface LinkT {
  external?: boolean;
  to: string;
}

const Link: FC<PropsWithChildren<LinkT>> = ({ external, to, children }) => {
  if (external) {
    return (
      <a rel="noopener noreferrer" className={styles.link} href={to} target="_blank">
        {children}
      </a>
    );
  }

  return (
    <RouterLink className={styles.link} to={to}>
      {children}
    </RouterLink>
  );
};

export default Link;
