import { assign, entries } from 'lodash';

/**
 * Used to flatten object to its merged string key
 * EG:
 * {
 *     user: {
 *         firstName: 'something'
 *     }
 * }
 * would be converted to
 * {
 *     'user.firstName': 'something'
 * }
 */
export const flattenObject = <T>(
  unflattenObject: Record<string, any>,
  parentKey?: string,
): Record<string, T> => {
  const result = {} as Record<string, T>;

  entries(unflattenObject).forEach(([key, value]) => {
    const flattenKey = parentKey ? `${parentKey}.${key}` : key;

    if (Array.isArray(value)) {
      assign(result, { [`${flattenKey}[]`]: value });
    } else if (typeof value === 'object') {
      assign(result, flattenObject(value, flattenKey));
    } else {
      result[flattenKey] = value;
    }
  });

  return result;
};
