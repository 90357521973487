import { AvailableLocales, LocalStorageKeys } from '@/core/enums';
import { useRequestTenantInfoQuery } from '@/features/auth/services/Tenant.service';

const DEFAULT_LOCALE = AvailableLocales.ENGLISH;

export const getSupportedLocale = (locale?: string): AvailableLocales | undefined => {
  if (!locale) return undefined;
  return Object.values(AvailableLocales).find((lang) => locale.startsWith(lang));
};

export const useDetectLocale = (): AvailableLocales => {
  const { data: tenantData } = useRequestTenantInfoQuery();

  const localStorageLocale = getSupportedLocale(
    localStorage.getItem(LocalStorageKeys.PREFERRED_LANG) ?? undefined,
  );
  if (localStorageLocale) return localStorageLocale;

  const supportedBrowserLocale = getSupportedLocale(navigator.language);
  if (supportedBrowserLocale) return supportedBrowserLocale;

  const supportedTenantLocale = getSupportedLocale(tenantData?.defaultLocale);
  if (supportedTenantLocale) return supportedTenantLocale;

  return DEFAULT_LOCALE;
};
