import { unflattenObject } from '@/core/utils/unflattenObject';
import { BookingFiltersQueryParams } from '@/features/booking/types';

type BookingFilterKeys =
  | 'locationCoordinates'
  | 'boundingBoxCoordinates'
  | 'startTime'
  | 'endTime'
  | 'location';

export const getQueryParams = (searchParams?: string) => {
  if (!searchParams) return null;

  const parsedParams: Record<string, unknown> = {};

  const URLParams = new URLSearchParams(searchParams);

  URLParams.forEach((paramValue, paramName) => {
    const param = paramName as BookingFilterKeys;

    switch (param) {
      case 'locationCoordinates':
      case 'boundingBoxCoordinates':
        parsedParams[param] = paramValue.split(',').map((coordinate) => Number(coordinate));
        break;
      case 'startTime':
        parsedParams.startTime = new Date(paramValue);
        break;
      case 'endTime':
        parsedParams.endTime = new Date(paramValue);
        break;
      default: {
        const paramValues = URLParams.getAll(param);

        if (paramName.includes('[]')) {
          parsedParams[param] = paramValues;
        } else {
          parsedParams[param] = paramValue;
        }

        break;
      }
    }
  });

  return unflattenObject<BookingFiltersQueryParams>(parsedParams);
};
