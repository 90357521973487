import React, { FC, useState } from 'react';
import { Row, Column, Paragraph, Button } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';
import locationGuidelinesStyles from '../assets/scss/components/LocationGuidelines.module.scss';
import LocationGuidelinesModal from '@/features/booking/components/LocationGuidelinesModal';

interface LocationGuidelinesT {
  locationGuidelines?: string;
}

const LocationGuidelines: FC<LocationGuidelinesT> = ({ locationGuidelines }) => {
  const [guidelinesModal, setGuidelinesModal] = useState(false);

  if (!locationGuidelines) {
    return null;
  }

  return (
    <>
      <Row
        className={locationGuidelinesStyles['location-guidelines']}
        alignItems="center"
        justify="space-between"
      >
        <Column>
          <Paragraph weight="medium" marginBottom={0} size={3}>
            <Trans id="booking.locationGuidelines">Location guidelines</Trans>
          </Paragraph>
        </Column>
        <Column>
          <Button size="small" variation="secondary" onClick={() => setGuidelinesModal(true)}>
            <Trans id="general.view">View</Trans>
          </Button>
        </Column>
      </Row>
      <LocationGuidelinesModal
        onClose={() => setGuidelinesModal(false)}
        opened={guidelinesModal}
        locationGuidelines={locationGuidelines}
      />
    </>
  );
};

export default LocationGuidelines;
