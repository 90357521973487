import React, { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { FormikProvider, useFormik } from 'formik';
import {
  AvailableBookingStationArgs,
  AvailableBookingStationFilters,
  BookingFiltersForm,
  BookingFiltersQueryParams,
  BookingSettings,
  ParsedAttributes,
} from '@/features/booking/types';
import { useSearchParams } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import {
  resetActiveFilters,
  setActiveFilterValues,
  setClearFiltersRequested,
} from '@/features/account/redux/account.reducer';
import { useMap, MapMouseEvent } from '@gourban/ui-components';
import FormFields from '@/features/booking/components/Filters/FormFields';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { flattenObject } from '@/core/utils/flattenObject';
import { useRequestVehicleAttributesQuery } from '@/features/vehicles/services/Vehicles.service';
import { isEmpty, keys, pick } from 'lodash';
import { getClearFiltersRequest } from '@/features/account/redux/account.selectors';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { SettingsManagement } from '@/core/types';
import { BookingService } from '@/features/booking/services/Booking.service';
import { BookingCacheTags } from '@/features/booking/enums';

interface FiltersFormT {
  onSubmit: (args: Partial<AvailableBookingStationArgs>) => Promise<unknown> | null;
  initialValues: BookingFiltersForm;
  initialParams: BookingFiltersQueryParams | null;
  bookingSettings?: SettingsManagement<BookingSettings>;
}

// This suffix is needed because form elements have name with id ( number )
// Formik automatically parses them as array index but we want them to be objects
export const GROUP_ITEM_SUFFIX = 'item-';

const FiltersForm: React.FC<FiltersFormT> = ({
  onSubmit,
  initialValues,
  initialParams,
  bookingSettings,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useTypedDispatch();
  const headerPortalRef = useRef<HTMLElement>(document.getElementById('booking-filters'));
  const { current: mapInstance } = useMap();
  const { data: vehicleAttributesAPI } = useRequestVehicleAttributesQuery();
  const clearFiltersRequested = useTypedSelector(getClearFiltersRequest);

  const updateActiveFilterValues = (args: Partial<AvailableBookingStationFilters>) => {
    const {
      vehicleAttributes,
      startTime,
      endTime,
      location,
      boundingBoxCoordinates,
      locationCoordinates,
    } = args;
    const hasAttributesFilter = vehicleAttributes && !!keys(vehicleAttributes).length;

    if (
      !startTime &&
      !endTime &&
      !hasAttributesFilter &&
      !boundingBoxCoordinates &&
      !locationCoordinates
    ) {
      dispatch(resetActiveFilters());
    } else {
      dispatch(
        setActiveFilterValues({
          startTime,
          endTime,
          location,
          boundingBoxCoordinates,
          locationCoordinates,
          vehicleAttributes,
        }),
      );
    }
  };

  /**
   * Attribute filters need to be parsed for backend to understand them
   */
  const parseAttributeValues = (
    attributes: BookingFiltersForm['vehicleAttributes'],
  ): ParsedAttributes => {
    const parsedValues: ParsedAttributes = {};

    if (!attributes) return parsedValues;

    Object.entries(attributes).forEach(([key, attributeValue]) => {
      const vehicleAttributeConfig = vehicleAttributesAPI!.find((attribute) =>
        attribute.attributes.some(({ id }) => String(id) === key.replace(GROUP_ITEM_SUFFIX, '')),
      );

      if (!vehicleAttributeConfig) {
        return;
      }

      if (!parsedValues[`${vehicleAttributeConfig.filterType}`]) {
        parsedValues[`${vehicleAttributeConfig.filterType}`] = [];
      }

      if (attributeValue) {
        parsedValues[`${vehicleAttributeConfig.filterType}`].push(
          key.replace(GROUP_ITEM_SUFFIX, ''),
        );
      }
    });

    return parsedValues;
  };

  const parseStartEndDate = ({
    bookingDate,
    bookingTime,
  }: Pick<BookingFiltersForm, 'bookingTime' | 'bookingDate'>): Pick<
    AvailableBookingStationArgs,
    'startTime' | 'endTime'
  > => {
    const startTime =
      bookingDate?.from &&
      new Date(mergeDateTime(bookingDate.from, bookingTime!.from)).toISOString();

    const endTime =
      (bookingDate?.from || bookingDate?.to) &&
      new Date(mergeDateTime(bookingDate.to ?? bookingDate.from!, bookingTime!.to)).toISOString();

    return { startTime, endTime };
  };

  const onLocationChange = async (values: BookingFiltersForm = {}) => {
    const { boundingBoxCoordinates, location, locationCoordinates } = values;
    const { vehicleAttributes, bookingDate, bookingTime } = values;

    const parsedAttributes = parseAttributeValues(vehicleAttributes);
    const { startTime, endTime } = parseStartEndDate({ bookingDate, bookingTime });

    setSearchParams((params) => {
      if (boundingBoxCoordinates) {
        params.set('boundingBoxCoordinates', boundingBoxCoordinates.join(','));
      } else {
        params.delete('boundingBoxCoordinates');
      }

      if (location) {
        params.set('location', location);
      } else {
        params.delete('location');
      }

      if (locationCoordinates) {
        params.set('locationCoordinates', locationCoordinates.toString());
      } else {
        params.delete('locationCoordinates');
      }

      return params;
    });

    updateActiveFilterValues({
      startTime,
      endTime,
      location,
      boundingBoxCoordinates,
      locationCoordinates,
      vehicleAttributes: parsedAttributes,
    });

    if (boundingBoxCoordinates) {
      mapInstance?.fitBounds(
        [
          [boundingBoxCoordinates[0], boundingBoxCoordinates[1]],
          [boundingBoxCoordinates[2], boundingBoxCoordinates[3]],
        ],
        { duration: 1500 },
      );
    } else if (locationCoordinates) {
      mapInstance?.flyTo({
        center: { lng: locationCoordinates[0], lat: locationCoordinates[1] },
        duration: 1500,
        zoom: 15,
      });
    }
  };

  const onFilterSubmit = async (values: BookingFiltersForm) => {
    const {
      date,
      bookingDate,
      vehicleAttributes,
      bookingTime,
      locationCoordinates,
      location,
      boundingBoxCoordinates,
    } = values;

    await onLocationChange(values);

    const parsedAttributes = parseAttributeValues(vehicleAttributes);
    const { startTime, endTime } = parseStartEndDate({ bookingDate, bookingTime });

    setSearchParams((params) => {
      params.delete('vehicleAttributes.ANY[]');
      params.delete('vehicleAttributes.ALL[]');
      params.delete('vehicleAttributes.ONE[]');

      if (parsedAttributes) {
        const flattedAttributes = flattenObject<string[]>({ vehicleAttributes: parsedAttributes });
        Object.entries(flattedAttributes).forEach(([attributeKey, attributeValues]) => {
          attributeValues.forEach((value) => params.append(attributeKey, value));
        });
      }

      if (date && startTime) {
        params.set('startTime', startTime);
        params.set('endTime', endTime!);
      } else {
        params.delete('startTime');
        params.delete('endTime');
      }

      return params;
    });

    if (startTime || vehicleAttributes) {
      await onSubmit({
        startTime,
        endTime,
        vehicleAttributes: parsedAttributes,
        location,
      });
    }

    updateActiveFilterValues({
      startTime,
      endTime,
      location,
      boundingBoxCoordinates,
      locationCoordinates,
      vehicleAttributes: parsedAttributes,
    });
  };

  const mapLocationChanges = useCallback(
    (mapEvent: MapMouseEvent) => {
      const newSearchParams = searchParams;
      const zoom = mapEvent.target.getZoom();
      newSearchParams.set('z', zoom.toString());

      const center = mapEvent.target.getCenter();
      newSearchParams.set('c', [center.lng, center.lat].toString());

      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const formInstance = useFormik<BookingFiltersForm>({
    initialValues,
    onSubmit: onFilterSubmit,
  });

  useEffect(() => {
    // We write many map related stuff in the URL params, but we only want specific ones for initial filter submit
    if (
      initialParams &&
      !isEmpty(
        pick(initialParams, ['startTime', 'endTime', 'vehicleAttributes', 'locationCoordinates']),
      )
    ) {
      const {
        locationCoordinates,
        startTime,
        endTime,
        vehicleAttributes,
        location,
        boundingBoxCoordinates,
      } = initialParams;

      updateActiveFilterValues({
        startTime: startTime?.toISOString(),
        endTime: endTime?.toISOString(),
        location,
        boundingBoxCoordinates,
        locationCoordinates,
        vehicleAttributes,
      });

      onSubmit({
        startTime: startTime?.toISOString(),
        endTime: endTime?.toISOString(),
        vehicleAttributes,
        location,
      });
    }

    // Should only trigger initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filters can be cleared from alot of places on the app, so we need to have this listener to trigger filter clear
    if (clearFiltersRequested) {
      const { lng, lat } = mapInstance!.getCenter();

      setSearchParams({
        z: mapInstance!.getZoom().toString(),
        c: `${lng},${lat}`,
      });

      formInstance.setValues({
        location: '',
        date: undefined,
        bookingDate: undefined,
        bookingTime: {
          from: formatInitialTime(bookingSettings?.value?.properties?.preselectedStartingTime, 60),
          to: formatInitialTime(bookingSettings?.value?.properties?.preselectedEndingTime, 120),
        },
        boundingBoxCoordinates: undefined,
        locationCoordinates: undefined,
        vehicleAttributes: undefined,
        vehicleFilters: undefined,
      });
      dispatch(resetActiveFilters());
      dispatch(setClearFiltersRequested(false));
      dispatch(BookingService.util?.invalidateTags([BookingCacheTags.AVAILABLE_STATIONS]));
    }

    // We dont need all these dependencies used here, we only want this to be triggered on clearFiltersRequested change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setSearchParams, clearFiltersRequested]);

  useEffect(() => {
    // @ts-ignore
    mapInstance?.on('moveend', mapLocationChanges);

    return () => {
      // @ts-ignore
      mapInstance?.off('moveend', mapLocationChanges);
    };
  }, [mapInstance, mapLocationChanges]);

  return createPortal(
    <FormikProvider value={formInstance}>
      <FormFields />
    </FormikProvider>,
    headerPortalRef.current!,
  );
};

export default FiltersForm;
