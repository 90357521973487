import { useLocation } from 'react-router-dom';

interface StateT {
  active?: boolean;
}

const useNavigationLinkState = (to?: string, additionalPaths?: string[]): StateT => {
  const { pathname } = useLocation();
  const active =
    (to && pathname.startsWith(to)) || additionalPaths?.some((path) => path === pathname);
  return { active };
};

export default useNavigationLinkState;
