import { createApi } from '@reduxjs/toolkit/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { SettingsManagement, SupportMethods } from '@/core/types';
import { SettingsKeys } from '@/core/enums';
import { AppWhiteLabel, BookingSettings, NewsletterSettings } from '@/features/booking/types';
import { UserRequiredFieldsSettingsValue } from '@/features/auth/types/User.types';
import { MapSettings, TenantAccountSettings } from '@/features/geomap/types';

export const mapSettingsDefaults: MapSettings = {
  mapSearchSettings: {
    types: 'region,postcode,locality,place,address,poi',
    limit: 10,
  },
};

const takeFirstFromArray = <T>(data: SettingsManagement<T>[]): SettingsManagement<T> => data?.[0];

export const SettingsManagementService = createApi({
  reducerPath: 'api-settings',
  baseQuery: RestClientWrapper,
  tagTypes: [],
  endpoints: (builder) => ({
    requestBookingSetting: builder.query<SettingsManagement<BookingSettings>, void>({
      query: () => ({
        url: '/front/settings',
        requestParams: {
          params: {
            keys: SettingsKeys.BOOKING,
          },
        },
      }),
      transformResponse: takeFirstFromArray,
      keepUnusedDataFor: Infinity,
    }),

    requestUserRequiredFields: builder.query<
      SettingsManagement<UserRequiredFieldsSettingsValue>,
      void
    >({
      query: () => ({
        url: '/front/settings',
        requestParams: {
          params: {
            keys: SettingsKeys.USER_REQUIRED_FIELDS,
          },
        },
      }),
      keepUnusedDataFor: Infinity,
      transformResponse: takeFirstFromArray,
    }),

    requestSupportMethods: builder.query<SettingsManagement<SupportMethods>, void>({
      query: () => ({
        url: '/front/settings',
        requestParams: {
          params: {
            keys: SettingsKeys.SUPPORT_METHODS,
          },
        },
      }),
      keepUnusedDataFor: Infinity,
      transformResponse: takeFirstFromArray,
    }),

    requestNewsletters: builder.query<SettingsManagement<NewsletterSettings>, void>({
      query: () => ({
        url: '/front/settings',
        config: {
          ignoreJWTCheck: true,
          displayNotificationOnError: false,
        },
        requestParams: {
          params: {
            keys: SettingsKeys.NEWSLETTER,
          },
        },
      }),
      transformResponse: takeFirstFromArray,
      keepUnusedDataFor: Infinity,
    }),

    requestWhiteLabelLogo: builder.query<SettingsManagement<AppWhiteLabel>, void>({
      query: () => ({
        url: '/back/settings',
        config: {
          ignoreJWTCheck: true,
          displayNotificationOnError: false,
        },
        requestParams: {
          params: {
            keys: SettingsKeys.WHITELABEL_APP,
          },
        },
      }),
      transformResponse: takeFirstFromArray,
      keepUnusedDataFor: Infinity,
    }),

    requestMapSettings: builder.query<SettingsManagement<MapSettings>, void>({
      query: () => ({
        url: '/front/settings',
        config: {
          ignoreJWTCheck: true,
          displayNotificationOnError: false,
        },
        requestParams: {
          params: {
            key: SettingsKeys.MAP,
          },
        },
      }),
      transformResponse: (
        data: SettingsManagement<MapSettings>,
      ): SettingsManagement<MapSettings> => {
        data.value = {
          ...mapSettingsDefaults,
          ...data.value,
        };
        return data;
      },
      keepUnusedDataFor: Infinity,
    }),

    requestTenantAccountSettings: builder.query<SettingsManagement<TenantAccountSettings>, void>({
      query: () => ({
        url: '/front/settings',
        config: {
          ignoreJWTCheck: true,
          displayNotificationOnError: false,
        },
        requestParams: {
          params: {
            keys: SettingsKeys.TENANT_ACCOUNTS,
          },
        },
      }),
      transformResponse: takeFirstFromArray,
      keepUnusedDataFor: Infinity,
    }),

    requestPublicSettingsBulk: builder.query<SettingsManagement<any>[], void>({
      query: () => ({
        url: '/front/settings',
        config: {
          ignoreJWTCheck: true,
          displayNotificationOnError: false,
        },
        requestParams: {
          params: {
            keys: `${SettingsKeys.TENANT_ACCOUNTS},${SettingsKeys.WHITELABEL_APP},${SettingsKeys.NEWSLETTER},${SettingsKeys.LOGIN}`,
          },
        },
      }),
      keepUnusedDataFor: Infinity,
    }),

    requestGuardedSettingsBulk: builder.query<SettingsManagement<any>[], void>({
      query: () => ({
        url: '/front/settings',
        requestParams: {
          params: {
            keys: `${SettingsKeys.SUPPORT_METHODS},${SettingsKeys.USER_REQUIRED_FIELDS},${SettingsKeys.BOOKING}`,
          },
        },
      }),
      keepUnusedDataFor: Infinity,
    }),
  }),
});

export const {
  useRequestWhiteLabelLogoQuery,
  useRequestBookingSettingQuery,
  useRequestUserRequiredFieldsQuery,
  useRequestNewslettersQuery,
  useRequestSupportMethodsQuery,
  useRequestMapSettingsQuery,
  useRequestTenantAccountSettingsQuery,
  useRequestPublicSettingsBulkQuery,
  useRequestGuardedSettingsBulkQuery,
} = SettingsManagementService;
