import { FC } from 'react';
import { Card, Column, Paragraph, Row } from '@gourban/ui-components';
import { BookingOverviewForm } from '@/features/booking/types';
import { MergedAvailableCategoriesData } from '@/features/vehicles/types';
import styles from '../../assets/scss/components/VehicleCategoryCard.module.scss';
import VehicleCategoryTags from './VehicleCategoryTags';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import VehicleCategoryPricingDisplay from '@/features/vehicles/components/VehicleCategoryPricingDisplay';

interface BookingVehicleCategoryCardT {
  categoryData: MergedAvailableCategoriesData;
}

const VehicleCategoryCard: FC<BookingVehicleCategoryCardT> = ({ categoryData }) => {
  const {
    setFieldValue,
    values: { selectedVehicleCategory },
  } = useFormikContext<BookingOverviewForm>();

  return (
    <Column sm={12}>
      <Card
        className={cn(
          styles['category-card'],
          selectedVehicleCategory === categoryData.id && styles['category-card--selected'],
        )}
        onClick={() => setFieldValue('selectedVehicleCategory', categoryData.id)}
      >
        <span className={styles['category-card__indication']} />
        <Row gapSm="sm" justify="space-between">
          <Column className={styles['category-card__image']} sm={4}>
            {categoryData.appProperties?.image && (
              <img src={categoryData.appProperties.image} alt={categoryData.name} />
            )}
          </Column>
          <Column className={styles['category-card__description']} sm={8}>
            <Paragraph marginBottom={4} weight="medium">
              {categoryData.name}
            </Paragraph>
            <Paragraph color="var(--gs-400)" size={3}>
              <VehicleCategoryPricingDisplay
                extendedPricing={categoryData?.pricing}
                grossPrice={categoryData.grossPrice}
                netPrice={categoryData.netPrice}
                currency={categoryData.currency}
              />
            </Paragraph>
            {categoryData.sharedProperties?.vehicleCategoryFeatures && (
              <VehicleCategoryTags
                features={categoryData.sharedProperties?.vehicleCategoryFeatures}
              />
            )}
          </Column>
        </Row>
      </Card>
    </Column>
  );
};

export default VehicleCategoryCard;
