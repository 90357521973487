import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { VerificationInit } from '@/features/account/types/Verification.types';
import { SupportedVerificationProvider } from '@/features/account/enums';

export const VerificationService = createApi({
  reducerPath: 'api-verification',
  baseQuery: RestClientWrapper,
  endpoints: (builder) => ({
    requestVerificationSessionData: builder.query<VerificationInit, void>({
      query: () => ({
        url: '/front/verification/init',
        requestParams: {
          method: 'POST',
          data: {
            supportedTypes: Object.values(SupportedVerificationProvider),
          },
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useRequestVerificationSessionDataQuery } = VerificationService;
