import React, { FC, Fragment } from 'react';
import { Row, Column, Tags, Tooltip, Paragraph } from '@gourban/ui-components';
import { VehicleCategoryFeatures } from '@/features/vehicles/types';

interface BookingVehicleCategoryTagsT {
  features: VehicleCategoryFeatures[];
}

const VehicleCategoryTags: FC<BookingVehicleCategoryTagsT> = ({ features }) => {
  return (
    <Row gapSm="xs">
      {features.slice(0, 2).map((feature) => (
        <Column key={feature.title}>
          <Tags
            style="squared"
            size="tiny"
            bgColor="transparent"
            borderColor="var(--gs-200)"
            content={feature.title}
          />
        </Column>
      ))}
      {features.length > 2 && (
        <Column>
          <Tooltip
            placement="bottom"
            content={
              <Paragraph marginBottom={0} color="white" size={3}>
                {features.slice(2, features.length).map((feature) => (
                  <Fragment key={feature.title}>
                    {feature.title} <br />
                  </Fragment>
                ))}
              </Paragraph>
            }
          >
            <Tags
              style="squared"
              size="tiny"
              bgColor="transparent"
              borderColor="var(--gs-200)"
              content="..."
              weight="bold"
            />
          </Tooltip>
        </Column>
      )}
    </Row>
  );
};

export default VehicleCategoryTags;
