import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import '@gourban/ui-components/dist/lib/index.css';
import '@/core/assets/scss/index.scss';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from '@/core/App';

Sentry.init({
  dsn: 'https://7898af9c088247dfa85f622ad2e26a7f@o369452.ingest.sentry.io/4505442531803136',
  environment: import.meta.env.VITE_TARGET_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  beforeSend: (event) => {
    if (!import.meta.env.VITE_TARGET_ENV) {
      return null;
    }
    return event;
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_TARGET_ENV === 'production' ? 0.5 : 1.0,
});

Sentry.setTag('app', 'BookingWeb');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
