import React from 'react';
import AccountLayout from '@/features/account/components/Edit/Layout';
import BasicInfo from '@/features/account/components/Edit/BasicInfo';
import { FormikProvider, useFormik } from 'formik';
import { format } from 'date-fns';
import { UserData } from '@/features/auth/types/User.types';
import { useTypedSelector } from '@/core/redux/hooks';
import { getMeData } from '@/features/account/redux/account.selectors';
import { useRequestUserUpdateMutation } from '@/features/account/services/Account.service';
import { useProfileSchema } from '@/features/auth/hooks/useProfileSchema';
import { useNavigate } from 'react-router-dom';
import { booking } from '@/core/pages/Booking/routes';
import { Button } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';
import { omit } from 'lodash';
import { formatPhoneNumber } from '@/core/utils/formatPhoneNumber';

type UserDataForm = Pick<
  UserData,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'birthdate'
  | 'street'
  | 'city'
  | 'postcode'
  | 'country'
  | 'customProperties'
>;

const AccountEditBasicInfo = () => {
  const meData = useTypedSelector(getMeData)!;
  const [updateUser] = useRequestUserUpdateMutation();
  const { validationSchema, profile } = useProfileSchema();
  const navigate = useNavigate();

  const form = useFormik<UserDataForm>({
    initialValues: {
      firstName: meData.firstName,
      lastName: meData.lastName,
      email: meData.email,
      phone: meData.phone,
      birthdate: meData.birthdate ? new Date(meData.birthdate) : undefined,
      customProperties: {
        birthPlace: meData?.customProperties?.birthPlace ?? '',
      },
      street: meData.street,
      city: meData.city,
      postcode: meData.postcode,
      country: meData.country,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values: UserDataForm) => {
      const { firstName, lastName, email, city, birthdate, country, phone, postcode, street } =
        values;

      return updateUser({
        ...omit(values, ['email']),
        firstName: profile.firstName.editable || !meData.firstName ? firstName : undefined,
        lastName: profile.lastName.editable || !meData.lastName ? lastName : undefined,
        email: profile.email.editable || !meData.email ? email : undefined,
        street: profile.street.editable || !meData.street ? street : undefined,
        city: profile.city.editable || !meData.city ? city : undefined,
        country: profile.country.editable || !meData.country ? country : undefined,
        phone: profile.phone.editable || !meData.phone ? formatPhoneNumber(phone) : undefined,
        postcode: profile.postcode.editable || !meData.postcode ? postcode : undefined,
        birthdate:
          birthdate && (profile.birthdate.editable || !meData.birthdate)
            ? format(new Date(birthdate), 'yyyy-MM-dd')
            : undefined,
        customProperties: { ...(meData.customProperties ?? {}), ...values.customProperties },
      });
    },
  });

  return (
    <AccountLayout
      actions={
        <Button onClick={() => navigate(booking.path)} variation="secondary">
          <Trans id="general.cancel">Cancel</Trans>
        </Button>
      }
    >
      <FormikProvider value={form}>
        <BasicInfo />
      </FormikProvider>
    </AccountLayout>
  );
};

export default AccountEditBasicInfo;
