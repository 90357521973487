import React, { FC } from 'react';
import styles from '@/core/assets/scss/components/CodeSnippet.module.scss';
import { prettyPrintJson } from 'pretty-print-json';

const CodeSnippet: FC<{ data: Record<any, any> }> = ({ data }) => {
  const html = prettyPrintJson.toHtml(data, { indent: 8 });
  return (
    <div className={styles['code-snippet']}>
      <span dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};

export default CodeSnippet;
