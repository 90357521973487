import React, { FC, useEffect } from 'react';
import { Row, Paragraph, Separator, Column, Text, Button, Svg } from '@gourban/ui-components';
import { formatNumber } from '@/core/utils/formatNumber';
import { useLazyRequestPackageInformationWithPromoCodeQuery } from '@/features/payment/services/Payment.service';
import { Formik, Form, FormikHelpers } from 'formik';
import styles from '../../assets/Onboarding/PackagePriceDetails.module.scss';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import { getPromoCode } from '@/features/account/redux/account.selectors';
import { setPromoCode } from '@/features/account/redux/account.reducer';
import { ApiError } from '@/core/types';
import { t, Trans } from '@lingui/macro';
import { PackageInfo } from '@/features/account/types';
import { useLingui } from '@lingui/react';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';

interface PromoCodeFormValues {
  promoCode: string;
}

interface PriceDetailsT {
  packageInfo: PackageInfo;
}

const PackagePriceDetails: FC<PriceDetailsT> = ({ packageInfo }) => {
  const dispatch = useTypedDispatch();
  const promoCode = useTypedSelector(getPromoCode);
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const [requestPackageInfoWithPromoCode, { data: promoCodePackageInfo }] =
    useLazyRequestPackageInformationWithPromoCodeQuery();
  const { i18n } = useLingui();

  const priceDetails = promoCode
    ? promoCodePackageInfo?.discountedPriceDetails!
    : packageInfo?.discountedPriceDetails ?? packageInfo.priceDetails;

  useEffect(() => {
    // when this component is destroyed and mounted again,
    // if promoCode and packageInfo exist, take the cached value
    // (second argument that is set to true) because
    // useLazy hook doesn't return cached data when called for the first time
    if (promoCode && packageInfo) {
      requestPackageInfoWithPromoCode(
        {
          promotionCode: promoCode,
          packageCode: packageInfo.code,
          branchId: resolvedBranch!.id,
        },
        true,
      );
    }
  }, [packageInfo, promoCode, requestPackageInfoWithPromoCode, resolvedBranch]);

  const onSubmit = (
    { promoCode: newPromoCode }: PromoCodeFormValues,
    { setFieldError }: FormikHelpers<PromoCodeFormValues>,
  ) =>
    requestPackageInfoWithPromoCode({
      promotionCode: newPromoCode,
      packageCode: packageInfo.code,
      branchId: resolvedBranch!.id,
    }).then(({ data, error }) => {
      if (data) {
        dispatch(setPromoCode(data.appliedPromotion.code));
      }
      if (error) {
        setFieldError('promoCode', (error as ApiError).userMessage);
      }
    });

  return (
    <Row justify="center">
      <Column sm={12} md={6}>
        <Row justify="space-between" marginBottom={0}>
          <Paragraph weight="medium" marginBottom={0}>
            {packageInfo.name}
          </Paragraph>
          <Paragraph weight="medium" marginBottom={0}>
            {i18n.number((packageInfo.discountedPriceDetails ?? packageInfo.priceDetails).gross, {
              style: 'currency',
              currency: packageInfo.currency,
            })}
          </Paragraph>
        </Row>
        <Row>
          <Paragraph size={3} marginBottom={0}>
            {packageInfo.description}
          </Paragraph>
        </Row>
        {promoCode ? (
          <Row>
            <div className={styles['promo-code']}>
              <Svg icon="discount" />
              {promoCode}
              <Button
                noPadding
                iconName="close"
                width="fit"
                variation="link"
                iconSize={{ height: 12, width: 24 }}
                onClick={() => dispatch(setPromoCode(null))}
              />
            </div>
          </Row>
        ) : (
          <Row marginBottom={0}>
            <Formik<PromoCodeFormValues> initialValues={{ promoCode: '' }} onSubmit={onSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <Row noWrap justify="space-between">
                    <Text
                      fieldAttr={{
                        id: 'promoCode',
                        placeholder: t({
                          id: 'account.priceDetails.promoCodePlaceholder',
                          message: 'Add promo code',
                        }),
                      }}
                      name="promoCode"
                      fieldProps={{
                        inputContainerClassName: styles['promo-code__text'],
                      }}
                    />
                    <Button
                      loading={isSubmitting}
                      variation="primary"
                      className={styles['promo-code__submit']}
                      attributes={{ type: 'submit' }}
                    >
                      <Trans id="account.priceDetails.promoCodeAction">Redeem</Trans>
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </Row>
        )}
        {promoCode && (
          <Row justify="space-between">
            <Paragraph weight="medium">
              <Trans id="account.priceDetails.discount">Discount</Trans> (
              {formatNumber(promoCodePackageInfo!.appliedPromotion.discount * 100, {
                suffix: ' %',
              })}
              )
            </Paragraph>
            <Paragraph weight="medium">
              -{' '}
              {i18n.number(
                promoCodePackageInfo!.priceDetails.gross -
                  promoCodePackageInfo!.discountedPriceDetails.gross,
                { style: 'currency', currency: packageInfo.currency },
              )}
            </Paragraph>
          </Row>
        )}
        <Row justify="space-between">
          <Paragraph weight="medium">
            <Trans id="account.priceDetails.oneTimeFee">One-time registration fee</Trans>
          </Paragraph>
          <Paragraph weight="medium">
            {i18n.number(priceDetails.net, { style: 'currency', currency: packageInfo.currency })}
          </Paragraph>
        </Row>
        <Row justify="space-between">
          <Paragraph weight="medium">
            <Trans id="account.priceDetails.tax">Tax</Trans> (
            {formatNumber(priceDetails.taxPercentage, { suffix: '%' })})
          </Paragraph>
          <Paragraph weight="medium">
            {i18n.number(priceDetails.tax, { style: 'currency', currency: packageInfo.currency })}
          </Paragraph>
        </Row>
        <Separator backgroundColor="var(--gs-300)" gapTop={0} gapBottom={16} />
        <Row justify="space-between">
          <Paragraph size={1} weight="regular">
            <Trans id="account.priceDetails.total">Total</Trans>
          </Paragraph>
          <Paragraph size={1} weight="regular">
            {i18n.number(priceDetails.gross, { style: 'currency', currency: packageInfo.currency })}
          </Paragraph>
        </Row>
      </Column>
    </Row>
  );
};

export default PackagePriceDetails;
