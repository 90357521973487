/**
 * This function is used when we want to conditionally add something to an array
 * Inside the array, spread this function and pass conditions and payload
 * Example:
 * [...addToArrayConditionally(condition, { something: 'something' })]
 */
export const addToArrayConditionally = <T>(condition: boolean, payload: T): T[] | [] => {
  if (condition) {
    return [payload];
  }

  return [];
};
