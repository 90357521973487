import { useEffect } from 'react';
import { setStepComplete } from '@/features/account/redux/account.reducer';
import { useTypedSelector } from '@/core/redux/hooks';
import { getIsStepCompleted, getMeData } from '@/features/account/redux/account.selectors';
import { useDispatch } from 'react-redux';
import { useVerificationStatus } from '@/features/account/hooks/useVerificationStatus';
import useAccountDataPooling from './useAccountDataPolling';
import { Locale, useGoUrbanConfigContext } from '@gourban/ui-components';
import { activateLocale } from '@/core/utils/activateLocale';
import { AvailableLocales, WhiteLabelingAPIKeys } from '@/core/enums';
import { VehiclesService } from '@/features/vehicles/services/Vehicles.service';
import { VehicleCacheTags } from '@/features/vehicles/enums';
import { UserPaymentState, UserRequiredFieldsProfile } from '@/features/auth/types/User.types';
import { useRequestPackageInformationQuery } from '@/features/payment/services/Payment.service';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import { getSupportedLocale } from '@/core/hooks/useDetectLocale';
import { useProfileSchema } from '@/features/auth/hooks/useProfileSchema';
import { keys } from 'lodash';
import { WhitelabelService } from '@/core/services/WhiteLabeling.service';

const paymentSourceAddedStates: UserPaymentState[] = ['SOURCE_ADDED'];

const useAccountSetupInit = () => {
  const meData = useTypedSelector(getMeData);
  const dispatch = useDispatch();
  const isVerificationCompleted = useTypedSelector((state) =>
    getIsStepCompleted(state, 'verification'),
  );
  const verificationStatus = useVerificationStatus();
  useAccountDataPooling(verificationStatus);
  const { setConfig } = useGoUrbanConfigContext();
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: packageInformation } = useRequestPackageInformationQuery(resolvedBranch?.id!, {
    skip: !resolvedBranch,
  });
  const { profile } = useProfileSchema();

  useEffect(() => {
    if (!meData) return;

    const { signupFeeSettled, paymentState } = meData;

    const allRequiredFieldsFilled = keys(profile).every((key) => {
      const profileKey = key as keyof UserRequiredFieldsProfile;
      const field = profile[profileKey];

      if (!field.required) return true;

      return !!meData[profileKey];
    });

    dispatch(setStepComplete({ name: 'basic-info', isComplete: allRequiredFieldsFilled }));
    dispatch(
      setStepComplete({
        name: 'payment',
        isComplete:
          !!(packageInformation && signupFeeSettled) ||
          (!packageInformation && paymentSourceAddedStates.includes(paymentState!)),
      }),
    );
    dispatch(
      setStepComplete({
        name: 'verification',
        isComplete:
          verificationStatus === 'successful' ||
          verificationStatus === 'pending' ||
          // If verification is manually set to be completed after verifications, don't overwrite it since backend can be late with webhooks
          isVerificationCompleted,
      }),
    );
  }, [meData, dispatch, verificationStatus, isVerificationCompleted, packageInformation, profile]);

  useEffect(() => {
    if (!meData) return;

    const locale = meData.language;
    const supportedLocale = getSupportedLocale(locale);

    if (supportedLocale) {
      setConfig!((prev) => ({ ...prev, locale: supportedLocale as Locale }));
      void activateLocale(supportedLocale as AvailableLocales);
      dispatch(VehiclesService.util?.invalidateTags([VehicleCacheTags.VEHICLE_ATTRIBUTES]));
      dispatch(WhitelabelService.util.invalidateTags([WhiteLabelingAPIKeys.WHITE_LABEL]));
    }
  }, [meData, setConfig, dispatch]);
};

export default useAccountSetupInit;
