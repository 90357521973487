export enum GeneralRentalRequirements {
  USER_HAVE_ENOUGH_BALANCE = 'USER_HAVE_ENOUGH_BALANCE',
  USER_HAS_NO_UNPAID_INVOICES = 'USER_HAS_NO_UNPAID_INVOICES',
  RIDE_PASS = 'RIDE_PASS',
  TERMS_ACCEPTED_LATEST = 'TERMS_ACCEPTED_LATEST',
  TERMS_ACCEPTED = 'TERMS_ACCEPTED',
  USER_IS_NOT_LOCKED = 'USER_IS_NOT_LOCKED',
  SIGNUP_FEE_PUSH = 'SIGNUP_FEE_PUSH',
  SIGNUP_FEE_SOURCE = 'SIGNUP_FEE_SOURCE',
}

export enum UserProfileRentalRequirements {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  BIRTHDATE = 'BIRTHDATE',
  STREET = 'STREET',
  POSTCODE = 'POSTCODE',
  CITY = 'CITY',
  COUNTRY = 'COUNTRY',
  MINIMUM_AGE = 'MINIMUM_AGE',
  MAXIMUM_AGE = 'MAXIMUM_AGE',
}

export enum PaymentRentalRequirements {
  PAYMENT_SOURCE = 'PAYMENT_SOURCE',
  PAYMENT_ANY = 'PAYMENT_ANY',
  PAYMENT_PUSH = 'PAYMENT_PUSH',
}

export enum DrivingLicenceRentalRequirements {
  LICENSE = 'LICENSE',
  MANUAL = 'MANUAL',
  USER_REQUIRED_DRIVING_LICENCE_TYPE_PRESENT = 'USER_REQUIRED_DRIVING_LICENCE_TYPE_PRESENT',
}

export enum VehicleCacheTags {
  VEHICLE_ATTRIBUTES = 'VEHICLE_ATTRIBUTES',
}
