import React, { useMemo } from 'react';
import styles from '../../assets/scss/components/AttributesFilters.module.scss';
import AttributeFilterGroup from '@/features/booking/components/Filters/AttributeFilterGroup';
import { Loader } from '@gourban/ui-components';
import { useRequestVehicleAttributesQuery } from '@/features/vehicles/services/Vehicles.service';

const AttributesFilters = () => {
  const { data: vehicleAttributesAPI, isFetching } = useRequestVehicleAttributesQuery();

  const sortedAttributes = useMemo(() => {
    if (!vehicleAttributesAPI) {
      return [];
    }

    return [...vehicleAttributesAPI].sort((a, b) => a.order - b.order);
  }, [vehicleAttributesAPI]);

  return (
    <div className={styles['attributes-filter']}>
      {isFetching ? (
        <Loader cover />
      ) : (
        sortedAttributes.map((vehicleAttribute) => (
          <AttributeFilterGroup
            key={vehicleAttribute.id}
            vehicleAttributeConfig={vehicleAttribute}
          />
        ))
      )}
    </div>
  );
};

export default AttributesFilters;
