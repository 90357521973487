import { FC, useMemo } from 'react';
import { Card, Column, Heading, Paragraph, Row, Svg } from '@gourban/ui-components';
import { Trans, t } from '@lingui/macro';
import { VehicleCategoryRentalRequirement } from '@/features/vehicles/types';
import {
  DrivingLicenceRentalRequirements,
  PaymentRentalRequirements,
  UserProfileRentalRequirements,
} from '@/features/vehicles/enums';
import styles from '@/features/booking/assets/scss/components/RentalRequirementsList.module.scss';
import { Link } from 'react-router-dom';
import {
  accountEditBasicInfo,
  accountEditVerification,
  accountEditPaymentMethods,
} from '@/core/pages/Account/routes';

interface RentalRequirementsListT {
  rentalRequirements: VehicleCategoryRentalRequirement[];
}

interface NavigateActionGroup {
  type: 'NAVIGATE';
  pathname: string;
}

interface InfoActionGroup {
  type: 'INFO';
}

type RequirementGroupAction = NavigateActionGroup | InfoActionGroup;

interface RequirementGroup {
  title: string;
  description?: string;
  fields: string[];
  action?: RequirementGroupAction;
}

interface RentalRequirementGroups {
  userProfileRentalRequirements: RequirementGroup;
  paymentRentalRequirements: RequirementGroup;
  driversLicenceRentalRequirements: RequirementGroup;
  generalRentalRequirements: RequirementGroup;
}

const RentalRequirementsList: FC<RentalRequirementsListT> = ({ rentalRequirements }) => {
  const groupedRentalRequirements = useMemo<RentalRequirementGroups>(() => {
    const userProfileRentalRequirements = Object.keys(UserProfileRentalRequirements);
    const paymentRentalRequirements = Object.keys(PaymentRentalRequirements);
    const drivingLicenceRentalRequirements = Object.keys(DrivingLicenceRentalRequirements);

    return rentalRequirements.reduce<RentalRequirementGroups>(
      (groups, { rentalRequirement, message }) => {
        switch (true) {
          case userProfileRentalRequirements.includes(rentalRequirement): {
            groups.userProfileRentalRequirements.fields.push(message);
            break;
          }
          case paymentRentalRequirements.includes(rentalRequirement): {
            groups.paymentRentalRequirements.fields.push(message);
            break;
          }
          case drivingLicenceRentalRequirements.includes(rentalRequirement): {
            groups.driversLicenceRentalRequirements.fields.push(message);
            break;
          }
          default: {
            groups.generalRentalRequirements.fields.push(message);
          }
        }

        return groups;
      },
      {
        userProfileRentalRequirements: {
          title: t({
            id: 'booking.rentalRequirementUserProfileGroup.title',
            message: 'Complete your profile',
          }),
          description: t({
            id: 'booking.rentalRequirementUserProfileGroup.description',
            message: 'Provide the essential details',
          }),
          fields: [],
          action: {
            type: 'NAVIGATE',
            pathname: accountEditBasicInfo.path,
          },
        },
        paymentRentalRequirements: {
          title: t({
            id: 'booking.rentalRequirementPaymentProfileGroup.title',
            message: 'Payment sources',
          }),
          description: t({
            id: 'booking.rentalRequirementPaymentGroup.description',
            message: 'Complete your payment method',
          }),
          fields: [],
          action: {
            type: 'NAVIGATE',
            pathname: accountEditPaymentMethods.path,
          },
        },
        driversLicenceRentalRequirements: {
          title: t({
            id: 'booking.rentalRequirementDriversLicenceGroup.title',
            message: 'Drivers licence',
          }),
          fields: [],
          action: {
            type: 'NAVIGATE',
            pathname: accountEditVerification.path,
          },
        },
        generalRentalRequirements: {
          title: t({
            id: 'booking.rentalRequirementGeneralGroup.title',
            message: 'Rental requirements not met',
          }),
          action: {
            type: 'INFO',
          },
          fields: [],
        },
      },
    );
  }, [rentalRequirements]);

  return (
    <>
      <Paragraph marginBottom={4} size={2}>
        <Trans id="booking.rentalRequirementsList.title">Requirements not fulfilled</Trans>
      </Paragraph>
      <Paragraph size={3} color="var(--gs-500)">
        <Trans id="booking.rentalRequirementsList.description">
          Below is the list of requirements you need to fulfil to continue.
        </Trans>
      </Paragraph>

      <Row>
        {/* Object entries args has a type of [s: string] and we provided specific keys, so it complains */}
        {/* @ts-ignore */}
        {Object.entries<RequirementGroup>(groupedRentalRequirements).map(([groupName, values]) => {
          if (!values.fields.length) {
            return null;
          }

          return (
            <Column key={groupName} marginBottom={16} sm={12}>
              <Card>
                <Row marginBottom={4} justify="space-between">
                  <Heading marginBottom={0} size={5}>
                    {values.title}
                  </Heading>
                  {values.action?.type === 'NAVIGATE' && (
                    <Link to={values.action.pathname}>
                      <Svg icon="arrowRight" />
                    </Link>
                  )}
                </Row>

                {values.description && (
                  <Paragraph marginBottom={0} size={3} color="var(--gs-500)">
                    {values.description}
                  </Paragraph>
                )}

                {!!values.fields?.length && (
                  <ul className={styles['rental-requirements-list']}>
                    {values.fields.map((field) => {
                      return <li key={field}>{field}</li>;
                    })}
                  </ul>
                )}
              </Card>
            </Column>
          );
        })}
      </Row>
    </>
  );
};

export default RentalRequirementsList;
