import { useState, useEffect, useCallback } from 'react';

/**
 * This hook is used to return width of the screen when resize of the window occurs
 */
export const useWindowResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { width };
};
