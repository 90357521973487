import { lowerCase } from 'lodash';
import { BaseUnitDisplay } from '@/core/types';

interface GetFormattedUnitDisplayT extends BaseUnitDisplay {
  locale?: Intl.UnicodeBCP47LocaleIdentifier;
}

/**
 * Supports formatting of time & distance units in short or long form with value included or not
 * Also supports custom formatting of "per-unit" display, but only in short form with slash and no value. e.g., /min, /h, /d
 */
export const getFormattedUnitDisplay = ({
  unit,
  locale = 'en',
  quantity = 1,
  unitDisplay = 'short',
  includeValue,
}: GetFormattedUnitDisplayT) => {
  const per = unitDisplay === 'shortPer' || unitDisplay === 'longPer';
  const normalizedUnit = lowerCase(unit).slice(0, -1);
  // minute-per is used just to allow proper formatting, the minute portion is stripped off later
  const mappedUnit = per ? `minute-per-${normalizedUnit}` : normalizedUnit;

  const perUnitDisplay: Intl.NumberFormatOptions['unitDisplay'] =
    unitDisplay === 'shortPer' ? 'short' : 'long';

  const numberFormatter = new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: mappedUnit,
    unitDisplay: per ? perUnitDisplay : unitDisplay,
  });

  const valueWithUnit = numberFormatter.format(quantity);
  const unitLabel = includeValue ? valueWithUnit : valueWithUnit.split(' ').slice(1).join(' ');

  if (per) {
    return perUnitDisplay === 'short'
      ? `/${unitLabel.split('/').at(-1)}`
      : unitLabel.split(' ').slice(1).join(' ');
  }
  return unitLabel;
};
