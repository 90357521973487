import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { SupportMethods } from '@/core/types';
import { SettingsKeys } from '@/core/enums';

const useMailtoLink = () => {
  const supportMethods = useTypedSelector((state) =>
    getSetting<SupportMethods>(state, SettingsKeys.SUPPORT_METHODS),
  );
  const email = supportMethods?.email || 'support@gourban.co';
  return { mailto: `mailto:${email}`, email };
};

export default useMailtoLink;
