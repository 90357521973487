import { createApi } from '@reduxjs/toolkit/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import {
  BranchWithTerms,
  ResolvedBranches,
  Branch,
  ResolvedBranch,
} from '@/core/features/branches/types';
import { BranchAPIKeys } from '@/features/branches/enums';

export const BranchesService = createApi({
  reducerPath: 'api-branches',
  baseQuery: RestClientWrapper,
  tagTypes: [BranchAPIKeys.BRANCH_TERMS_AND_CONDITIONS],
  endpoints: (builder) => ({
    requestResolvedBranch: builder.query<ResolvedBranch, void>({
      query: () => ({
        url: '/front/branches/resolve',
        config: {
          ignoreJWTCheck: true,
        },
      }),
      keepUnusedDataFor: 30 * 60,
      transformResponse: (data: ResolvedBranches) => {
        return data.branches[0];
      },
    }),
    requestBranchTermsAndConditions: builder.query<BranchWithTerms, number>({
      query: (branchId) => ({
        url: '/front/termsandconditions',
        requestParams: {
          params: { branchId },
        },
        config: {
          displayNotificationOnError: false,
          ignoreJWTCheck: true,
        },
      }),
      providesTags: (data, meta, branchId) => [
        { type: BranchAPIKeys.BRANCH_TERMS_AND_CONDITIONS, id: branchId },
      ],
    }),

    requestAllBranches: builder.query<Branch[], void>({
      query: () => ({
        url: '/front/branches',
      }),
      keepUnusedDataFor: 60 * 60,
    }),

    requestSingleBranch: builder.query<Branch, string>({
      query: (id) => ({
        url: `/front/branches/${id}`,
      }),
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const {
  useRequestResolvedBranchQuery,
  useRequestBranchTermsAndConditionsQuery,
  useRequestAllBranchesQuery,
  useRequestSingleBranchQuery,
} = BranchesService;
