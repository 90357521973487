import { createApi } from '@reduxjs/toolkit/query/react';
import storage from 'redux-persist/lib/storage';
import {
  AuthenticationData,
  LoginCredentials,
  PhoneLoginCredentials,
  ResetPasswordArgs,
} from '@/features/auth/types/Auth.types';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { LocalStorageKeys } from '@/core/enums';
import { resetAPIState } from '@/core/redux/utils';

export const AuthService = createApi({
  reducerPath: 'api-auth',
  baseQuery: RestClientWrapper,
  tagTypes: [],
  endpoints: (builder) => ({
    requestUserLogin: builder.mutation<AuthenticationData, LoginCredentials>({
      query: (loginCredentials) => ({
        url: '/auth/sign-in-email',
        requestParams: { method: 'post', data: loginCredentials },
        config: { displayNotificationOnError: false, ignoreJWTCheck: true },
      }),
    }),

    requestRefreshToken: builder.mutation<AuthenticationData, string>({
      query: (token) => ({
        url: '/auth/refresh-token',
        requestParams: { method: 'post', data: { refreshToken: token } },
        config: {
          ignoreJWTCheck: true,
        },
      }),
    }),

    requestResetPassword: builder.mutation<void, ResetPasswordArgs>({
      query: ({ token, newPassword }) => ({
        url: `/auth/set-password/${token}`,
        requestParams: {
          method: 'post',
          data: {
            password: newPassword,
            reEnteredPassword: newPassword,
          },
        },
        config: {
          displayNotificationOnError: false,
          ignoreJWTCheck: true,
        },
      }),
    }),

    requestSignOut: builder.mutation<undefined, void>({
      queryFn: async () => {
        await storage.removeItem(LocalStorageKeys.AUTH);
        resetAPIState();
        return { data: undefined };
      },
    }),
    requestPhoneLogin: builder.mutation<AuthenticationData, PhoneLoginCredentials>({
      query: ({ phoneNumber, code }) => ({
        url: '/auth/sign-in-phone-number',
        requestParams: {
          method: 'post',
          data: {
            phoneNumber,
            code,
          },
        },
        config: {
          displayNotificationOnError: false,
          ignoreJWTCheck: true,
        },
      }),
    }),
    requestCreateOrSignInWithPhoneNumber: builder.mutation<
      AuthenticationData,
      PhoneLoginCredentials
    >({
      query: ({ phoneNumber, branchId, signature, timestamp }) => ({
        url: `/front/customers/create-or-sign-in-with-phone-number?signature=${signature}&timestamp=${timestamp}`,
        requestParams: {
          method: 'post',
          data: {
            phoneNumber,
            branchId,
          },
        },
        config: {
          //displayNotificationOnError: false,
          ignoreJWTCheck: true,
        },
      }),
    }),
  }),
});

export const {
  useRequestUserLoginMutation,
  useRequestResetPasswordMutation,
  useRequestSignOutMutation,
  useRequestPhoneLoginMutation,
  useRequestCreateOrSignInWithPhoneNumberMutation,
} = AuthService;
