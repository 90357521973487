import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useRequestTenantInfoQuery } from '@/features/auth/services/Tenant.service';
import { Loader } from '@gourban/ui-components';
import { useRequestPublicSettingsBulkQuery } from '@/core/services/SettingsManagement.service';
import useFeatureCheck from '@/features/auth/hooks/useFeatureCheck';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { TenantAccountSettings } from '@/features/geomap/types';
import { SettingsKeys } from '@/core/enums';
import { TenantFeatures } from '@/features/auth/enums';
import { getIsLoggedIn } from '@/features/auth/redux/authorization.selectors';
import useRequestUserSignOut from '@/features/auth/hooks/useRequestUserSignOut';
import { ApiError } from '@/core/types';
import TenantError from '@/features/auth/components/TenantError';

const TenantInfoResolver: FC<PropsWithChildren> = ({ children }) => {
  const {
    data: tenantData,
    isLoading: isFetchingTenantInfo,
    error: tenantError,
  } = useRequestTenantInfoQuery();

  const error = tenantError as ApiError;

  const { isLoading: isLoadingPublicSettings } = useRequestPublicSettingsBulkQuery(undefined, {
    skip: !tenantData,
  });
  const hasFeature = useFeatureCheck();
  const hasActiveBusinessAccounts = useTypedSelector((state) =>
    getSetting<TenantAccountSettings>(state, SettingsKeys.TENANT_ACCOUNTS),
  );
  const isLoggedIn = useTypedSelector(getIsLoggedIn);
  const signOut = useRequestUserSignOut();

  useEffect(() => {
    if (
      hasActiveBusinessAccounts &&
      !hasActiveBusinessAccounts?.businessAccounts?.active &&
      !hasFeature(TenantFeatures.ENDUSER_WEB_APP) &&
      isLoggedIn
    ) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, hasActiveBusinessAccounts, hasFeature]);

  if (error && error.errorCode === 'T100') {
    return <TenantError title="Not found" description="Tenant with given name doesnt exist." />;
  }

  if (isLoadingPublicSettings || isFetchingTenantInfo) return <Loader cover />;

  return <>{children}</>;
};

export default TenantInfoResolver;
