import React, { FC, useState } from 'react';
import {
  useRequestBusinessAccountLeaveMutation,
  useRequestBusinessAccountMembershipQuery,
  useRequestBusinessAccountQuery,
} from '@/features/businessAccounts/services/BusinessAccount.service';
import { Button, ConfirmationModal } from '@gourban/ui-components';
import { useNavigate } from 'react-router-dom';
import BusinessCard from '@/features/account/components/Edit/BusinessCard';
import { Trans } from '@lingui/macro';
import BusinessAccountJoinWidget from '@/features/businessAccounts/components/BusinessAccountJoinWidget';

const BusinessAccount: FC = () => {
  const { data: businessAccountOwner, isSuccess: fetchedBusinessAccountOwner } =
    useRequestBusinessAccountQuery();

  const { data: businessAccountMembership } = useRequestBusinessAccountMembershipQuery(undefined, {
    skip: businessAccountOwner && fetchedBusinessAccountOwner,
  });
  const [leaveConfirmationModal, setLeaveConfirmationModal] = useState<string | null>(null);
  const [leaveBusinessAccount, { isLoading: isLeavingBusinessAccount }] =
    useRequestBusinessAccountLeaveMutation();

  const navigate = useNavigate();

  if (businessAccountOwner) {
    return (
      <BusinessCard
        name={businessAccountOwner.name}
        action={
          <Button onClick={() => navigate('/business-account/trips')} size="small" variation="link">
            <Trans id="general.manage">Manage</Trans>
          </Button>
        }
      />
    );
  }

  if (businessAccountMembership?.length) {
    return (
      <>
        {businessAccountMembership.map((businessAccount) => (
          <BusinessCard
            key={businessAccount.code}
            name={businessAccount.name}
            action={
              <Button
                loading={isLeavingBusinessAccount}
                onClick={() => setLeaveConfirmationModal(businessAccount.code)}
                size="small"
                variation="link"
              >
                <Trans id="accountEdit.business.leave">Leave</Trans>
              </Button>
            }
          />
        ))}

        <ConfirmationModal
          onConfirm={() => leaveBusinessAccount(leaveConfirmationModal!)}
          onCancel={() => setLeaveConfirmationModal(null)}
          title={
            <Trans id="accountEdit.business.leaveConfirmationTitle">Leave business account?</Trans>
          }
          deleteMessage={
            <Trans id="accountEdit.business.leaveConfirmationDescription">
              Are you sure you want to leave business account?
            </Trans>
          }
          opened={!!leaveConfirmationModal}
        />
      </>
    );
  }

  return <BusinessAccountJoinWidget />;
};

export default BusinessAccount;
