import { createApi } from '@reduxjs/toolkit/query/react';
import { TenantData } from '@/features/auth/types/Tenant.types';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import restClient, { getTenant, getTenantServiceURL } from '@/core/api/restClient';
import { AxiosResponse } from 'axios';

export const TenantService = createApi({
  reducerPath: 'api-tenant',
  baseQuery: RestClientWrapper,
  tagTypes: [],
  endpoints: (builder) => ({
    requestTenantInfo: builder.query<TenantData, void>({
      query: () => ({
        url: `${getTenantServiceURL()}?lookup=${getTenant()}`,
        config: {
          ignoreJWTCheck: true,
          onRequestSuccess: ({ data }: AxiosResponse<TenantData>) => {
            restClient.defaults.baseURL = data.platformUrl ?? data.coreUrl;
          },
        },
      }),
      keepUnusedDataFor: Infinity,
    }),
  }),
});

export const { useRequestTenantInfoQuery } = TenantService;
