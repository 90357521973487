import React from 'react';
import { Heading, Paragraph } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';
import CompanyInfoFormFields from './CompanyInfoFormFields';

const CompanyInfoStep = () => {
  return (
    <>
      <Heading size={3}>
        <Trans id="businessAccount.create.companyInfo">Company info</Trans>
      </Heading>
      <Paragraph marginBottom={32}>
        <Trans id="businessAccount.create.companyInfoDescription">
          Please provide the official details of your company as registered. This information is
          vital for verification purposes and for the correct addressing of any communications or
          agreements.
        </Trans>
      </Paragraph>

      <CompanyInfoFormFields />
    </>
  );
};

export default CompanyInfoStep;
