export enum AccountCacheTags {
  REQUEST_ME_DATA = 'requestMeData',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  PAYMENT_SOURCES = 'paymentSources',
}

/**
 * When presets or user preferred config are saved, they are saved under an entity key that is later looked for when loading that entity.
 * Here we store all available entities
 */
export enum UserEntityKeys {
  TRIPS = 'TRIPS',
  ON_DEMAND_TRIPS = 'ON_DEMAND_TRIPS',
  COST_CENTERS = 'COST_CENTERS',
  MEMBERS = 'MEMBERS',
  JOIN_REQUESTS = 'JOIN_REQUESTS',
}

/**
 * List of supported verification providers
 */
export enum SupportedVerificationProvider {
  ONFIDO_SDK = 'ONFIDO_SDK',
  VERIFF_SDK = 'VERIFF_SDK',
}
