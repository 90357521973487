import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { FeatureCollection } from 'geojson';
import { createApi } from '@reduxjs/toolkit/query/react';
import { i18n } from '@lingui/core';
import { LocationGeocodingArgs } from '@/features/geomap/types';

export const MapService = createApi({
  reducerPath: 'api-geomap',
  baseQuery: RestClientWrapper,
  endpoints: (builder) => ({
    /* Retrieves countries */
    requestCountryGeocoding: builder.query<{ data: FeatureCollection['features'] }, string>({
      query: (query) => {
        return {
          url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
          requestParams: {
            params: {
              types: 'country',
              limit: 10,
              access_token: import.meta.env.VITE_MAPBOX_ACCESSTOKEN,
              language: i18n.locale,
            },
          },
        };
      },
      transformResponse: (data: FeatureCollection) => {
        return { data: data.features };
      },
    }),

    /* Retrieves cities */
    requestCityGeocoding: builder.query<{ data: FeatureCollection['features'] }, string>({
      query: (query) => ({
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
        requestParams: {
          params: {
            proximity: 'ip',
            types: 'place',
            limit: 10,
            access_token: import.meta.env.VITE_MAPBOX_ACCESSTOKEN,
            language: i18n.locale,
          },
        },
      }),
      transformResponse: (data: FeatureCollection) => {
        return { data: data.features };
      },
    }),

    /* Retrieves locations */
    requestLocationGeocoding: builder.query<
      { data: FeatureCollection['features'] },
      LocationGeocodingArgs
    >({
      query: ({ query, proximityCoordinates, mapSearchSettings }) => ({
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
        requestParams: {
          params: {
            ...mapSearchSettings,
            proximity: proximityCoordinates
              ? `${proximityCoordinates.lng},${proximityCoordinates.lat}`
              : 'ip',
            access_token: import.meta.env.VITE_MAPBOX_ACCESSTOKEN,
            language: i18n.locale,
          },
        },
      }),
      transformResponse: (data: FeatureCollection) => {
        return { data: data.features };
      },
    }),
  }),
});

export const {
  useLazyRequestCityGeocodingQuery,
  useLazyRequestCountryGeocodingQuery,
  useLazyRequestLocationGeocodingQuery,
} = MapService;
