import React, { useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { Loader, Paragraph, Row } from '@gourban/ui-components';
import { useFormikContext } from 'formik';
import { BookingOverviewForm, BookingSteps } from '@/features/booking/types';
import { useRequestVehicleAdditionsQuery } from '@/core/features/vehicles/services/Vehicles.service';
import AdditionCard from './AdditionCard';
import { useTypedSelector } from '@/core/redux/hooks';
import { getVehicleCategoryData } from '@/features/vehicles/redux/Vehicles.selector';
import { Trans } from '@lingui/macro';
import { BookingFlowIDs } from '@/features/booking/components/BookingFlow/BookingFlow';

interface AdditionsSelectionT<Steps> extends Omit<BookingFlowIDs, 'bookingId'> {
  setSteps: Dispatch<SetStateAction<Steps>>;
}

const AdditionsSelection = <Steps extends Partial<BookingSteps>>({
  setSteps,
  branchId,
}: AdditionsSelectionT<Steps>) => {
  const { values, setFieldValue } = useFormikContext<BookingOverviewForm>();
  const vehicleCategories = useTypedSelector((state) =>
    getVehicleCategoryData(state, values.selectedVehicleCategory),
  );
  const { data: additionsData, isFetching } = useRequestVehicleAdditionsQuery({
    branchId,
    startDate: values.bookingDate?.from!.toISOString()!,
    endDate: values.bookingDate?.to
      ? values.bookingDate?.to.toISOString()
      : values.bookingDate?.from!.toISOString()!,
    startTime: values.bookingTime?.from!,
    endTime: values.bookingTime?.to!,
    categoryId: values.selectedVehicleCategory!,
  });

  useEffect(() => {
    const selected = additionsData
      ? values.selectedAdditions?.filter((code) => additionsData?.find((add) => add.code === code))
      : values.selectedAdditions;
    setFieldValue('selectedAdditions', selected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionsData]);

  const filterOutAdditionsWithNoPricing = useMemo(() => {
    if (!additionsData) return [];

    return additionsData
      .filter((addition) => !addition.isInternal)
      .filter((addition) => !!addition.pricingInformation.estimation);
  }, [additionsData]);

  useEffect(() => {
    if (!filterOutAdditionsWithNoPricing.length && !isFetching) {
      setSteps((steps) => ({
        ...steps,
        extras: { ...steps.extras, status: 'completed' },
        summary: { status: 'active' },
      }));
    }
  }, [filterOutAdditionsWithNoPricing, isFetching, setSteps]);

  return (
    <div>
      <Paragraph marginBottom={24} size={2} weight="medium">
        <Trans id="bookings.createOrUpdate.extras">Extras</Trans>
      </Paragraph>

      {isFetching ? (
        <Loader cover />
      ) : (
        <Row gapSm="sm">
          {!!filterOutAdditionsWithNoPricing.length &&
            filterOutAdditionsWithNoPricing.map((addition) => (
              <AdditionCard
                key={addition.name + addition.code}
                currency={vehicleCategories?.currency}
                addition={addition}
              />
            ))}
        </Row>
      )}
    </div>
  );
};

export default AdditionsSelection;
