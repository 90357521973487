import { useEffect, useState } from 'react';

export const useCountdown = (initialCount: number) => {
  const [countdown, setCountdown] = useState(initialCount);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [countdown]);

  return { countdown, setCountdown };
};
