import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteStaticConfig } from '@/core/types';
import StepButton from '@/core/components/StepButton';
import { StepName } from '@/features/account/types';
import { useStepStatus } from '@/features/account/hooks/useStepStatus';
import { useLingui } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';
import { useRequestPackageInformationQuery } from '@/features/payment/services/Payment.service';
import { useTypedSelector } from '@/core/redux/hooks';
import { getPackageInformationWithPromoCode } from '@/features/payment/redux/payment.selectors';
import { getPromoCode } from '@/features/account/redux/account.selectors';
import styles from '@/features/account/assets/Onboarding/Stepper.module.scss';

interface Step extends RouteStaticConfig {
  stepName: StepName;
}

interface StepperT {
  steps: Step[];
}

const isRouteActive = (route: RouteStaticConfig, pathname: string): boolean =>
  Boolean(route.path === pathname || route.additionalPaths?.includes(pathname));

const AccountStepper: FC<StepperT> = ({ steps }) => {
  const { pathname } = useLocation();
  const stepsStatus = useStepStatus();
  const { i18n } = useLingui();
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const { data: packageInformation } = useRequestPackageInformationQuery(resolvedBranch!.id);
  const promoCode = useTypedSelector(getPromoCode);
  const promoCodePackageInfo = useTypedSelector((state) =>
    getPackageInformationWithPromoCode(state, {
      promotionCode: promoCode!,
      packageCode: packageInformation?.code!,
      branchId: resolvedBranch!.id,
    }),
  );
  const packageInfo = promoCodePackageInfo ?? packageInformation;

  return (
    <div className={styles.stepper}>
      {steps.map((step, index) => {
        const disabled = packageInfo ? index > stepsStatus.completed : stepsStatus.completed < 1;

        return (
          <StepButton
            key={step.stepName}
            isActive={isRouteActive(step, pathname)}
            url={step.path}
            label={
              (step.label as MessageDescriptor).id
                ? i18n._(step.label as MessageDescriptor)
                : (step.label as string)
            }
            stepNumber={index + 1}
            isCompleted={!disabled}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

export default AccountStepper;
