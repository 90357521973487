import { FC, PropsWithChildren, useEffect } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { activateLocale } from '@/core/utils/activateLocale';
import { Locale, useGoUrbanConfigContext } from '@gourban/ui-components';
import { useDetectLocale } from '@/core/hooks/useDetectLocale';

const TranslationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setConfig } = useGoUrbanConfigContext();
  const initialLocale = useDetectLocale();

  useEffect(() => {
    void activateLocale(initialLocale);

    setConfig!((prev) => ({ ...prev, locale: initialLocale as Locale }));

    // No need to add initialLocale as dependency here as it only need to run once, on init
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export default TranslationProvider;
