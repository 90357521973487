import { RouteStaticConfig } from '@/core/types';

export const myTrips: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips',
  label: 'My Trips',
};

export const myActiveTrips: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips/active',
  label: 'My Active Trips',
  additionalPaths: ['/my-trips'],
};

export const myUpcomingTrips: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips/upcoming',
  label: 'My Upcoming Trips',
};

export const myPastTrips: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips/past',
  label: 'My Past Trips',
};

export const myCanceledTrips: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips/canceled',
  label: 'My Canceled Trips',
};

export const tripDrivingLog: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips/:id/driving-log',
  label: 'Trip driving log',
};

export const tripDrivingLogCreate: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips/:id/driving-log/create',
  label: 'Trip driving log create',
};

export const tripDrivingLogEdit: RouteStaticConfig = {
  guarded: true,
  path: '/my-trips/:id/driving-log/:drivingLogEntryId/edit',
  label: 'Trip driving log edit',
};
