import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { WhitelabelingAccount, WhitelabelingApp } from '@/core/types';
import { WhiteLabelingAPIKeys } from '@/core/enums';

export const WhitelabelService = createApi({
  reducerPath: 'whitelabeling',
  baseQuery: RestClientWrapper,
  tagTypes: [WhiteLabelingAPIKeys.WHITE_LABEL],
  endpoints: (builder) => ({
    requestWhitelabel: builder.query<WhitelabelingAccount, void>({
      query: () => ({
        url: `/front/white-labeling/account`,
        config: {
          ignoreJWTCheck: true,
        },
      }),
      keepUnusedDataFor: Infinity,
      providesTags: [WhiteLabelingAPIKeys.WHITE_LABEL],
    }),
    requestWhitelabelApp: builder.query<WhitelabelingApp, void>({
      query: () => ({
        url: `/front/white-labeling/app`,
        config: {
          ignoreJWTCheck: true,
        },
      }),
      keepUnusedDataFor: Infinity,
      providesTags: [WhiteLabelingAPIKeys.WHITE_LABEL],
    }),
  }),
});

export const { useRequestWhitelabelQuery, useRequestWhitelabelAppQuery } = WhitelabelService;
