import React, { useState, useRef, FC, lazy } from 'react';
import {
  useLazyRequestPaymentOnSuccessNotifierQuery,
  useRequestPaymentSourceDeleteMutation,
  useRequestPaymentSourcesQuery,
} from '@/features/payment/services/Payment.service';
import {
  Row,
  Column,
  Heading,
  Paragraph,
  Button,
  Loader,
  ConfirmationModal,
} from '@gourban/ui-components';
import styles from '@/features/payment/assets/PaymentSources.module.scss';
import PaymentSource from '@/features/payment/components/PaymentSource';
import { Trans, t } from '@lingui/macro';
import { BusinessAccount } from '@/features/businessAccounts/types';
import { PaymentSource as Source } from '@/features/payment/types/Payment.types';
import { CommonParamTranslations } from '@/core/utils/commonParamTranslations';
import { useTypedDispatch, useTypedSelector } from '@/core/redux/hooks';
import { AccountService } from '@/features/account/services/Account.service';
import { AccountCacheTags } from '@/features/account/enums';
import { getPaymentProvider, getSelectedCardId } from '@/features/account/redux/account.selectors';
import ErrorBoundarySuspense from '@/core/components/ErrorHandlers/ErrorBoundarySuspense';
import { useRequestResolvedBranchQuery } from '@/features/branches/services/Branches.service';

const paymentIntegrations = {
  Stripe: lazy(() => import('@/features/payment/components/StripeIntegration')),
  Adyen: lazy(() => import('@/features/payment/components/AdyenIntegration')),
};

interface PaymentSourcesT {
  businessAccount?: BusinessAccount;
  displayProviderForm?: boolean;
  displayAddPaymentButton?: boolean;
  onAddPaymentMethod?: () => void;
  onPaymentMethodAdded: () => void;
  title?: string;
  description?: string;
}

const PaymentSources: FC<PaymentSourcesT> = ({
  businessAccount,
  displayProviderForm,
  displayAddPaymentButton,
  onAddPaymentMethod,
  onPaymentMethodAdded,
  title = t({ id: 'account.cards.heading', message: 'Add Payment Method' }),
  description,
}) => {
  const { data: resolvedBranch } = useRequestResolvedBranchQuery();
  const {
    data: paymentSources,
    refetch,
    isFetching,
  } = useRequestPaymentSourcesQuery({
    userGroupId: businessAccount?.id,
    branchId: resolvedBranch!.id,
  });
  const [requestOnSuccessNotifier] = useLazyRequestPaymentOnSuccessNotifierQuery();
  const dispatch = useTypedDispatch();
  const paymentProvider = useTypedSelector(getPaymentProvider);
  const [deletePaymentSource, setDeletePaymentSource] = useState<Source | null>(null);
  const [loadingCards, setLoadingCards] = useState(false);
  const [requestDeletePaymentSource] = useRequestPaymentSourceDeleteMutation();
  const selectedCard = useTypedSelector(getSelectedCardId);

  const retires = useRef(0);

  // immediately after the source has been added, there is a delay
  // therefore, we'll retry fetching payment sources multiple times.
  // We'll retry while number of sources is not changed AND retires count is
  // less than 10.
  // While testing, it appears that ~600 ms is enough for newly added source to
  // appear in the list.
  const fetchPaymentSources = () => {
    refetch()
      .unwrap()
      .then((sources) => {
        if (sources?.length === paymentSources?.length && retires.current < 10) {
          // schedule retry after 200 ms
          setTimeout(fetchPaymentSources, 500);
          retires.current += 1;
        } else {
          retires.current = 0;
          setLoadingCards(false);
          dispatch(AccountService.util.invalidateTags([AccountCacheTags.REQUEST_ME_DATA]));
        }
      });
  };

  return (
    <>
      {loadingCards || isFetching ? (
        <Row justify="center">
          <Loader />
        </Row>
      ) : (
        paymentSources?.map((paymentSource) => (
          <PaymentSource
            branchId={resolvedBranch!.id}
            onRequestDelete={setDeletePaymentSource}
            businessAccount={businessAccount}
            paymentSource={paymentSource}
            key={paymentSource.id}
            selected={selectedCard === paymentSource.id}
          />
        ))
      )}

      {displayProviderForm ? (
        <Column className={styles['payment-card']} marginBottom={16}>
          <Heading size={4}>{title}</Heading>
          {description && (
            <Paragraph size={3} color="var(--gs-500)" marginBottom={32}>
              {description}
            </Paragraph>
          )}
          <Row>
            {paymentProvider === 'STRIPE' && (
              <ErrorBoundarySuspense fallback={<Loader />}>
                <paymentIntegrations.Stripe
                  branchId={resolvedBranch!.id}
                  businessAccount={businessAccount}
                  onPaymentSourceAdded={() => {
                    onPaymentMethodAdded();
                    setLoadingCards(true);
                    fetchPaymentSources();
                  }}
                />
              </ErrorBoundarySuspense>
            )}
            {paymentProvider === 'ADYEN' && (
              <ErrorBoundarySuspense fallback={<Loader />}>
                <paymentIntegrations.Adyen
                  branchId={resolvedBranch!.id}
                  businessAccount={businessAccount}
                  onPaymentSourceAdded={(sessionData, sessionResult) => {
                    onPaymentMethodAdded();
                    setLoadingCards(true);
                    requestOnSuccessNotifier({
                      onSuccessURL: sessionData.onSuccess,
                      sessionResult,
                    })
                      .unwrap()
                      .then(() => {
                        fetchPaymentSources();
                      });
                  }}
                />
              </ErrorBoundarySuspense>
            )}
          </Row>
        </Column>
      ) : (
        <Column>
          {displayAddPaymentButton && (
            <Button
              className={styles['payment-card--link']}
              variation="link"
              onClick={onAddPaymentMethod}
              iconName="plus"
              iconColoringMode="stroke"
              iconSize={{ width: 16, height: 16 }}
            >
              <Trans id="account.cards.action">Add Payment Method</Trans>
            </Button>
          )}
        </Column>
      )}

      <ConfirmationModal
        onConfirm={() =>
          requestDeletePaymentSource({
            sourceId: deletePaymentSource!.id,
            userGroupId: businessAccount?.id,
            branchId: resolvedBranch!.id,
          })
        }
        onCancel={() => setDeletePaymentSource(null)}
        title={CommonParamTranslations.removeConfirmationTitle(
          t({ id: 'businessAccount.create.payment', message: 'Payment method' }),
        )}
        deleteMessage={CommonParamTranslations.removeConfirmationDescription(
          t({ id: 'businessAccount.create.payment', message: 'Payment method' }),
        )}
        opened={!!deletePaymentSource}
      />
    </>
  );
};

export default PaymentSources;
