import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { Route } from '@/core/types';
import { ReactNode } from 'react';
import { getChildRoutes } from '@/core/pages/utils/getChildRoutes';
import globalRoutes from '@/core/pages/Routers/globalRouterConfig';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const createRouter = ({
  routes,
  wildcardElement,
}: {
  routes?: Route[]; // optional because we have global routes
  wildcardElement: ReactNode;
}) => {
  const routerRoutes: RouteObject[] = getChildRoutes([...globalRoutes, ...(routes ?? [])]);

  routerRoutes.push({
    path: '*',
    element: wildcardElement,
  });

  return sentryCreateBrowserRouter(routerRoutes, {
    basename: import.meta.env.VITE_PUBLIC_URL?.length ? import.meta.env.VITE_PUBLIC_URL : undefined,
  });
};
