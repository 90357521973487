import { RouteStaticConfig } from '@/core/types';

export const booking: RouteStaticConfig = {
  guarded: true,
  path: '/booking',
  additionalPaths: ['/'],
  label: 'Booking',
};

export const bookingSingle: RouteStaticConfig = {
  guarded: true,
  path: '/booking/:branchId',
  label: 'Booking single',
  additionalPaths: ['/booking/:branchId/edit/:bookingId'],
};
