import { FC, ReactNode } from 'react';
import styles from '@/core/assets/scss/components/Header/Header.module.scss';
import { Row, Column, Button, Heading } from '@gourban/ui-components';
import { t } from '@lingui/macro';

interface HeaderT {
  title: ReactNode;
  actions?: ReactNode;
  onClose?: () => void;
}

const Header: FC<HeaderT> = ({ title, actions, onClose }) => {
  return (
    <header className={styles.header}>
      <Row justify="space-between">
        <Column>
          <Row marginBottom={0} gapSm="sm">
            {onClose && (
              <Button
                attributes={{
                  role: 'link',
                  'aria-label': t({ id: 'navigation.goBack', message: 'Go back' }),
                }}
                onClick={onClose}
                iconName="arrowLeft"
                variation="link"
                size="small"
                noPadding
              />
            )}
            <Heading className={styles.header__title} marginBottom={0} size={4}>
              {title}
            </Heading>
          </Row>
        </Column>
        <Column>
          <div className={styles.header__actions}>{actions}</div>
        </Column>
      </Row>
    </header>
  );
};

export default Header;
