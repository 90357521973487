import { createSlice } from '@reduxjs/toolkit';
import { VehiclesService } from '@/core/features/vehicles/services/Vehicles.service';
import { VehicleStateT } from '@/features/vehicles/types';

const initialState: VehicleStateT = {
  vehiclesCategories: undefined,
  additions: undefined,
};

const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      VehiclesService.endpoints.requestAvailableVehicleCategories.matchFulfilled,
      (state, { payload }) => {
        state.vehiclesCategories = payload;
      },
    );
    builder.addMatcher(
      VehiclesService.endpoints.requestVehicleAdditions.matchFulfilled,
      (state, { payload }) => {
        state.additions = payload;
      },
    );
  },
});

export const { reducer, actions } = vehicleSlice;
