import React, { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from '@gourban/ui-components';
import { useRequestMeDataQuery } from '@/features/account/services/Account.service';
import {
  useRequestBusinessAccountMembershipQuery,
  useRequestBusinessAccountQuery,
} from '@/features/businessAccounts/services/BusinessAccount.service';
import ApprovalPending from './ApprovalPending';
import ApprovalRejected from './ApprovalRejected';
import AccessDenied from '@/core/features/auth/components/AccessDenied';
import Page404 from '@/core/pages/Page404';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { SettingsKeys } from '@/core/enums';
import { TenantAccountSettings } from '@/features/geomap/types';

const BusinessAccountAdminRestricted: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading: isLoadingMeData } = useRequestMeDataQuery();
  const { data: businessAccountData, isLoading: isLoadingBusinessAccounts } =
    useRequestBusinessAccountQuery();
  const { data: businessAccountMember, isLoading: isLoadingBusinessAccountMembership } =
    useRequestBusinessAccountMembershipQuery();
  const tenantBusinessAccountSetting = useTypedSelector((state) =>
    getSetting<TenantAccountSettings>(state, SettingsKeys.TENANT_ACCOUNTS),
  );

  const isBusinessAccountMember = businessAccountMember && businessAccountMember?.length > 0;

  if (isLoadingBusinessAccounts || isLoadingMeData || isLoadingBusinessAccountMembership) {
    return <Loader cover zIndex={100} />;
  }

  if (!tenantBusinessAccountSetting?.businessAccounts?.active) {
    return <Page404 />;
  }

  // If the business account is pending approval, show the approval pending screen
  if (businessAccountData?.approvalState === 'PENDING') {
    return <ApprovalPending />;
  }

  // If the business account is rejected, show the approval rejected screen
  if (businessAccountData?.approvalState === 'REJECTED') {
    return <ApprovalRejected />;
  }

  // If the user is not an owner of the business account, show the access denied screen
  if (isBusinessAccountMember && !businessAccountData) {
    return <AccessDenied />;
  }

  // If user isnt a owner of a business account, redirect to the create business account page
  if (!businessAccountData) {
    return <Navigate to="/business-account/create" />;
  }

  return <>{children}</>;
};

export default BusinessAccountAdminRestricted;
