import React, { useEffect } from 'react';
import TermsAndConditions from '@/features/booking/components/BookingFlow/TermsAndConditions';
import BookingFlow from '@/features/booking/components/BookingFlow/BookingFlow';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequestUserTermsAndConditionsStatusQuery } from '@/features/account/services/Account.service';
import { Loader, useMap } from '@gourban/ui-components';
import { useBookingPreparation } from '@/features/booking/hooks/useBookingPreparation';
import OverviewModal from '@/core/features/booking/components/OverviewModal/OverviewModal';
import { useGoBack } from '@/core/hooks/useGoBack';
import { useBookingFlowSteps } from '@/core/features/booking/hooks/useBookingFlowSteps';
import { BookingOverviewForm, BookingSteps } from '@/features/booking/types';
import { trim } from 'lodash';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import {
  useRequestBookingCreateMutation,
  useRequestBookingUpdateMutation,
} from '@/features/booking/services/Booking.service';

const BookingOverview = () => {
  const { branchId, bookingId } = useParams();
  const { steps, setSteps } = useBookingFlowSteps<BookingSteps>([
    'date',
    'account',
    'vehicleCategory',
    'vehicleCategorySummary',
    'extras',
    'summary',
    'thankYou',
  ]);
  const navigate = useNavigate();
  const goBack = useGoBack();
  const mapInstance = useMap();
  const {
    data: termsAndConditions,
    isFetching,
    isError: errorFetchingTermsAndConditions,
  } = useRequestUserTermsAndConditionsStatusQuery(branchId!);
  const { isFetchingBookingData, bookingInitialValues, isError, bookingData } =
    useBookingPreparation(branchId, bookingId, termsAndConditions?.state);
  const [createBooking] = useRequestBookingCreateMutation();
  const [updateBooking] = useRequestBookingUpdateMutation();

  const onSubmitBooking = async (values: BookingOverviewForm) => {
    const {
      bookingDate,
      bookingTime,
      selectedVehicleCategory: vehicleCategoryId,
      selectedAdditions: additions,
      selectedPaymentMethod,
      selectedPaymentType,
      reason,
    } = values;

    const data = {
      branchId: branchId!,
      vehicleCategoryId: vehicleCategoryId!,
      additions,
      reason: trim(reason),
      rentalType: selectedPaymentType!,
      userGroupCode: selectedPaymentType === 'BUSINESS' ? selectedPaymentMethod! : null,
      startTime: new Date(mergeDateTime(bookingDate!.from!, bookingTime?.from)).toISOString(),
      endTime: new Date(
        mergeDateTime(bookingDate?.to ?? bookingDate!.from!, bookingTime?.to),
      ).toISOString(),
    };

    let booking;

    if (bookingId) {
      booking = await updateBooking({ id: bookingId, ...data }).unwrap();
    } else {
      booking = await createBooking(data).unwrap();
    }

    setSteps((prev) => ({
      ...prev,
      summary: { status: 'completed' },
      thankYou: { status: 'active' },
    }));

    return Promise.resolve(booking);
  };

  useEffect(() => {
    const map = mapInstance.current;

    if (!map) {
      return undefined;
    }

    map.getMap().dragPan.disable();
    map.getMap().scrollZoom.disable();
    map.getMap().keyboard.disable();
    map.getMap().doubleClickZoom.disable();

    return () => {
      map.getMap().dragPan.enable();
      map.getMap().scrollZoom.enable();
      map.getMap().keyboard.enable();
      map.getMap().doubleClickZoom.enable();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingData && bookingData.branchId !== +branchId! && bookingId) {
      navigate(`/booking/${bookingData.branchId}/edit/${bookingId}`);
    }

    if (isError) {
      navigate('/booking');
    }
  }, [bookingData, bookingId, branchId, navigate, isError]);

  if (isError || errorFetchingTermsAndConditions) {
    return <OverviewModal onClose={goBack} modalHeader={<span />} displayFallback />;
  }

  if (isFetching || isFetchingBookingData) {
    return <Loader cover />;
  }

  if (!termsAndConditions) {
    return null;
  }

  if (termsAndConditions.state === 'NOT_ACCEPTED') {
    return <TermsAndConditions />;
  }

  return (
    <BookingFlow<BookingSteps>
      onClose={() => navigate({ pathname: '..', search: window.location.search })}
      onBookingSubmit={onSubmitBooking}
      onBookingCompleted={() => navigate('/')}
      branchId={String(branchId)}
      bookingId={bookingId && String(bookingId)}
      flowSteps={steps}
      setSteps={setSteps}
      initialValues={bookingInitialValues}
    />
  );
};

export default BookingOverview;
