const zeroPad = (value: string, places = 0) => value.padStart(places, '0');

interface FormatNumberOptions {
  fractionDigits?: number;
  prefix?: string;
  suffix?: string;
  leadingZeroes?: number;
}

export const formatNumber = (
  value: number,
  { fractionDigits = 2, prefix = '', suffix = '', leadingZeroes = 0 }: FormatNumberOptions = {},
): string => {
  return prefix + zeroPad(value.toFixed(fractionDigits), leadingZeroes) + suffix;
};
