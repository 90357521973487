import React, { FC } from 'react';
import { Button, Column, Row } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';
import { useFormikContext } from 'formik';
import { DrivingLogCreateForm } from '@/features/myTrips/types/DrivingLog';
import { useGoBack } from '@/core/hooks/useGoBack';
import { useNavigate } from 'react-router-dom';

interface TripDrivingLogModalActionsT {
  mode: 'edit' | 'create';
}

const TripDrivingLogModalActions: FC<TripDrivingLogModalActionsT> = ({ mode }) => {
  const { submitForm, dirty, isValid } = useFormikContext<DrivingLogCreateForm>();
  const goBack = useGoBack();
  const navigate = useNavigate();

  return (
    <Row justify="space-between">
      <Column>
        <Button variation="secondary" onClick={() => (mode === 'edit' ? navigate(-1) : goBack())}>
          <Trans id="general.cancel">Cancel</Trans>
        </Button>
      </Column>
      <Column>
        <Button disabled={!(isValid && dirty)} onClick={submitForm}>
          {mode === 'create' ? (
            <Trans id="general.create">Create</Trans>
          ) : (
            <Trans id="general.save">Save</Trans>
          )}
        </Button>
      </Column>
    </Row>
  );
};

export default TripDrivingLogModalActions;
