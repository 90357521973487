import { FC } from 'react';
import { Row, Paragraph, Svg, DropdownItem } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';
import { useLazyRequestInvoiceDownloadUrlQuery } from '@/core/services/Invoice.service';

export interface DownloadInvoiceMenuOptionT {
  invoiceId: number;
}

const DownloadInvoiceMenuOption: FC<DownloadInvoiceMenuOptionT> = ({ invoiceId }) => {
  const [getInvoiceDownloadUrl, { isFetching: isDownloadingInvoice }] =
    useLazyRequestInvoiceDownloadUrlQuery();

  return (
    <DropdownItem
      onClick={() => {
        getInvoiceDownloadUrl({ invoiceId })
          .unwrap()
          .then((response) => window.open(response, '_blank'));
      }}
      disabled={isDownloadingInvoice}
    >
      <Row marginBottom={0} alignItems="center">
        <Svg icon="downloadFolder" iconColor="var(--gs-900)" width={20} height={20} />
        <Paragraph size={3} marginBottom={0}>
          <Trans id="myTrip.dropdown.downloadInvoice">Download invoice</Trans>
        </Paragraph>
      </Row>
    </DropdownItem>
  );
};

export default DownloadInvoiceMenuOption;
