import { createSlice } from '@reduxjs/toolkit';
import { AuthStateT } from '../types/Auth.types';
import { AuthService } from '@/core/features/auth/services/Auth.service';

const initialState: AuthStateT = {
  authInfo: undefined,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuth: (state) => {
      state.authInfo = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      AuthService.endpoints.requestUserLogin.matchFulfilled,
      (state, { payload }) => {
        state.authInfo = payload;
      },
    );
    builder.addMatcher(
      AuthService.endpoints.requestRefreshToken.matchFulfilled,
      (state, { payload }) => {
        state.authInfo = payload;
      },
    );
    builder.addMatcher(AuthService.endpoints.requestRefreshToken.matchRejected, (state) => {
      state.authInfo = undefined;
    });
    builder.addMatcher(AuthService.endpoints.requestSignOut.matchFulfilled, (state) => {
      state.authInfo = undefined;
    });
    builder.addMatcher(
      AuthService.endpoints.requestPhoneLogin.matchFulfilled,
      (state, { payload }) => {
        state.authInfo = payload;
      },
    );
  },
});

export const { reducer, actions } = authSlice;
export const { clearAuth } = actions;
