import { createApi } from '@reduxjs/toolkit/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import {
  AvailableBookingStationArgs,
  AvailableBookingStations,
  BookingAvailability,
  BookingAvailabilityArgs,
  BookingCreationArgs,
  Booking,
  BookingExtended,
  BookingArgs,
  BookingUpdateArgs,
  BookingExtendStatus,
} from '@/features/booking/types';
import { addDays } from 'date-fns';
import { BookingCacheTags } from '@/features/booking/enums';
import { stringify } from 'qs';
import { formatResponse } from '@/core/api/formatAPIResponse';
import { TransformedResponse } from '@/core/types';
import { NotificationStore } from '@gourban/ui-components';
import { t } from '@lingui/macro';

export const BookingService = createApi({
  reducerPath: 'api-bookings',
  baseQuery: RestClientWrapper,
  tagTypes: [
    BookingCacheTags.BOOKING_AVAILABILITY,
    BookingCacheTags.MY_TRIPS,
    BookingCacheTags.AVAILABLE_STATIONS,
  ],
  endpoints: (builder) => ({
    requestBookingAvailability: builder.query<BookingAvailability[], BookingAvailabilityArgs>({
      query: ({ branchId, maxDateRange = 365, vehicleAttributes, bookingId }) => {
        const currentDate = new Date();

        return {
          url: `/front/bookings/availability`,
          requestParams: {
            paramsSerializer: {
              serialize: (param) => stringify(param, { encode: true, arrayFormat: 'comma' }),
            },
            params: {
              branchId,
              includeWithPricingOnly: true,
              startDate: currentDate.toISOString(),
              endDate: addDays(currentDate, maxDateRange).toISOString(),
              vehicleAttributes,
              excludeBookingId: bookingId,
            },
          },
        };
      },
      providesTags: [BookingCacheTags.BOOKING_AVAILABILITY],
    }),

    requestMyTrips: builder.query<TransformedResponse<Booking[]>, BookingArgs>({
      query: (params) => ({
        url: '/front/bookings',
        requestParams: {
          params: {
            ...params,
            size: 10,
          },
        },
      }),
      transformResponse: formatResponse,
      providesTags: [BookingCacheTags.MY_TRIPS],
    }),

    requestBookingById: builder.query<BookingExtended, { id: number; userGroupId?: number }>({
      query: ({ id, userGroupId }) => ({
        url: `/front/bookings/${id}`,
        requestParams: {
          params: {
            userGroupId,
          },
        },
      }),
      providesTags: (data) => [{ type: BookingCacheTags.MY_TRIPS, id: data?.id }],
    }),

    requestBookingCreate: builder.mutation<BookingExtended, BookingCreationArgs>({
      query: (data) => ({
        url: '/front/bookings',
        requestParams: { method: 'post', data },
      }),
      invalidatesTags: [BookingCacheTags.BOOKING_AVAILABILITY, BookingCacheTags.MY_TRIPS],
    }),

    requestBookingUpdate: builder.mutation<BookingExtended, BookingUpdateArgs>({
      query: (data) => ({
        url: `/front/bookings/${data.id}`,
        requestParams: { method: 'patch', data },
      }),
      invalidatesTags: [BookingCacheTags.BOOKING_AVAILABILITY, BookingCacheTags.MY_TRIPS],
    }),

    requestBookingCancel: builder.mutation<void, number>({
      query: (bookingId) => ({
        url: `/front/bookings/${bookingId}`,
        requestParams: { method: 'DELETE' },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'booking.notification.cancelSuccess',
                message: 'Booking successfully canceled.',
              }),
            });
          },
        },
      }),
      invalidatesTags: [BookingCacheTags.BOOKING_AVAILABILITY, BookingCacheTags.MY_TRIPS],
    }),

    requestAvailableStations: builder.query<
      AvailableBookingStations[],
      Partial<AvailableBookingStationArgs>
    >({
      query: (args) => {
        return {
          url: `/front/bookings/available-stations`,
          requestParams: {
            paramsSerializer: {
              serialize: (param) => stringify(param, { encode: true, arrayFormat: 'comma' }),
            },
            params: {
              ...args,
              size: 500,
            },
          },
        };
      },
      providesTags: [BookingCacheTags.AVAILABLE_STATIONS],
    }),

    requestCanExtendBooking: builder.query<BookingExtendStatus, number>({
      query: (id) => {
        return {
          url: `/front/bookings/${id}/extendable`,
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useRequestBookingAvailabilityQuery,
  useRequestMyTripsQuery,
  useRequestCanExtendBookingQuery,
  useRequestBookingByIdQuery,
  useLazyRequestBookingByIdQuery,
  useRequestBookingCreateMutation,
  useRequestBookingUpdateMutation,
  useRequestBookingCancelMutation,
  useLazyRequestAvailableStationsQuery,
  useRequestAvailableStationsQuery,
} = BookingService;
