import { AvailableLocales } from '@/core/enums';

export interface AvailableLocale {
  label: string;
  value: AvailableLocales;
}

export const useAvailableLocales = (): AvailableLocale[] => {
  const availableLocales = Object.values(AvailableLocales);
  const languageNames = new Intl.DisplayNames(availableLocales, { type: 'language' });

  return availableLocales.map((locale) => ({
    label: languageNames.of(locale)!,
    value: locale,
  }));
};
