import { isEmpty, mapValues, pick } from 'lodash';

/**
 * Some of the backend endpoints require empty strings to be converted to null while other dont,
 * This helper functions converts empty strings to null for defined fields
 */
export const nullEmptyStrings = <T>(values: T, keys: (keyof T)[]) => {
  return {
    ...values,
    ...mapValues(pick(values, keys), (val) => (isEmpty(val) ? null : val)),
  };
};
