import { AvailableLocales } from '@/core/enums';
import { enGB, deAT } from 'date-fns/locale';
import type { Locale } from 'date-fns';
import { useLingui } from '@lingui/react';

export const useDateLocale = (): Locale => {
  const { i18n } = useLingui();

  switch (i18n.locale) {
    case AvailableLocales.GERMAN:
      return deAT;
    case AvailableLocales.ENGLISH:
    default:
      return enGB;
  }
};
