import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useRequestBusinessAccountQuery } from '@/features/businessAccounts/services/BusinessAccount.service';
import { Loader } from '@gourban/ui-components';
import { businessAccountCreateRoot } from '@/core/pages/BusinessAccounts/routes';
import Page404 from '@/core/pages/Page404';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { SettingsKeys } from '@/core/enums';
import { TenantAccountSettings } from '@/features/geomap/types';

const CreateBusinessAccountCheck = () => {
  const { data: businessAccountData, isLoading: isLoadingBusinessAccounts } =
    useRequestBusinessAccountQuery();
  const tenantAccountSettings = useTypedSelector((state) =>
    getSetting<TenantAccountSettings>(state, SettingsKeys.TENANT_ACCOUNTS),
  );

  const { pathname, state } = useLocation();

  const isBusinessAccountOwner = !!businessAccountData;

  if (isLoadingBusinessAccounts) {
    return <Loader cover fixed />;
  }

  if (!tenantAccountSettings?.businessAccounts?.active) {
    return <Page404 />;
  }

  if (isBusinessAccountOwner && !state?.isFromStepSwitching) {
    return <Navigate to="/business-account" />;
  }

  if (!state?.isFromStepSwitching && pathname !== businessAccountCreateRoot.path) {
    return <Navigate to="/business-account/create" />;
  }

  return <Outlet />;
};

export default CreateBusinessAccountCheck;
