import { Route } from '@/core/types';
import authRoutes from '@/core/pages/Auth/routerConfig';
import accountRoutes from '@/core/pages/Account/routerConfig';
import bookingRoutes from '@/core/pages/Booking/routerConfig';
import myTripsRoutes from '@/core/pages/MyTrips/routerConfig';
import businessAccountRoutes from '@/core/pages/BusinessAccounts/routerConfig';
import AppSetupInit from '@/core/components/AppSetupInit';
import RedirectIfAuth from '@/features/auth/components/RedirectIfAuth';
import RedirectToBusinessAccount from '@/features/businessAccounts/components/RedirectToBusinessAccount';

export const guardedRoutes = {
  path: '/',
  label: 'App routes',
  guarded: true,
  element: <AppSetupInit />,
  routes: [...accountRoutes, ...bookingRoutes, ...myTripsRoutes, ...businessAccountRoutes],
};

// These routes are used when webapp feature is turned off, but business account feature still needs to be accessible
export const businessAccountOnlyRoutes = {
  path: '/',
  label: 'Business account only mode routes',
  guarded: true,
  element: <RedirectToBusinessAccount />,
  routes: businessAccountRoutes,
};

export const authenticationRoutes = {
  path: '/auth',
  label: 'Auth routes',
  guarded: false,
  element: <RedirectIfAuth />,
  routes: [...authRoutes],
};

const routes: Route[] = [authenticationRoutes, guardedRoutes];

export const businessAccountOnlyModeRoutes = [authenticationRoutes, businessAccountOnlyRoutes];
export default routes;
