import React from 'react';
import AccountLayout from '@/features/account/components/Edit/Layout';
import { Button } from '@gourban/ui-components';
import { Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { booking } from '@/core/pages/Booking/routes';
import EditFormSection from '@/core/components/EditFormSection';
import AccountVerification from '@/features/account/components/Verification/AccountVerification';

const AccountEditVerification = () => {
  const navigate = useNavigate();

  return (
    <AccountLayout
      actions={
        <Button variation="secondary" onClick={() => navigate(booking.path)}>
          <Trans id="general.close">Close</Trans>
        </Button>
      }
    >
      <EditFormSection
        title={<Trans id="accountEdit.verification.title">Verification</Trans>}
        description={
          <Trans id="accountEdit.verification.description">
            Onfido Document Verification uses Atlas AI to assess the legitimacy of each document in
            seconds.
          </Trans>
        }
        content={<AccountVerification verificationMessageSize="small" />}
      />
    </AccountLayout>
  );
};

export default AccountEditVerification;
