import { keys, set } from 'lodash';

/**
 * Used to unflatten merged object string key
 * EG:
 * {
 *     'user.firstName': 'something'
 * }
 * would be converted to
 * {
 *     user: {
 *         firstName: 'something'
 *     }
 * }
 */
export const unflattenObject = <T extends Record<string, any>>(
  flattedObject: Record<string, any>,
): T => {
  const result = {} as T;

  keys(flattedObject).forEach((key) => {
    // When flattening object, array values are marked with [] in the object key, but when unflatten we need to remove those, since its not considered a value key
    set(result, key.replace('[]', ''), flattedObject[key]);
  });

  return result;
};
