import React, { FC } from 'react';
import { VehicleAttributes } from '@/features/vehicles/types';
import { Checkbox, Paragraph, RadioGroup, Row, Switch } from '@gourban/ui-components';
import { toUpper } from 'lodash';
import styles from '../../assets/scss/components/AttributeFilterGroup.module.scss';
import { GROUP_ITEM_SUFFIX } from '@/features/booking/components/Filters/FiltersForm';

interface AttributeFilterGroupT {
  vehicleAttributeConfig: VehicleAttributes;
}

/**
 * 'name' properties on all of these must have prefixes because the properties are numbers and formik converts all values to arrays
 * rather then object with dot notation
 */
const AttributeFilterGroup: FC<AttributeFilterGroupT> = ({ vehicleAttributeConfig }) => {
  return (
    <div className={styles['attribute-filter-group']}>
      {vehicleAttributeConfig.selectionType !== 'SWITCH' && (
        <Paragraph size={3} color="var(--gs-400)">
          {toUpper(vehicleAttributeConfig.name)}
        </Paragraph>
      )}

      {vehicleAttributeConfig.selectionType === 'CHECKBOX' &&
        vehicleAttributeConfig.attributes.map((checkboxAttr) => {
          return (
            <Row key={checkboxAttr.id} marginBottom={8}>
              <Checkbox
                name={`vehicleAttributes.${GROUP_ITEM_SUFFIX}${checkboxAttr.id}`}
                fieldAttr={{ id: `vehicleAttributes.${checkboxAttr.id}` }}
                fieldProps={{ label: checkboxAttr.name }}
              />
            </Row>
          );
        })}

      {vehicleAttributeConfig.selectionType === 'RADIO' && (
        <RadioGroup
          name="vehicleAttributes"
          direction="column"
          radioOptions={vehicleAttributeConfig.attributes.map(({ name, id }) => ({
            label: name,
            value: `${GROUP_ITEM_SUFFIX}${id}`,
          }))}
        />
      )}

      {vehicleAttributeConfig.selectionType === 'SWITCH' &&
        vehicleAttributeConfig.attributes.map(({ name, id }) => (
          <Switch
            key={id}
            fieldAttr={{
              id: name,
            }}
            fieldProps={{ label: name, style: 'stretch' }}
            name={`vehicleAttributes[${GROUP_ITEM_SUFFIX}${id}]`}
          />
        ))}
    </div>
  );
};

export default AttributeFilterGroup;
