import { Addition, MergedAvailableCategoriesData } from '@/features/vehicles/types';
import { RootState } from '@/core/redux';

export const getVehicleCategoryData = (
  state: RootState,
  categoryId?: number,
): MergedAvailableCategoriesData | null =>
  state.vehicles.vehiclesCategories?.find((category) => category.id === categoryId) ?? null;

export const getVehicleCategoryAdditions = (state: RootState): Addition[] | null =>
  state.vehicles.additions ?? null;
