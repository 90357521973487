/**
 * Local storage keys
 */
export enum LocalStorageKeys {
  /**
   * Contains auth related things
   */
  AUTH = 'persist:auth',
  CHUNK_RETRY = 'chunkRetrying',
  /**
   * Persists user preferred lang
   */
  PREFERRED_LANG = 'preferredLang',
  /**
   * Persists business account join application code.
   * Used if user is not logged in or registered, so we can keep the information until he is done
   */
  JOIN_APPLICATION_CODE = 'joinApplicationCode',
}

export enum DateFormats {
  FULL_DATE_TIME = 'LLL dd yyyy / HH:mm',
  FULL_DATE_TIME_NO_YEAR = 'LLL dd / HH:mm',
  SHORT_DATE = 'PP',
}

/**
 * Available TargetAreas when updating/creating settings management
 */
export enum TargetArea {
  CORE = 'CORE',
  APP = 'APP',
}

export enum AvailableLocales {
  ENGLISH = 'en',
  GERMAN = 'de',
}

export enum SettingsKeys {
  BOOKING = 'booking',
  USER_REQUIRED_FIELDS = 'user.requiredFields',
  SUPPORT_METHODS = 'support.methods',
  NEWSLETTER = 'user.newsletter.options',
  WHITELABEL_APP = 'user-apps.white-labeling',
  MAP = 'map',
  TENANT_ACCOUNTS = 'user.accounts',
  LOGIN = 'logins',
}

export enum WhiteLabelingAPIKeys {
  WHITE_LABEL = 'whitelabel',
}
