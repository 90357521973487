import { Polygon } from 'geojson';
import { LngLat } from '@/core/features/geomap/types';

/**
 * Mapbox only returns bottom left and top right points, so we have to create polygon for it to be able to use it
 */
export const createPolygonFromPoints = (pointOne: LngLat, pointTwo: LngLat): Polygon => {
  return {
    type: 'Polygon',
    coordinates: [
      [
        [pointOne.lat, pointOne.lng],
        [pointTwo.lat, pointOne.lng],
        [pointTwo.lat, pointTwo.lng],
        [pointOne.lat, pointTwo.lng],
        [pointOne.lat, pointOne.lng],
      ],
    ],
  };
};
