import { RouteStaticConfig } from '@/core/types';
import { msg } from '@lingui/macro';

export const account: RouteStaticConfig = {
  path: '/account',
  label: 'Account',
  guarded: true,
};

export const basicInfo: RouteStaticConfig = {
  path: '/account/basic-info',
  label: msg({ id: 'account.tabs.basicInfo', message: 'Basic info' }),
  guarded: true,
  additionalPaths: ['/account'],
};

export const payment: RouteStaticConfig = {
  path: '/account/payment',
  label: msg({ id: 'account.tabs.payment', message: 'Payment' }),
  guarded: true,
};

export const verification: RouteStaticConfig = {
  guarded: true,
  path: '/account/verification',
  label: msg({ id: 'account.tabs.verification', message: 'Verification' }),
};

export const accountEditBasicInfo: RouteStaticConfig = {
  guarded: true,
  path: '/account/edit/basic-info',
  additionalPaths: ['/account/edit'],
  label: 'AccountEditBasicInfo',
};

export const accountEditPaymentMethods: RouteStaticConfig = {
  guarded: true,
  path: '/account/edit/payment',
  label: 'AccountEditPaymentMethods',
};

export const accountEditBusinessAccount: RouteStaticConfig = {
  guarded: true,
  path: '/account/edit/business',
  label: 'AccountEditBusiness',
};

export const accountEditNewsletter: RouteStaticConfig = {
  guarded: true,
  path: '/account/edit/newsletter',
  label: 'AccountEditNewsletter',
};

export const accountEditVerification: RouteStaticConfig = {
  guarded: true,
  path: '/account/edit/verification',
  label: 'AccountEditVerification',
};

export const accountChildRoutes = [
  basicInfo,
  payment,
  verification,
  accountEditBasicInfo,
  accountEditPaymentMethods,
  accountEditBusinessAccount,
  accountEditNewsletter,
  accountEditVerification,
];
