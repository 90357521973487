import { FC } from 'react';
import AuthGuard from '@/features/auth/components/AuthGuard';
import { Route } from '@/core/types';
import { capitalize, lowerCase, upperFirst } from 'lodash';
import { Helmet } from 'react-helmet';
import { useTypedSelector } from '@/core/redux/hooks';
import { getSetting } from '@/core/redux/selectors/settingsManagement.selectors';
import { SettingsKeys } from '@/core/enums';
import { AppWhiteLabel } from '@/features/booking/types';

const ComposeScreen: FC<{ route: Route }> = ({ route }) => {
  const formattedPathname = capitalize(
    upperFirst(lowerCase(route.path?.split('/')?.reverse()?.[0] ?? '')),
  );
  const whiteLabelSettings = useTypedSelector((state) =>
    getSetting<AppWhiteLabel>(state, SettingsKeys.WHITELABEL_APP),
  );

  const title = whiteLabelSettings?.web?.title ?? 'Wunder mobility';
  const shouldTitleIncludePath = whiteLabelSettings?.web?.shouldTitleIncludePath ?? false;
  const metaDescription = whiteLabelSettings?.web?.description ?? 'Wunder mobility user web app.';
  const favicon = whiteLabelSettings?.web?.favicon?.icon ?? '/images/favicon-32x32.png';

  const pathName = shouldTitleIncludePath ? `- ${formattedPathname}` : '';

  const pageTitle = (
    <Helmet>
      <title>
        {title} {pathName}
      </title>
      <meta name="description" content={metaDescription} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon} />
      {whiteLabelSettings?.web?.robotsMetaTags?.map((robotMeta) => (
        <meta key={`robot-${robotMeta}`} name="robots" content={robotMeta} />
      ))}
    </Helmet>
  );

  // If route is not guarded, just render the element
  if (!route.guarded)
    return (
      <>
        {pageTitle}
        {route.element}
      </>
    );

  // If route only has guarded set to true, it means user only needs to be logged in to access it
  return (
    <>
      {pageTitle}
      <AuthGuard>{route.element}</AuthGuard>
    </>
  );
};

export default ComposeScreen;
