import React, { FC, ReactNode } from 'react';
import { BookingStep } from '@/features/booking/types';
import Breadcrumbs from '@/features/booking/components/Breadcrumbs';
import styles from '../../assets/scss/components/OverviewModalHeader.module.scss';

interface OverviewModalHeaderT {
  shouldHide: boolean;
  customHeading?: ReactNode;
  steps: BookingStep[];
}

const OverviewModalHeader: FC<OverviewModalHeaderT> = ({ steps, shouldHide, customHeading }) => {
  if (shouldHide) {
    // Need to have some element so we still get the header because of close modal icon
    return <span />;
  }

  if (customHeading) {
    return <span className={styles.header}>{customHeading}</span>;
  }

  return <Breadcrumbs steps={steps} />;
};

export default OverviewModalHeader;
