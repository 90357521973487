import { createSlice } from '@reduxjs/toolkit';
import { SettingsManagementService } from '@/core/services/SettingsManagement.service';

const initialState: Record<string, any> = {};

const settingsManagementSlice = createSlice({
  name: 'settingsManagement',
  initialState,
  reducers: {
    clearSettings: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      SettingsManagementService.endpoints.requestTenantAccountSettings.matchFulfilled,
      (state, { payload }) => {
        state[payload.key] = payload.value;
      },
    );
    builder.addMatcher(
      SettingsManagementService.endpoints.requestBookingSetting.matchFulfilled,
      (state, { payload }) => {
        state[payload.key] = payload.value;
      },
    );
    builder.addMatcher(
      SettingsManagementService.endpoints.requestNewsletters.matchFulfilled,
      (state, { payload }) => {
        state[payload.key] = payload.value;
      },
    );
    builder.addMatcher(
      SettingsManagementService.endpoints.requestMapSettings.matchFulfilled,
      (state, { payload }) => {
        state[payload.key] = payload.value;
      },
    );
    builder.addMatcher(
      SettingsManagementService.endpoints.requestSupportMethods.matchFulfilled,
      (state, { payload }) => {
        state[payload.key] = payload.value;
      },
    );
    builder.addMatcher(
      SettingsManagementService.endpoints.requestWhiteLabelLogo.matchFulfilled,
      (state, { payload }) => {
        state[payload.key] = payload.value;
      },
    );
    builder.addMatcher(
      SettingsManagementService.endpoints.requestPublicSettingsBulk.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          payload.forEach((setting) => {
            state[setting.key] = setting.value;
          });
        }
      },
    );
    builder.addMatcher(
      SettingsManagementService.endpoints.requestGuardedSettingsBulk.matchFulfilled,
      (state, { payload }) => {
        if (payload) {
          payload.forEach((setting) => {
            state[setting.key] = setting.value;
          });
        }
      },
    );
  },
});

export const { reducer, actions } = settingsManagementSlice;

export const { clearSettings } = actions;
