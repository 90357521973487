import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import styles from '@/core/assets/scss/components/Layouts/BookingLayout.module.scss';
import cn from 'classnames';

interface LayoutT {
  header: ReactNode;
  children?: ReactNode;
  className?: string;
}

const BookingLayout: FC<LayoutT> = ({ header, children = <Outlet />, className }) => {
  return (
    <div className={cn(styles['booking-layout'], className)}>
      <header className={styles['booking-layout__header']}>{header}</header>
      <main className={styles['booking-layout__main']}>{children}</main>
    </div>
  );
};

export default BookingLayout;
