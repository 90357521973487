import { FC, useEffect, useMemo } from 'react';
import { AvailableBookingStationArgs, BookingFiltersForm } from '@/features/booking/types';
import FiltersForm, { GROUP_ITEM_SUFFIX } from '@/features/booking/components/Filters/FiltersForm';
import { getNumberOfActiveFilters } from '@/features/booking/utils/getNumberOfActiveFilters';
import { format } from 'date-fns';
import { formatInitialTime } from '@/features/booking/utils/formatInitialTime';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import { plural, t } from '@lingui/macro';
import { getQueryParams } from '@/features/booking/utils/getFilterQueryParams';
import { useTypedDispatch } from '@/core/redux/hooks';
import { resetActiveFilters } from '@/features/account/redux/account.reducer';
import { DateFormats } from '@/core/enums';

interface FiltersT {
  onSubmit: (args: Partial<AvailableBookingStationArgs>) => Promise<unknown> | null;
}

/**
 * Filters are being portaled to the header from here because of its needs for map context to be able to navigate around the map
 * as well as filtering different geoJSON layers
 */
const Filters: FC<FiltersT> = ({ onSubmit }) => {
  const dispatch = useTypedDispatch();
  const { data: bookingSettings } = useRequestBookingSettingQuery();

  // Wrapped in memo so it doesnt trigger reinitialization of initialValues and initial useEffect states
  const initialParams = useMemo(() => {
    return getQueryParams(window.location.search);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = useMemo<BookingFiltersForm>(() => {
    // Params we got from the URL need to be parsed for initialValues so we can recognize them
    const vehicleAttributes =
      initialParams?.vehicleAttributes &&
      Object.entries(initialParams.vehicleAttributes).reduce(
        (initialAttributes, [, attributeIDs]) => {
          attributeIDs.forEach((attributeID) => {
            initialAttributes[`${GROUP_ITEM_SUFFIX}${attributeID}`] = true;
          });

          return initialAttributes;
        },
        {} as Record<string, boolean>,
      );

    const activeFilterNumber = getNumberOfActiveFilters(vehicleAttributes);
    const hasDateFilters = initialParams?.startTime && initialParams?.endTime;
    const datePlaceholder =
      hasDateFilters &&
      `${format(initialParams.startTime!, DateFormats.FULL_DATE_TIME_NO_YEAR)} - ${format(
        initialParams.endTime!,
        DateFormats.FULL_DATE_TIME_NO_YEAR,
      )}`;
    const bookingDate = hasDateFilters && {
      from: initialParams.startTime,
      to: initialParams.endTime,
    };
    const bookingTime = hasDateFilters
      ? {
          from: format(new Date(initialParams.startTime!), 'HH:mm'),
          to: format(new Date(initialParams.endTime!), 'HH:mm'),
        }
      : {
          from: formatInitialTime(bookingSettings?.value?.properties?.preselectedStartingTime, 60),
          to: formatInitialTime(bookingSettings?.value?.properties?.preselectedEndingTime, 120),
        };

    return {
      location: initialParams?.location ?? '',
      date: datePlaceholder,
      bookingDate,
      bookingTime,
      boundingBoxCoordinates: initialParams?.boundingBoxCoordinates,
      locationCoordinates: initialParams?.locationCoordinates,
      vehicleAttributes,
      vehicleFilters: activeFilterNumber
        ? t({
            id: 'bookings.filters.activeFilterNumber',
            message: plural(activeFilterNumber, {
              one: '# Filter',
              other: '# Filters',
            }),
          })
        : undefined,
    };
  }, [bookingSettings, initialParams]);

  // We need to reset filters when user leaves the screen containing them.
  useEffect(() => {
    return () => {
      dispatch(resetActiveFilters());
    };
  }, [dispatch]);

  return (
    <FiltersForm
      bookingSettings={bookingSettings}
      initialParams={initialParams}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
};

export default Filters;
