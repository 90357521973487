import { useRequestUserRequiredFieldsQuery } from '@/core/services/SettingsManagement.service';
import {
  UserRequiredFieldsProfile,
  FormConfig,
  UserRequiredFieldsSettingsValue,
} from '@/features/auth/types/User.types';
import { object, string, StringSchema, ObjectSchema } from 'yup';
import { CommonValidationTranslations } from '@/core/utils/commonValidationTranslations';
import { t } from '@lingui/macro';

const resolveProfile = (
  profile: UserRequiredFieldsProfile | undefined,
): UserRequiredFieldsProfile => {
  if (profile) {
    return profile;
  }

  const defaultFormConfig = {
    visible: false,
    required: false,
    editable: false,
  };

  return {
    email: defaultFormConfig,
    firstName: defaultFormConfig,
    lastName: defaultFormConfig,
    birthdate: defaultFormConfig,
    phone: defaultFormConfig,
    postcode: defaultFormConfig,
    street: defaultFormConfig,
    city: defaultFormConfig,
    country: defaultFormConfig,
  };
};

const generateStringSchema = (config: FormConfig): StringSchema => {
  const { required } = config;

  let schema = string().trim();

  if (required) {
    schema = schema.required(() => CommonValidationTranslations.requiredField());
  }

  return schema;
};

export const useProfileSchema = (): {
  profile: UserRequiredFieldsProfile;
  validationSchema: ObjectSchema<any>;
  isFetching: boolean;
} => {
  const { data, isFetching } = useRequestUserRequiredFieldsQuery();

  const configs: (keyof UserRequiredFieldsProfile)[] = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'birthdate',
    'street',
    'city',
    'postcode',
    'country',
  ];

  const shape = {} as Record<keyof UserRequiredFieldsSettingsValue['profile'], StringSchema>;

  const profile = resolveProfile(data?.value?.profile);

  configs.forEach((config) => {
    const { visible } = profile[config];
    if (visible) {
      shape[config] = generateStringSchema(profile[config]);
    }
  });

  if (shape.email) {
    shape.email = shape.email.email(() =>
      CommonValidationTranslations.invalidField(
        t({
          id: 'auth.forms.email',
          message: 'E-mail',
        }),
      ),
    );
  }

  const validationSchema = object().shape(shape);

  return { profile, validationSchema, isFetching };
};
