import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
  useRef,
} from 'react';

export interface ProvidedModal {
  opened?: boolean;
  onCancel?: () => void;
}

interface ModalInfo {
  id: number;
  props: any;
}

interface ModalContextProps {
  showModal: <T extends ProvidedModal>(component: FC<T>, props?: T) => number;
  hideModal: () => void;
  hideModalById: (id: number) => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [modals, setModals] = useState<ModalInfo[]>([]);
  const nextIdRef = React.useRef(1);
  const components = useRef<Record<string, FC<any>>>({});

  const showModal = useCallback(<T extends ProvidedModal>(component: FC<T>, props?: T) => {
    const id = nextIdRef.current++;
    components.current[id] = component;
    setModals((prevModals) => [...prevModals, { id, props }]);
    return id;
  }, []);

  const hideModalById = useCallback((id?: number) => {
    // if id is provided, remove the modal of that id
    setModals((prevModals) => {
      if (id) {
        delete components.current[id];
        return prevModals.filter((modal) => modal.id !== id);
      }

      delete components.current[prevModals[prevModals.length - 1].id];

      // No id was provided; remove the last modal
      return prevModals.slice(0, -1);
    });
  }, []);

  const hideModal = useCallback(() => {
    hideModalById();
  }, [hideModalById]);

  const value = useMemo(
    () => ({
      showModal,
      hideModal,
      hideModalById,
    }),
    [showModal, hideModal, hideModalById],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modals.map(({ id, props }) => {
        const ModalComponent = components.current[id];

        return <ModalComponent key={id} {...props} opened onCancel={() => hideModalById(id)} />;
      })}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
