const generateSHA256Hash = async (input: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
};
const test = '1Ab23Cd';
const generateTimestamp = () => Math.floor(new Date().getTime() / 1000);

export const generateSignatureHash = async (data: string) => {
  const timestamp = String(generateTimestamp());
  const signature = await generateSHA256Hash(`${test}${timestamp}${data}${test}`);
  return { signature, timestamp };
};
