import axios from 'axios';
import { applyInterceptors } from '@/core/api/interceptors';

export const getTenant = () =>
  import.meta.env.VITE_TEST_TENANT || window.location.hostname.split('.').shift();

export const getTenantServiceURL = () =>
  `${import.meta.env.VITE_TENANT_SERVICE_URL}/user-api/tenants`;

const restClient = axios.create({
  /**
   * From axios 1.5.0, if env is node it will fallback to http and tests will fail because MSW expect xhr
   * https://github.com/axios/axios/commit/9a414bb6c81796a95c6c7fe668637825458e8b6d
   */
  adapter: 'xhr',
});

applyInterceptors(restClient);

export default restClient;
