import { persistor } from '@/core/redux';
import { LocalStorageKeys } from '@/core/enums';
import { accountChildRoutes } from '@/core/pages/Account/routes';
import { TenantFeatures } from '@/features/auth/enums';
import { useLocation, useNavigate } from 'react-router-dom';
import useFeatureCheck from '@/features/auth/hooks/useFeatureCheck';

const useAfterAuthPathResolver = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasFeature = useFeatureCheck();

  return async () => {
    await persistor.flush();
    const businessAccountJoinRequestCode = localStorage.getItem(
      LocalStorageKeys.JOIN_APPLICATION_CODE,
    );
    const joinRequestData =
      businessAccountJoinRequestCode && JSON.parse(businessAccountJoinRequestCode);

    const navigateToJoinApplication = () =>
      navigate(`/public/join-application/${joinRequestData.code}`);
    const navigateToFromState = () =>
      navigate(location.state.from.pathname, { state: { fromLogin: true } });
    const navigateToDefault = () =>
      navigate(accountChildRoutes[0].path, { state: { fromLogin: true } });
    const navigateToBusinessAccountDashboard = () => navigate('/business-account');

    // Redirect to business account dashboard if the user doesn't have the end-user web app feature
    // Business account management is available for everyone, webapp is hidden under a feature flag
    if (!hasFeature(TenantFeatures.ENDUSER_WEB_APP)) {
      return navigateToBusinessAccountDashboard();
    }

    // Redirect to join application page if the user has a join request
    if (joinRequestData && new Date() < new Date(joinRequestData.expTime)) {
      return navigateToJoinApplication();
    }

    // Redirect to the previous page if the user was redirected to the login page
    if (location.state?.from) {
      return navigateToFromState();
    }

    return navigateToDefault();
  };
};

export default useAfterAuthPathResolver;
