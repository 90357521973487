import { FC, ReactNode } from 'react';

interface ConditionalUrlT {
  urlText: ReactNode;
  url?: string;
}
const ConditionalUrl: FC<ConditionalUrlT> = ({ url, urlText }) =>
  url ? (
    <a href={url} target="_blank">
      {urlText}
    </a>
  ) : (
    <>{urlText}</>
  );

export default ConditionalUrl;
