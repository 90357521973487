import { useState, useEffect, useMemo, useCallback } from 'react';
import { useWindowResize } from '@/core/hooks/useWindowResize';
import { getCssVariableValue } from '@/core/utils/getCssVariable';
import { Breakpoints } from '@/core/types';

/**
 * This hook is used to return width of the screen when resize of the window occurs
 */
export const useResponsive = () => {
  const { width } = useWindowResize();

  const defaultDeviceStates = useMemo<Breakpoints>(
    () => ({
      isMobile: false,
      isMobileLarge: false,
      isTablet: false,
      isLaptop: false,
      isDesktop: false,
      isLargeDesktop: false,
    }),
    [],
  );

  const [devices, setDevices] = useState<Breakpoints>(defaultDeviceStates);

  const breakpoints = useMemo(() => {
    return {
      mobile: getCssVariableValue('--breakpoint-mobile'),
      mobileLarge: getCssVariableValue('--breakpoint-mobileLarge'),
      tablet: getCssVariableValue('--breakpoint-tablet'),
      laptop: getCssVariableValue('--breakpoint-laptop'),
      desktop: getCssVariableValue('--breakpoint-desktop'),
      desktopLarge: getCssVariableValue('--breakpoint-desktopLarge'),
    };
  }, []);

  const setActiveDevice = useCallback(
    (activeDevice: keyof Breakpoints) => {
      setDevices(() => {
        return {
          ...defaultDeviceStates,
          [activeDevice]: true,
        };
      });
    },
    [defaultDeviceStates, setDevices],
  );

  useEffect(() => {
    switch (true) {
      case width <= parseInt(breakpoints.mobileLarge, 10):
        setActiveDevice('isMobile');
        break;
      case width < parseInt(breakpoints.tablet, 10) && width >= parseInt(breakpoints.mobile, 10):
        setActiveDevice('isMobileLarge');
        break;
      case width < parseInt(breakpoints.laptop, 10) && width >= parseInt(breakpoints.mobile, 10):
        setActiveDevice('isTablet');
        break;
      case width < parseInt(breakpoints.desktop, 10) && width >= parseInt(breakpoints.laptop, 10):
        setActiveDevice('isLaptop');
        break;
      case width < parseInt(breakpoints.desktopLarge, 10) &&
        width >= parseInt(breakpoints.desktop, 10):
        setActiveDevice('isDesktop');
        break;
      case width >= parseInt(breakpoints.desktopLarge, 10):
        setActiveDevice('isLargeDesktop');
        break;
      default:
        break;
    }
  }, [width, breakpoints, setActiveDevice]);

  return { ...devices, screenWidth: width } as Breakpoints & { screenWidth: number };
};
