import React, { FC } from 'react';
import { AvailableCategoriesPricing, AvailableVehicleCategories } from '@/features/vehicles/types';
import { Trans } from '@lingui/macro';
import { Svg, Tooltip } from '@gourban/ui-components';
import CurrencyDisplay from '@/core/components/CurrencyDisplay';
import UnitDisplay from '@/core/components/UnitDisplay';
import { useLingui } from '@lingui/react';
import { getNormalizedUnit } from '@/core/utils/getNormalizedUnit';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';

export interface AvailableCategoriesPricingT {
  extendedPricing?: AvailableCategoriesPricing;
  currency: AvailableVehicleCategories['currency'];
  grossPrice: AvailableVehicleCategories['grossPrice'];
  netPrice?: AvailableVehicleCategories['netPrice'];
}

const VehicleCategoryPricingDisplay: FC<AvailableCategoriesPricingT> = ({
  extendedPricing,
  currency,
  grossPrice,
  netPrice,
}) => {
  const { i18n } = useLingui();
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { showGrossPrice } = bookingSettings?.value ?? {};

  if (!currency) {
    return null;
  }

  return (
    <span data-testid="VehicleCategoryPricingDisplay">
      <CurrencyDisplay
        value={showGrossPrice || !netPrice ? grossPrice : netPrice}
        currency={currency}
      />{' '}
      {extendedPricing?.distancePrice?.rate?.rate &&
        !extendedPricing?.distancePrice?.rate?.included && (
          <>
            {' '}
            +{' '}
            <CurrencyDisplay value={extendedPricing?.distancePrice.rate.rate} currency={currency} />
            <UnitDisplay unit={extendedPricing?.distancePrice.unit} unitDisplay="shortPer" />
          </>
        )}
      {extendedPricing?.distancePrice?.rate?.included && (
        <>
          <br />
          <Trans id="vehicles.vehicleCategoryPricingDisplay.includedDistance">
            Included distance{' '}
            {i18n.number(extendedPricing.distancePrice.rate.included, {
              unit: getNormalizedUnit(extendedPricing?.distancePrice.unit),
              style: 'unit',
              unitDisplay: 'short',
            })}
          </Trans>{' '}
          <Tooltip
            content={
              <>
                <Trans id="vehicles.vehicleCategoryPricingDisplay.afterIncludeDistanceRate">
                  After the included distance of{' '}
                  {i18n.number(extendedPricing.distancePrice.rate.included, {
                    unit: getNormalizedUnit(extendedPricing?.distancePrice.unit),
                    style: 'unit',
                    unitDisplay: 'short',
                  })}{' '}
                  ,
                  <br /> a rate of{' '}
                  <CurrencyDisplay
                    value={extendedPricing?.distancePrice.rate.rate}
                    currency={currency}
                  />
                  <UnitDisplay unit={extendedPricing?.distancePrice.unit} unitDisplay="shortPer" />{' '}
                  applies.
                </Trans>{' '}
              </>
            }
          >
            <Svg icon="infoFilled" width={20} height={15} iconColor="var(--gs-300)" />
          </Tooltip>
        </>
      )}
    </span>
  );
};

export default VehicleCategoryPricingDisplay;
