import { LocalStorageKeys } from '@/core/enums';
import { AuthenticationData } from '@/features/auth/types/Auth.types';

/**
 * Get authInfo from localStorage
 * @returns {Object} - Auth info object
 */
export const getLocalStorageAuthInfo = () => {
  const localStorageAuthInfo =
    localStorage.getItem(LocalStorageKeys.AUTH) &&
    JSON.parse(localStorage.getItem(LocalStorageKeys.AUTH) as string);

  if (localStorageAuthInfo?.authInfo) {
    return (
      localStorageAuthInfo?.authInfo &&
      (JSON.parse(localStorageAuthInfo?.authInfo) as AuthenticationData)
    );
  }

  return null;
};
