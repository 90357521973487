import { getMeData } from '@/features/account/redux/account.selectors';
import { UserData } from '@/features/auth/types/User.types';
import { useTypedSelector } from '@/core/redux/hooks';

export type VerificationStatus = 'uninitialized' | 'pending' | 'successful' | 'retry' | 'rejected';

export const getVerificationStatus = (user?: UserData): VerificationStatus => {
  switch (user?.licenseVerificationState) {
    case 'VERIFIED':
      return 'successful';
    case 'REJECTED':
      return 'rejected';
    case 'EXPIRED_LICENSE':
    case 'RETRY':
      return 'retry';
    case 'PROCESSING':
    case 'VERIFIED_BUT_NEEDS_CHECK':
    case 'REVIEW':
      return 'pending';
    case 'CREATED':
    case 'UNVERIFIED':
    default:
      return 'uninitialized';
  }
};

export const useVerificationStatus = (): VerificationStatus => {
  const user = useTypedSelector(getMeData);

  return getVerificationStatus(user);
};
