import React from 'react';
import {
  DatePickerInput,
  Text,
  Textarea,
  Row,
  Column,
  Number,
  Select,
} from '@gourban/ui-components';
import { t } from '@lingui/macro';
import { useOutletContext } from 'react-router-dom';
import { BookingExtended } from '@/features/booking/types';
import { i18n } from '@lingui/core';
import { format } from 'date-fns';
import { DateFormats } from '@/core/enums';

const TripDrivingLogFormFields = () => {
  const { rental, startTime, endTime } = useOutletContext<BookingExtended>();

  return (
    <Row gapSm="sm">
      <Column sm={12}>
        <DatePickerInput
          fieldAttr={{
            id: 'startDate',
            required: true,
            placeholder: t({
              id: 'myTrips.drivingLogFormFields.datePlaceholder',
              message: 'Add driving log date',
            }),
          }}
          fieldProps={{
            label: t({ id: 'myTrips.drivingLogFormFields.date', message: 'Date' }),
            helperText: t({
              id: 'myTrips.drivingLogFormFields.dateHelperText',
              message: `Rental occured on: ${format(
                new Date(startTime),
                DateFormats.SHORT_DATE,
              )} - ${format(new Date(endTime), DateFormats.SHORT_DATE)}`,
            }),
          }}
          name="startDate"
        />
      </Column>

      <Column sm={12}>
        <Text
          fieldAttr={{
            id: 'startLocation',
            required: true,
            placeholder: t({
              id: 'myTrips.drivingLogFormFields.fromPlaceholder',
              message: 'From location',
            }),
          }}
          fieldProps={{ label: t({ id: 'myTrips.drivingLogFormFields.from', message: 'From' }) }}
          name="startLocation"
        />
      </Column>

      <Column sm={12}>
        <Text
          fieldAttr={{
            id: 'endLocation',
            required: true,
            placeholder: t({
              id: 'myTrips.drivingLogFormFields.toPlaceholder',
              message: 'To location',
            }),
          }}
          fieldProps={{ label: t({ id: 'myTrips.drivingLogFormFields.to', message: 'To' }) }}
          name="endLocation"
        />
      </Column>

      <Column sm={12}>
        <Select
          fieldAttr={{
            id: 'tripType',
            placeholder: t({
              id: 'myTrips.drivingLogFormFields.tripTypePlaceholder',
              message: 'Choose trip type ',
            }),
          }}
          fieldProps={{
            clearable: true,
            label: t({ id: 'myTrips.drivingLogFormFields.tripType', message: 'Trip type' }),
            options: [
              {
                label: t({
                  id: 'myTrips.drivingLogFormFields.tripType.tripHome',
                  message: 'Trip home',
                }),
                value: 'TRIP_HOME',
              },
              {
                label: t({
                  id: 'myTrips.drivingLogFormFields.tripType.other',
                  message: 'Other',
                }),
                value: 'OTHER',
              },
            ],
          }}
          name="tripType"
        />
      </Column>

      <Column sm={12}>
        <Textarea
          fieldAttr={{
            id: 'reason',
            required: true,
            placeholder: t({
              id: 'myTrips.drivingLogFormFields.reasonPlaceholder',
              message: 'Reason for business trip',
            }),
          }}
          fieldProps={{
            label: t({ id: 'myTrips.drivingLogFormFields.reason', message: 'Reason' }),
          }}
          name="reason"
        />
      </Column>

      <Column sm={12}>
        <Row gapSm="sm" justify="space-between">
          <Column sm={12} md={6}>
            <Number
              fieldAttr={{ id: 'startKilometers', required: true, placeholder: '--' }}
              fieldProps={{
                label: t({
                  id: 'myTrips.drivingLogFormFields.odometerStart',
                  message: 'Odometer start',
                }),
                suffix: 'km',
                integersOnly: true,
                min: Math.round(rental.startKilometers!),
                helperText: t({
                  id: 'myTrips.drivingLogFormFields.odometerStarHelperText',
                  message: `Rental started at: ${i18n.number(rental.startKilometers!, {
                    unit: 'kilometer',
                    unitDisplay: 'short',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                    style: 'unit',
                  })}`,
                }),
              }}
              name="startKilometers"
            />
          </Column>
          <Column sm={12} md={6}>
            <Number
              fieldAttr={{ id: 'endKilometers', required: true, placeholder: '--' }}
              fieldProps={{
                max: Math.round(rental.endKilometers ?? rental.startKilometers!),
                integersOnly: true,
                label: t({
                  id: 'myTrips.drivingLogFormFields.odometerEnd',
                  message: 'Odometer end',
                }),
                suffix: 'km',
                helperText: t({
                  id: 'myTrips.drivingLogFormFields.odometerEndHelperText',
                  message: `Rental ended at: ${i18n.number(
                    rental.endKilometers ?? rental.startKilometers!,
                    {
                      unit: 'kilometer',
                      unitDisplay: 'short',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      style: 'unit',
                    },
                  )}`,
                }),
              }}
              name="endKilometers"
            />
          </Column>
        </Row>
      </Column>

      <Column sm={12}>
        <Textarea
          fieldAttr={{
            id: 'note',
          }}
          fieldProps={{
            label: t({ id: 'myTrips.drivingLogFormFields.notes', message: 'Notes' }),
          }}
          name="note"
        />
      </Column>
    </Row>
  );
};

export default TripDrivingLogFormFields;
