import { addMinutes, format, roundToNearestMinutes } from 'date-fns';

/**
 * When displaying initial time for the date picker of booking there are several different cases that need to be considered.
 * Case 1: If start/end booking time preferences is present in the dashboard but that value had already passed -> we need to display "now" + buffer time
 * Case 2: If start/end booking time preferences is present in the dashboard and is available -> display it
 * Case 3: If start/end booking time preferences is missing in the dashboard, display now time + buffer provided
 * @param initialTime
 * @param buffer -> How much buffer minutes should be added, eg: 30.... add 30 minutes to new Date()
 * @param roundTo
 */
export const formatInitialTime = (
  initialTime: string | undefined,
  buffer: number,
  roundTo: number = 15,
): string => {
  if (initialTime) {
    const [hours, minutes] = initialTime.split(':');
    // We need to check if booking settings for initial time is in the past or not
    const timeAsPerConfig = new Date(new Date().setHours(Number(hours), Number(minutes)));

    if (timeAsPerConfig > new Date()) {
      return initialTime;
    }
  }

  return format(
    roundToNearestMinutes(addMinutes(new Date(), buffer), {
      nearestTo: +roundTo,
    }),
    'HH:mm',
  );
};
