import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, Column, DropdownWrapper, Row, Text } from '@gourban/ui-components';
import cn from 'classnames';
import styles from '@/features/booking/assets/scss/components/Filters.module.scss';
import LocationSelect from '@/features/geomap/components/Form/LocationSelect';
import DatePicker from '@/features/booking/components/Filters/DatePicker';
import { Form, useFormikContext } from 'formik';
import { BookingFiltersForm } from '@/features/booking/types';
import AttributesFilters from '@/features/booking/components/Filters/AttributesFilters';
import { getNumberOfActiveFilters } from '../../utils/getNumberOfActiveFilters';
import { useLocation, useParams } from 'react-router-dom';
import { plural, t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const FormFields: FC = () => {
  const [filterDropdownVisible, setFilterDropdownVisible] = useState<boolean>(false);
  const [dateDropdownVisible, setDateDropdownVisible] = useState<boolean>(false);
  const { setFieldValue, values, isSubmitting } = useFormikContext<BookingFiltersForm>();
  const { branchId } = useParams();
  const [filtersDirty, setFiltersDirty] = useState<boolean>(false);
  const location = useLocation();
  useLingui();

  const previousValues = useRef<Pick<BookingFiltersForm, 'vehicleFilters' | 'location' | 'date'>>({
    vehicleFilters: values.vehicleFilters,
    location: values.location,
    date: values.date,
  });

  useEffect(() => {
    const hasChanges = Object.entries(previousValues.current).some(([key, value]) => {
      return (
        value !==
        values[key as keyof Pick<BookingFiltersForm, 'vehicleFilters' | 'location' | 'date'>]
      );
    });

    // If some of these values changes, we need to set filters to be dirty programmatically
    if (!filtersDirty && hasChanges) setFiltersDirty(true);

    if (hasChanges) {
      previousValues.current = {
        vehicleFilters: values.vehicleFilters,
        location: values.location,
        date: values.date,
      };
    }

    // eslint-disable-next-line
  }, [values.vehicleFilters, values.location, values.date, isSubmitting]);

  const isReadOnly = location.pathname === `/booking/${branchId}`;

  return (
    <Form className={cn(styles.filters__form)}>
      <Row noWrap justify="space-between" className={styles.filters__form__container}>
        <Column className={styles['filters__form__container-item']} sm={4}>
          <LocationSelect
            readonly={isReadOnly}
            fieldAttr={{
              placeholder: t({
                id: 'bookings.filters.pickUpDropOff',
                message: 'Pick up & drop off',
              }),
            }}
            fieldProps={{
              clearable: !isReadOnly,
              onClear: () => {
                void setFieldValue('locationCoordinates', undefined);
                void setFieldValue('boundingBoxCoordinates', undefined);
              },
              onChange: ({ additional }) => {
                const { locationCoordinates, boundingBoxCoordinates } = additional;
                void setFieldValue('locationCoordinates', locationCoordinates);
                void setFieldValue('boundingBoxCoordinates', boundingBoxCoordinates);
                if (!values.date && !values.vehicleFilters) {
                  setDateDropdownVisible(true);
                }
              },
              iconLeft: { icon: 'location', color: 'var(--gs-500)' },
              borderless: true,
            }}
            additionalMapping={{ boundingBoxCoordinates: 'bbox', locationCoordinates: 'center' }}
          />
        </Column>
        <Column className={styles['filters__form__container-item']} grow>
          <DatePicker dropdownVisible={dateDropdownVisible} readOnly={isReadOnly} />
        </Column>
        <Column
          className={cn(
            styles['filters__form__container-item'],
            styles['filters__form__container-item--vehicle-filters'],
          )}
          sm={4}
        >
          <Row noWrap>
            <Column grow>
              <DropdownWrapper
                triggerElement={
                  <Text
                    name="vehicleFilters"
                    fieldProps={{
                      clearable: !isReadOnly,
                      onClear: () => {
                        void setFieldValue('vehicleFilters', undefined);
                        void setFieldValue('vehicleAttributes', undefined);
                      },
                      label: '',
                      iconLeft: { icon: 'filters', color: 'var(--gs-500)' },
                      borderless: true,
                    }}
                    fieldAttr={{
                      id: 'vehicleFilters',
                      placeholder: t({
                        id: 'bookings.filters.allVehicles',
                        message: 'All vehicles',
                      }),
                      onFocus: () => setFilterDropdownVisible(true),
                      readOnly: true,
                    }}
                  />
                }
                onRequestClose={() => {
                  if (filterDropdownVisible) {
                    setFilterDropdownVisible(false);
                    const activeFilterNumber = getNumberOfActiveFilters(values.vehicleAttributes);
                    void setFieldValue(
                      'vehicleFilters',
                      activeFilterNumber
                        ? t({
                            id: 'bookings.filters.activeFilterNumber',
                            message: plural(activeFilterNumber, {
                              one: '# Filter',
                              other: '# Filters',
                            }),
                          })
                        : undefined,
                    );
                  }
                }}
                dropdownContainerClassName={styles['filters__attributes-dropdown']}
                dropdownContent={<AttributesFilters />}
                isVisible={!isReadOnly && filterDropdownVisible}
              />
            </Column>

            <Column className={styles['filters__form-submit']}>
              <Button
                disabled={isReadOnly}
                attributes={{
                  type: isReadOnly ? 'button' : 'submit',
                  'data-testid': 'FilterSearchSubmit',
                }}
                size="small"
                variation="primary"
                iconSize={{ width: 16, height: 20 }}
                iconName="search"
                onClick={() => {
                  setTimeout(() => {
                    setFiltersDirty(false);
                  }, 0);
                }}
                className={cn(isReadOnly && styles['filters__form-submit--hidden'])}
              >
                {filtersDirty && <Trans id="general.search">Search</Trans>}
              </Button>
            </Column>
          </Row>
        </Column>
      </Row>
    </Form>
  );
};

export default FormFields;
