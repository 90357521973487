import React, { FC, PropsWithChildren, ReactNode } from 'react';
import styles from '../assets/scss/components/PageContent.module.scss';
import { ContentSize } from '@/core/types';
import cn from 'classnames';

interface PageContentT {
  header?: ReactNode;
  contentSize?: ContentSize;
  contentClassName?: string;
  aside?: ReactNode;
}

const PageContent: FC<PropsWithChildren<PageContentT>> = ({
  header,
  children,
  contentSize,
  contentClassName,
  aside,
}) => {
  return (
    <div className={styles['page-content']}>
      {header}
      <div className={styles['page-content__main']}>
        {aside && <div className={styles['page-content__main-aside']}>{aside}</div>}
        <div className={styles['page-content__main-wrapper']}>
          <div
            className={cn(
              contentClassName,
              styles['page-content__main-content'],
              styles[`page-content__main-content--${contentSize}`],
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContent;
