import { t } from '@lingui/macro';

interface ValidationArgs {
  property?: string;
}

interface MinMaxArgs extends ValidationArgs {
  min: number;
  max: number;
}

interface RequiredNumberArgs extends ValidationArgs {
  value: number;
}

interface OptionalNumberArgs extends ValidationArgs {
  value?: number;
}

export const CommonValidationTranslations = {
  maxLength: (value: number) =>
    t({ id: 'validations.maxLength.invalid', message: `Max length is ${value}` }),
  minLength: (value: number) =>
    t({ id: 'validations.minLength.invalid', message: `Min length is ${value}` }),
  requiredField: (value?: string) =>
    !value
      ? t({ id: 'validations.required', message: `This field is required` })
      : t({ id: 'validations.field.required', message: `${value} field is required` }),
  invalidField: (value: string) =>
    t({ id: 'validations.field.invalid', message: `${value} is invalid` }),
  invalidPhoneNumber: (): string =>
    t({ id: 'general.validation.invalidPhoneNumber', message: 'Must be valid phone number' }),
  number: {
    moreThan: ({ property, value = 0 }: OptionalNumberArgs = {}): string =>
      property
        ? t({
            id: 'general.validation.moreThanProperty',
            message: `${property} must be more than ${value}`,
          })
        : t({ id: 'general.validation.moreThan', message: `Must be more than ${value}` }),
    integer: (): string => t({ id: 'general.validation.integer', message: `Must be an integer` }),
    range: ({ min, max, property }: MinMaxArgs): string =>
      property
        ? t({
            id: 'general.validation.numberRangeProperty',
            message: `${property} must be between ${min} and ${max}`,
          })
        : t({ id: 'general.validation.numberRange', message: `Must be between ${min} and ${max}` }),
    atMost: ({ value, property }: RequiredNumberArgs): string =>
      property
        ? t({
            id: 'general.validation.atMostProperty',
            message: `${property} must be ${value} or below`,
          })
        : t({ id: 'general.validation.atMost', message: `Must be ${value} or below` }),
    atLeast: ({ value = 0, property }: OptionalNumberArgs = {}): string =>
      property
        ? t({
            id: 'general.validation.atLeastProperty',
            message: `${property} must be ${value} or above`,
          })
        : t({ id: 'general.validation.atLeast', message: `Must be ${value} or above` }),
  },
};
