import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@gourban/ui-components';
import { useTypedSelector } from '@/core/redux/hooks';
import { getIsStepCompleted } from '@/features/account/redux/account.selectors';
import { Trans } from '@lingui/macro';
import AccountVerification from '@/features/account/components/Verification/AccountVerification';
import {
  useRequestMeDataQuery,
  useRequestUserUpdateMutation,
} from '@/features/account/services/Account.service';
import FullScreen from '@/core/components/Layouts/FullScreen';
import AccountStepper from '@/features/account/components/Onboarding/Stepper';
import { basicInfo, payment, verification } from '@/core/pages/Account/routes';
import OnboardingActionButtons from '@/features/account/components/Onboarding/OnboardingActionButtons';
import ResponsiveFixedButtons from '@/core/components/UI/ResponsiveFixedButtons';

const Verification: FC = () => {
  const navigate = useNavigate();
  const isComplete = useTypedSelector((state) => getIsStepCompleted(state, 'verification'));
  const { data: userData, refetch, isFetching: isFetchingMeData } = useRequestMeDataQuery();
  const [updateUser, { isLoading: isLoadingSkipAccountSetup }] = useRequestUserUpdateMutation();

  const onFinish = () => {
    if (userData?.customProperties?.accountSetupSkipped) {
      // Already skipped, no need to update customProperties, just navigate to the path
      navigate('/');
      return;
    }

    updateUser({
      customProperties: {
        ...(userData?.customProperties ?? {}),
        accountSetupSkipped: true,
      },
    })
      .unwrap()
      .then(() => refetch().unwrap())
      .then(() => {
        navigate('/');
      });
  };

  return (
    <FullScreen
      title={
        <AccountStepper
          steps={[
            { stepName: 'basic-info', ...basicInfo },
            { stepName: 'payment', ...payment },
            { stepName: 'verification', ...verification },
          ]}
        />
      }
      actions={
        <ResponsiveFixedButtons>
          <OnboardingActionButtons
            nextButton={
              <Button
                loading={isLoadingSkipAccountSetup || isFetchingMeData}
                disabled={!isComplete}
                onClick={onFinish}
              >
                {isComplete ? (
                  <Trans id="general.finish">Finish</Trans>
                ) : (
                  <Trans id="general.next">Next</Trans>
                )}
              </Button>
            }
          />
        </ResponsiveFixedButtons>
      }
      main={<AccountVerification />}
    />
  );
};

export default Verification;
