import { Column, Dropdown, DropdownItem, Row, Svg } from '@gourban/ui-components';
import { AvailableLocales } from '@/core/enums';
import { useLingui } from '@lingui/react';
import { useAvailableLocales } from '@/tests/utils/useAvailableLocales';
import { FC } from 'react';
import langPickerStyles from '@/core/assets/scss/components/LangPicker.module.scss';

interface LangPickerT {
  onLangSelected: (lang: AvailableLocales) => void;
}

const LangPicker: FC<LangPickerT> = ({ onLangSelected }) => {
  const { i18n } = useLingui();
  const languageOptions = useAvailableLocales();

  // Div wrapper has to be present because of flex parent containers, since Dropdown appends additional div for dropdown
  // Therefor stuff like space-between dont work properly since they take into account that appended div as well
  return (
    <div>
      <Dropdown
        dropdownButtonClassName={langPickerStyles['lang-picker']}
        direction="bottom"
        align="end"
        variation="link"
        label={
          <Row alignItems="center" gapSm="xs">
            <Column>{i18n.locale.toUpperCase()}</Column>
            <Column>
              <Svg width={12} height={10} icon="arrowBottom" />
            </Column>
          </Row>
        }
      >
        {languageOptions.map(({ value, label }) => (
          <DropdownItem onClick={() => onLangSelected(value)} key={value}>
            {label}
          </DropdownItem>
        ))}
      </Dropdown>
    </div>
  );
};

export default LangPicker;
