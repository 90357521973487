type CopyFn = (
  text: string,
  onCopy?: (text: string) => void,
  onCopyFailed?: () => void,
) => Promise<boolean>;

export const copyToClipboard: CopyFn = async (text, onCopy, onCopyFailed) => {
  if (!navigator?.clipboard) {
    console.warn('Clipboard not supported');
    return false;
  }

  try {
    await navigator.clipboard.writeText(text);
    if (onCopy) onCopy(text);
    return true;
  } catch (error) {
    console.warn('Copy failed', error);
    if (onCopyFailed) onCopyFailed();
    return false;
  }
};
