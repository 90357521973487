import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { AuthService } from '@/features/auth/services/Auth.service';
import { reducer as authReducer } from '@/features/auth/redux/authorization.reducer';
import { reducer as vehicleReducer } from '@/features/vehicles/redux/Vehicles.reducer';
import { reducer as settingsManagementReducer } from '@/core/redux/reducers/settingsManagement.reducer';
import { BranchesService } from '@/features/branches/services/Branches.service';
import { accountReducer } from '@/core/features/account/redux/account.reducer';
import { AccountService } from '@/features/account/services/Account.service';
import { SettingsManagementService } from '@/core/services/SettingsManagement.service';
import { BookingService } from '@/features/booking/services/Booking.service';
import { VehiclesService } from '@/features/vehicles/services/Vehicles.service';
import { PaymentService } from '@/features/payment/services/Payment.service';
import { VerificationService } from '@/features/account/services/Verification.service';
import { MapService } from '@/features/geomap/services/Map.service';
import { TenantService } from '@/features/auth/services/Tenant.service';
import { BusinessAccountService } from '@/features/businessAccounts/services/BusinessAccount.service';
import { InvoiceService } from '@/core/services/Invoice.service';
import { DrivingLogService } from '@/features/myTrips/services/DrivingLog.service';
import { WhitelabelService } from '@/core/services/WhiteLabeling.service';

export const services = [
  AuthService,
  BranchesService,
  AccountService,
  SettingsManagementService,
  BookingService,
  VehiclesService,
  PaymentService,
  VerificationService,
  MapService,
  TenantService,
  BusinessAccountService,
  InvoiceService,
  DrivingLogService,
  WhitelabelService,
] as const;

/**
 * A utility type that gets the type of elements inside an array.
 */
type ArrayElementTypes<A> = A extends readonly (infer T)[] ? T : never;

/**
 * Applied to the services array, this type will be a union of all the reducer paths.
 */
type ServiceElementTypes = ArrayElementTypes<typeof services>;
type ReducerPath = ServiceElementTypes['reducerPath']; // 'api-auth' | 'api-branches'...
type Reducer = ServiceElementTypes['reducer'];
type ServicesReducers = Record<ReducerPath, Reducer>;
// Typed root state
export type RootState = ReturnType<typeof store.getState>;
// Correctly typed dispatch
export type AppDispatch = typeof store.dispatch;

const reducer = combineReducers({
  account: accountReducer,
  auth: persistReducer({ key: 'auth', storage }, authReducer),
  settings: settingsManagementReducer,
  vehicles: vehicleReducer,
  ...services.reduce(
    (acc, { reducerPath, reducer: r }) => ({ ...acc, [reducerPath]: r }),
    {} as ServicesReducers,
  ),
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          AuthService.endpoints.requestUserLogin.matchFulfilled as unknown as string,
          AuthService.endpoints.requestPhoneLogin.matchFulfilled as unknown as string,
        ],
      },
    }).concat(...services.map(({ middleware }) => middleware)),
});

//  Prepare persistor for persistStore
const persistor = persistStore(store);

//  Exporting store and persistor for providers in index
export { store, persistor };
