import React, { FC, ReactNode } from 'react';
import { Column, Svg, Row, Heading, IconName, IconColoringMode } from '@gourban/ui-components';
import styles from '@/core/assets/scss/components/Banner.module.scss';
import cn from 'classnames';

interface BannerT {
  header: ReactNode;
  message: ReactNode;
  icon: IconName;
  iconColor?: string;
  iconColorMode?: IconColoringMode;
  className?: string;
}

const Banner: FC<BannerT> = ({
  header,
  message,
  icon,
  iconColorMode = 'fill',
  iconColor = 'var(--gs-600)',
  className,
}) => (
  <Row column alignItems="center" className={cn(styles.banner, className)}>
    <Column marginBottom={16} className={styles.banner__icon}>
      <Svg
        icon={icon}
        strokeColor={iconColorMode === 'stroke' ? iconColor : undefined}
        iconColor={iconColorMode === 'fill' ? iconColor : undefined}
        width={35}
        height={35}
      />
    </Column>
    <Column marginBottom={16}>
      <Heading align="center" size={2}>
        {header}
      </Heading>
    </Column>
    <Column>{message}</Column>
  </Row>
);

export default Banner;
