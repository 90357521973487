import { createApi } from '@reduxjs/toolkit/query/react';
import { RestClientWrapper } from '@/core/api/restClientWrapper';
import { RegisterArgs, ForgotPasswordArgs } from '@/core/features/auth/types/Auth.types';
import { UserData, UserTermsAndConditionsStatus } from '@/features/auth/types/User.types';
import { SkippableInvalidation } from '@/core/types';
import { RootState, store } from '@/core/redux';
import { DataViewsConfig, FilterPresetAdd, NotificationStore } from '@gourban/ui-components';
import { produce } from 'immer';
import { AccountCacheTags, UserEntityKeys } from '../enums';
import { t } from '@lingui/macro';

export const AccountService = createApi({
  reducerPath: 'api-account',
  baseQuery: RestClientWrapper,
  tagTypes: [AccountCacheTags.REQUEST_ME_DATA, AccountCacheTags.TERMS_AND_CONDITIONS],
  endpoints: (builder) => ({
    requestMeData: builder.query<UserData, void>({
      query: () => ({
        url: '/front/customers',
      }),
      keepUnusedDataFor: 60 * 30,
      providesTags: [AccountCacheTags.REQUEST_ME_DATA],
    }),

    requestUserUpdate: builder.mutation<null, SkippableInvalidation<Partial<UserData>>>({
      query: (data) => ({
        url: '/front/customers',
        requestParams: { method: 'patch', data },
        config: {
          onRequestSuccess: () => {
            NotificationStore.addNotification({
              type: 'success',
              title: t({ id: 'general.notification.success', message: 'Success' }),
              content: t({
                id: 'account.notification.updateSuccess',
                message: 'Profile updated successfully.',
              }),
            });
          },
        },
      }),
      invalidatesTags: (results, error, { skipInvalidation }) => {
        return skipInvalidation || error ? [] : [AccountCacheTags.REQUEST_ME_DATA];
      },
    }),

    requestRegister: builder.mutation<null, RegisterArgs>({
      query: (data) => ({
        url: '/front/customers',
        requestParams: { method: 'post', data },
        config: {
          ignoreJWTCheck: true,
          displayNotificationOnError: false,
        },
      }),
    }),

    requestResendEmail: builder.mutation<null, Pick<RegisterArgs, 'email'>>({
      query: ({ email }) => ({
        url: `/front/customers/verify-email/resend/${email}`,
        requestParams: { method: 'post' },
        config: {
          ignoreJWTCheck: true,
          displayNotificationOnError: false,
        },
      }),
    }),

    requestForgotPassword: builder.mutation<null, ForgotPasswordArgs>({
      query: ({ email }) => ({
        url: `/front/customers/reset/${email}`,
        config: {
          ignoreJWTCheck: true,
        },
        requestParams: {
          method: 'post',
          params: {
            target: 'user-web-app',
          },
        },
      }),
    }),

    requestUserTermsAndConditionsStatus: builder.query<UserTermsAndConditionsStatus, string>({
      providesTags: (termsAndConditions) => {
        if (!termsAndConditions?.branchId) {
          return [];
        }
        return [{ type: AccountCacheTags.TERMS_AND_CONDITIONS, id: termsAndConditions.branchId }];
      },
      query: (branchId) => ({
        url: '/front/customers/termsandconditions',
        requestParams: { method: 'get', params: { branchId } },
      }),
      keepUnusedDataFor: 0,
    }),

    requestUserAcceptedTermsAndConditions: builder.mutation<
      Pick<UserTermsAndConditionsStatus, 'branchId'>,
      string
    >({
      query: (branchId) => ({
        url: '/front/termsandconditions',
        requestParams: { method: 'post', params: { branchId } },
      }),
      transformResponse: (data, meta, branchId) => {
        // Backend doesn't return anything when its accepted, but we need the branchId in the invalidations so we can invalidate a request
        return { branchId: Number(branchId) };
      },
      invalidatesTags: (result) => {
        return [{ type: AccountCacheTags.TERMS_AND_CONDITIONS, id: result?.branchId }];
      },
    }),

    requestEntityPresetsUpdate: builder.mutation<
      UserData,
      { preset: FilterPresetAdd; key: UserEntityKeys }
    >({
      queryFn: async ({ preset, key }) => {
        const state: RootState = store.getState();
        const meDataSelector = AccountService.endpoints.requestMeData.select()(state);

        const userData: UserData = produce<UserData>({ ...meDataSelector?.data! }, (draft) => {
          if (!draft?.customProperties?.presets) draft.customProperties!.presets = {};

          if (!draft.customProperties!.presets[key]) draft.customProperties!.presets[key] = {};

          draft.customProperties!.presets[key][preset.uuid] = preset;
        });

        return RestClientWrapper<UserData>({
          url: '/front/customers',
          requestParams: {
            method: 'patch',
            data: {
              customProperties: userData.customProperties,
            },
          },
        });
      },
      invalidatesTags: [AccountCacheTags.REQUEST_ME_DATA],
    }),

    requestEntityConfigUpdate: builder.mutation<
      UserData,
      { config: DataViewsConfig; key: UserEntityKeys }
    >({
      queryFn: async ({ config, key }) => {
        const state: RootState = store.getState();
        const meDataSelector = AccountService.endpoints.requestMeData.select()(state);

        const userData: UserData = produce<UserData>({ ...meDataSelector?.data! }, (draft) => {
          if (!draft?.customProperties?.userConfig) draft.customProperties!.userConfig = {};

          if (!draft.customProperties!.userConfig[key])
            draft.customProperties!.userConfig[key] = {};

          draft.customProperties!.userConfig[key] = config;
        });

        return RestClientWrapper<UserData>({
          url: '/front/customers',
          requestParams: {
            method: 'patch',
            data: {
              customProperties: userData.customProperties,
            },
          },
        });
      },
      invalidatesTags: [AccountCacheTags.REQUEST_ME_DATA],
    }),
  }),
});

export const {
  useRequestUserUpdateMutation,
  useRequestMeDataQuery,
  useRequestRegisterMutation,
  useRequestResendEmailMutation,
  useRequestForgotPasswordMutation,
  useRequestUserTermsAndConditionsStatusQuery,
  useRequestUserAcceptedTermsAndConditionsMutation,
  useRequestEntityPresetsUpdateMutation,
  useRequestEntityConfigUpdateMutation,
} = AccountService;
