import React from 'react';
import cssModules from '@/core/assets/scss/components/Dashboard/MainMenu.module.scss';
import { NavLink } from 'react-router-dom';
import { NavigationItemT } from '@/core/types';
import cn from 'classnames';
import useNavigationLinkState from '@/core/hooks/useNavigationLinkState';

const SubMenuItem: React.FC<Pick<NavigationItemT, 'to' | 'additionalPaths' | 'content'>> = ({
  to,
  additionalPaths,
  content,
}) => {
  const { active } = useNavigationLinkState(to, additionalPaths);

  return (
    <li
      className={cn(
        cssModules['main-navigation__item'],
        cssModules['main-navigation__item--submenu'],
      )}
    >
      <NavLink
        className={cn([
          cssModules['main-navigation__item__link'],
          cssModules['main-navigation__item--submenu__link'],
          active && cssModules['main-navigation__item__link--active'],
          active && cssModules['main-navigation__item--submenu__link--active'],
        ])}
        to={to!}
      >
        {content}
      </NavLink>
    </li>
  );
};

export default SubMenuItem;
