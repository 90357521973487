import React, { FC, useState } from 'react';
import { Button, Column, Row } from '@gourban/ui-components';
import PaymentSources from '@/features/payment/components/PaymentSources';
import styles from '@/features/payment/assets/PaymentSources.module.scss';
import { Trans } from '@lingui/macro';
import { BusinessAccount } from '@/features/businessAccounts/types';

interface AccountPaymentT {
  businessAccount?: BusinessAccount;
}

const PaymentProvider: FC<AccountPaymentT> = ({ businessAccount }) => {
  const [displayProviderForm, setDisplayProviderForm] = useState(false);

  return (
    <Row justify="flex-start">
      <Column marginBottom={8} sm={12}>
        <PaymentSources
          businessAccount={businessAccount}
          onPaymentMethodAdded={() => setDisplayProviderForm(false)}
          onAddPaymentMethod={() => setDisplayProviderForm(true)}
          displayAddPaymentButton
          displayProviderForm={displayProviderForm}
        />
      </Column>
      {displayProviderForm && (
        <Button
          onClick={() => setDisplayProviderForm(false)}
          width="full"
          className={styles['payment-card']}
          noPadding
          variation="link"
        >
          <Trans id="general.cancel">Cancel</Trans>
        </Button>
      )}
    </Row>
  );
};

export default PaymentProvider;
