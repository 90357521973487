import { BaseUnitDisplay } from '@/core/types';
import { getFormattedUnitDisplay } from '@/core/utils/getFormattedUnitDisplay';
import { useLingui } from '@lingui/react';
import { capitalize } from 'lodash';
import { FC } from 'react';

interface UnitDisplayT extends BaseUnitDisplay {
  capitalized?: boolean;
}

const UnitDisplay: FC<UnitDisplayT> = ({
  unit,
  quantity = 1,
  unitDisplay = 'short',
  includeValue,
  capitalized,
}) => {
  const {
    i18n: { locale },
  } = useLingui();

  const displayValue = getFormattedUnitDisplay({
    unit,
    quantity,
    unitDisplay,
    locale,
    includeValue,
  });

  // fragment needed as simple string does not match FC return type
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{capitalized ? capitalize(displayValue) : displayValue}</>;
};

export default UnitDisplay;
