import { ErrorHandler } from '@/core/components/ErrorHandlers/ErrorHandlingProvider';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosResponseHeaders,
} from 'axios';
import { NotificationStore } from '@gourban/ui-components';
import { ApiError, RestClientQueryT } from '@/core/types';
import restClient from './restClient';
import { t } from '@lingui/macro';
import TraceIdCollapsable from '@/core/components/ErrorHandlers/TraceIdCollapsable';

export interface RestClientWrapperReturnValue<T = unknown> {
  data: T;
  meta: { request: AxiosRequestConfig; headers: RawAxiosResponseHeaders };
}

const onError = (
  requestParams: RestClientQueryT['requestParams'],
  errorResponse?: AxiosResponse<ApiError>,
) => {
  // If get request fails, only display notification error
  if (requestParams?.method === 'get' || requestParams?.method === 'GET') {
    NotificationStore.addNotification({
      type: 'error',
      title: t({ id: 'general.notification.error', message: 'Error' }),
      content: TraceIdCollapsable({
        errorResponse: errorResponse?.data,
        errorMessage: errorResponse?.data?.userMessage ?? errorResponse?.data?.errorMessage,
      }),
      duration: 10000,
      position: 'bottom-right',
    }); // If other CRUD requests fail, display error handler modal
  } else {
    ErrorHandler.createError({
      error: errorResponse?.data ?? { errorCode: 'unknown' },
      requestConfig: errorResponse?.config as AxiosRequestConfig,
    });
  }
};

/**
 * RestClientWrapper is used in all requests that go through RTK Query
 */
export const RestClientWrapper = async <T>({
  url,
  requestParams = { method: 'get' },
  config = { displayNotificationOnError: true },
}: RestClientQueryT): Promise<RestClientWrapperReturnValue<T> | { error: ApiError }> => {
  if (config.displayNotificationOnError === undefined) config.displayNotificationOnError = true;
  if (!requestParams.method) requestParams.method = 'get';

  let params = requestParams?.params;

  if (config?.ignoreJWTCheck) {
    if (!params) {
      params = {};
    }

    params.ignoreJWTCheck = true;
  }

  try {
    const response = await restClient(url, {
      ...requestParams,
      params,
    });

    if (config.onRequestSuccess) config.onRequestSuccess(response);

    return {
      data: response.data,
      meta: { request: response.request, headers: response.headers },
    };
  } catch (e: any) {
    if (!axios.isCancel(e)) {
      if (config.displayNotificationOnError && !config.onRequestFailed) {
        onError(requestParams, e.response);
      }
      if (config.onRequestFailed) config.onRequestFailed(e as AxiosError, requestParams);
      return { error: e.response?.data };
    }
    throw e;
  }
};
