import { FC } from 'react';
import {
  Row,
  Column,
  Paragraph,
  Tags,
  Svg,
  DropdownItem,
  Dropdown,
  Loader,
  NotificationStore,
  Button,
  Alert,
} from '@gourban/ui-components';
import { Booking, BookingArgs } from '@/features/booking/types';
import { useVehicleCategories } from '@/features/myTrips/hooks/useVehicleCategories';
import VehicleCategoryDescription from '@/features/vehicles/components/VehicleCategoryDescription';
import styles from '@/features/myTrips/assets/scss/components/Trips/MyTrip.module.scss';
import MyTripStatus from '@/features/myTrips/components/Trips/MyTripStatus';
import { Trans, t } from '@lingui/macro';
import { useLazyRequestBookingByIdQuery } from '@/features/booking/services/Booking.service';
import TripHeader from '@/features/myTrips/components/Trips/TripHeader';
import DownloadInvoiceMenuOption from '@/features/myTrips/components/Trips/DownloadInvoiceMenuOption';
import LocationGuidelines from '@/features/booking/components/LocationGuidelines';
import { isAfter, format, differenceInDays } from 'date-fns';
import VehicleCategoryPricingDisplay from '@/features/vehicles/components/VehicleCategoryPricingDisplay';
import { useNavigate } from 'react-router-dom';
import { useModal } from '@/core/components/UI/modals/ModalProvider';
import CancelBooking, { CancelBookingT } from '@/features/booking/components/modals/CancelBooking';

export interface MyTripT {
  trip: Booking;
  view: BookingArgs['view'];
  onEditClicked: () => void;
}

const MyTrip: FC<MyTripT> = ({ trip, view, onEditClicked }) => {
  const categories = useVehicleCategories();
  const category = categories![trip.vehicleCategoryId];
  const [requestBooking, { isFetching: isFetchingBookingData }] = useLazyRequestBookingByIdQuery();
  const isCancelable = view === 'UPCOMING' && trip.state !== 'CANCELED';
  const isEditable =
    isCancelable &&
    (trip.editableUntil ? isAfter(new Date(trip.editableUntil), new Date()) : false);
  const isPastBooking = view === 'PAST';
  const isCanceledBooking = view === 'CANCELED';
  const hasInvoice = !!trip.invoiceId;
  const shouldShowTripOptions = isCancelable || hasInvoice;
  const navigate = useNavigate();
  const { showModal } = useModal();

  return (
    <Row
      gapLg="md"
      gapSm="sm"
      marginBottom={0}
      className={styles['my-trip']}
      justify="space-between"
    >
      <Column sm={12} md={6} lg={6}>
        <TripHeader tripId={trip.id} tripState={trip.state} categoryName={category.name} />
        {trip.price && (
          <Paragraph color="var(--gs-500)">
            <VehicleCategoryPricingDisplay
              extendedPricing={trip.pricing}
              currency={trip.price.currency}
              grossPrice={trip.price.grossPrice}
            />
          </Paragraph>
        )}
        <Row className={styles['my-trip__content']} marginBottom={32}>
          <Column>
            {category.sharedProperties?.vehicleCategoryFeatures?.map((feature) => (
              <Tags
                style="squared"
                size="tiny"
                bgColor="transparent"
                borderColor="var(--gs-200)"
                content={feature.title}
                key={feature.title}
              />
            ))}
          </Column>
        </Row>
        <Row>
          <VehicleCategoryDescription description={category.description} />
        </Row>
        <Row>
          <MyTripStatus view={view} trip={trip} />
        </Row>
        {!isPastBooking && !isCanceledBooking && (
          <Row>
            <LocationGuidelines locationGuidelines={trip.branch.contactInfo?.locationGuidelines} />
          </Row>
        )}
        {isPastBooking && !!trip.drivingLog && (
          <Row gapSm="sm" marginBottom={0}>
            <Column marginBottom={0} sm={12}>
              <Button onClick={() => navigate(`/my-trips/${trip.id}/driving-log`)} width="full">
                {trip.drivingLog.status === 'SUBMITTED' ? (
                  <Trans id="myTrip.drivingLog.view">View driving log</Trans>
                ) : (
                  <Trans id="myTrip.drivingLog.edit">Edit driving log</Trans>
                )}
              </Button>
            </Column>
            {trip.drivingLog.status !== 'SUBMITTED' && (
              <Column marginBottom={0} sm={12}>
                <Alert
                  canBeClosed={false}
                  full
                  type="warning"
                  icon="issues"
                  iconHeight={21}
                  fill="var(--y-50)"
                  size="small"
                  title={t({
                    id: 'myTrip.drivingLog.editDeadline.title',
                    message: 'Edit driving log',
                  })}
                  description={
                    <Trans id="myTrip.drivingLog.editDeadline">
                      {differenceInDays(new Date(trip.drivingLog?.dueDate), new Date())} days left
                      until deadline
                    </Trans>
                  }
                />
              </Column>
            )}
          </Row>
        )}
      </Column>
      <Column sm={12} md={6} lg={6}>
        <div className={styles['my-trip__image']}>
          {category.appProperties.image ? (
            <img src={category.appProperties.image} alt={category.name} />
          ) : (
            <div className={styles['my-trip__image--placeholder']}>
              <Svg icon="imagePlaceholder" width={24} height={24} />
            </div>
          )}
          {shouldShowTripOptions && (
            <Dropdown
              noPadding
              direction="bottom"
              align="end"
              variation="secondary"
              dropdownButtonClassName={styles['my-trip__image--menu']}
              attributes={{ role: 'menu', 'aria-label': 'Trip options' }}
              label={<Svg icon="dots" width={20} height={20} />}
            >
              {isEditable && (
                <DropdownItem onClick={onEditClicked}>
                  <Row marginBottom={0} alignItems="center">
                    <Svg icon="edit" iconColor="var(--gs-900)" width={16} />
                    <Paragraph size={3} marginBottom={0}>
                      <Trans id="myTrip.dropdown.editBooking">Edit booking</Trans> (
                      <Trans id="myTrip.dropdown.editBookingUntil">
                        until {format(new Date(trip.editableUntil!), 'dd.MM')}
                      </Trans>
                      )
                    </Paragraph>
                  </Row>
                </DropdownItem>
              )}
              {isCancelable && (
                <DropdownItem
                  onClick={(e) => {
                    e.keepOpen = true;
                    requestBooking({ id: trip.id })
                      .unwrap()
                      .then((response) => {
                        if (response?.cancellationInformation?.canCancel === false) {
                          NotificationStore.addNotification({
                            type: 'error',
                            title: t({ id: 'general.notification.error', message: 'Error' }),
                            content: t({
                              id: 'myTrip.notification.cancelNotAllowed',
                              message: 'Booking can no longer be canceled!',
                            }),
                          });
                        } else {
                          showModal<CancelBookingT>(CancelBooking, {
                            booking: response,
                            currency: trip?.branch?.currency,
                          });
                        }
                      });
                  }}
                >
                  <Row marginBottom={0} alignItems="center">
                    <Svg icon="restricted" iconColor="var(--gs-900)" />
                    <Paragraph size={3} marginBottom={0}>
                      <Trans id="myTrip.dropdown.cancelBooking">Cancel booking </Trans>
                    </Paragraph>
                    {isFetchingBookingData && <Loader cover width={20} height={20} />}
                  </Row>
                </DropdownItem>
              )}
              {hasInvoice && <DownloadInvoiceMenuOption invoiceId={trip.invoiceId!} />}
            </Dropdown>
          )}
        </div>
      </Column>
    </Row>
  );
};

export default MyTrip;
