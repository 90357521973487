import { createApi } from '@reduxjs/toolkit/query/react';
import { RestClientWrapper, RestClientWrapperReturnValue } from '@/core/api/restClientWrapper';
import { BookingAvailableCategoriesArgs } from '@/features/booking/types';
import {
  MergedAvailableCategoriesData,
  AvailableVehicleCategories,
  VehicleCategory,
  Addition,
  VehicleAdditionsArgs,
  VehicleCategoryRentalRequirementArgs,
  VehicleCategoryRentalRequirement,
  VehicleAttributes,
} from '@/features/vehicles/types';
import { mergeDateTime } from '@/features/booking/utils/mergeDateTime';
import { pick } from 'lodash';
import { VehicleCacheTags } from '@/features/vehicles/enums';
import { stringify } from 'qs';

export const VehiclesService = createApi({
  reducerPath: 'api-vehicles',
  baseQuery: RestClientWrapper,
  tagTypes: [VehicleCacheTags.VEHICLE_ATTRIBUTES],
  endpoints: (builder) => ({
    requestAllCategories: builder.query<VehicleCategory[], void>({
      query: () => ({
        url: '/front/vehicles/categories',
      }),
    }),

    requestAvailableVehicleCategories: builder.query<
      MergedAvailableCategoriesData[],
      BookingAvailableCategoriesArgs
    >({
      queryFn: async ({
        branchId,
        startDate,
        endDate,
        startTime,
        endTime,
        vehicleAttributes,
        bookingId,
      }) => {
        const start = new Date(mergeDateTime(new Date(startDate), startTime)).toISOString();
        const end = new Date(mergeDateTime(new Date(endDate), endTime)).toISOString();

        const getAvailableVehicleCategories = () =>
          RestClientWrapper<AvailableVehicleCategories[]>({
            url: '/front/bookings/available-categories',
            requestParams: {
              paramsSerializer: {
                serialize: (param) => stringify(param, { encode: true, arrayFormat: 'comma' }),
              },
              params: {
                branchId,
                includeWithPricingOnly: true,
                startTime: start,
                endTime: end,
                vehicleAttributes,
                excludeBookingId: bookingId,
              },
            },
          });

        const getVehicleCategories = () =>
          RestClientWrapper<VehicleCategory[]>({
            url: '/front/vehicles/categories',
            requestParams: {
              params: { branchId },
            },
          });

        const [availableCategories, vehicleCategories] = await Promise.all([
          getAvailableVehicleCategories(),
          getVehicleCategories(),
        ]);

        // Merge available categories for booking with that vehicle category data
        return {
          data: (
            availableCategories as RestClientWrapperReturnValue<AvailableVehicleCategories[]>
          ).data.reduce((categories, availableCategory) => {
            const vehicleCategoryData = (
              vehicleCategories as RestClientWrapperReturnValue<VehicleCategory[]>
            ).data.find((vehicleCategory) => vehicleCategory.id === availableCategory.categoryId);

            /**
             * TODO: This is currently done on frontend until back fixes it. Available category should be returned if it has no price
             */
            if (vehicleCategoryData && availableCategory.currency && availableCategory.grossPrice) {
              categories.push({
                ...vehicleCategoryData,
                ...pick(availableCategory, ['currency', 'grossPrice', 'netPrice', 'pricing']),
              });
            }

            return categories;
          }, [] as MergedAvailableCategoriesData[]),
        };
      },
    }),

    requestVehicleAdditions: builder.query<Addition[], VehicleAdditionsArgs | void>({
      query: (args) => {
        const vehicleArgs = args as VehicleAdditionsArgs;

        if (vehicleArgs) {
          const start = mergeDateTime(
            new Date(vehicleArgs.startDate!),
            vehicleArgs.startTime,
          ).toISOString();
          const end = new Date(
            mergeDateTime(new Date(vehicleArgs.endDate!), vehicleArgs.endTime),
          ).toISOString();

          return {
            url: '/front/rentals/additions',
            requestParams: {
              params: {
                branchId: vehicleArgs.branchId,
                vehicleCategoryId: vehicleArgs.categoryId,
                bookingStartTime: start,
                bookingEndTime: end,
              },
            },
          };
        }

        return {
          url: '/front/rentals/additions',
        };
      },
      keepUnusedDataFor: undefined,
    }),

    requestVehicleCategoryRequirements: builder.query<
      VehicleCategoryRentalRequirement[],
      VehicleCategoryRentalRequirementArgs
    >({
      query: ({
        branchId,
        bookingEndTime,
        bookingStartTime,
        vehicleCategoryId,
        userGroupCode,
      }) => ({
        url: '/front/rentals/requirements',
        requestParams: {
          params: {
            branchId,
            vehicleCategoryId,
            bookingStartTime,
            bookingEndTime,
            userGroupCode,
          },
        },
      }),
      keepUnusedDataFor: 0,
    }),

    requestVehicleAttributes: builder.query<VehicleAttributes[], void>({
      query: () => ({
        url: '/front/vehicle-attributes',
      }),
      providesTags: [VehicleCacheTags.VEHICLE_ATTRIBUTES],
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const {
  useRequestAllCategoriesQuery,
  useRequestAvailableVehicleCategoriesQuery,
  useRequestVehicleAdditionsQuery,
  useRequestVehicleCategoryRequirementsQuery,
  useRequestVehicleAttributesQuery,
} = VehiclesService;
