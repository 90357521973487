import { store } from '@/core/redux';
import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import { getLocalStorageAuthInfo } from '@/features/auth/utils/getLocalStorageAuthInfo';
import { AuthenticationData } from '@/features/auth/types/Auth.types';
import { AuthService } from '@/core/features/auth/services/Auth.service';
import { omit } from 'lodash';

let refreshTokenPending = false;

const resolveOnRefreshTokenCompletion = (
  currentAuthInfo: AuthenticationData,
  headers: AxiosHeaders,
) =>
  new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const latestAuthInfo = getLocalStorageAuthInfo();

      if (!latestAuthInfo?.accessToken) {
        reject(new axios.Cancel('Refresh token invalid, canceled request.'));
        clearInterval(interval);
        return;
      }

      if (latestAuthInfo?.accessToken !== currentAuthInfo?.accessToken) {
        clearInterval(interval);

        headers.Authorization = `Bearer ${latestAuthInfo?.accessToken}`;

        resolve(headers);
      }
    }, 200);
  });

const applyInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use(
    async (requestConfig) => {
      // If URL trying to be reached is in the list of ignored requests...
      // Let it go through
      if (requestConfig.params?.ignoreJWTCheck) {
        return { ...requestConfig, params: omit(requestConfig.params, ['ignoreJWTCheck']) };
      }

      const currentAuthInfo = getLocalStorageAuthInfo();

      // For every incoming request, set the token from localStorage
      if (currentAuthInfo) {
        requestConfig.headers.Authorization = `Bearer ${currentAuthInfo.accessToken}`;
      }

      // Check if token is expired
      const isExpired =
        !currentAuthInfo ||
        (new Date() >= new Date(currentAuthInfo?.accessTokenExpireDate) &&
          !!axiosInstance.defaults.baseURL);

      // If refreshing token is currently fetching
      // Pause all incoming requests until it is fetched
      // After its fetch continue its execution
      if (refreshTokenPending) {
        // @ts-ignore
        requestConfig.headers = await resolveOnRefreshTokenCompletion(
          currentAuthInfo,
          requestConfig.headers,
        );

        return requestConfig;
      }

      // Token is expired and there is no refresh token being fetched yet
      // Start fetching refresh token
      // If succeeded update this requests auth token and continue with request
      // If fails sign out user ( handled inside requestRefreshToken )
      if (!refreshTokenPending && isExpired) {
        refreshTokenPending = true;
        const controller = new AbortController();
        requestConfig.signal = controller.signal;

        // If token expired, try to get new token
        // If failed user will be logged out in requestRefreshToken function
        try {
          const { accessToken } = await store
            .dispatch(
              AuthService.endpoints.requestRefreshToken.initiate(currentAuthInfo?.refreshToken),
            )
            .unwrap();

          refreshTokenPending = false;

          requestConfig.headers.Authorization = `Bearer ${accessToken}`;

          return requestConfig;
        } catch (e) {
          refreshTokenPending = false;
          controller.abort();
          await store.dispatch(AuthService.endpoints.requestSignOut.initiate());
          return Promise.reject(new axios.Cancel('Refresh token invalid, canceled request.'));
        }
      }

      return requestConfig;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
};

export { applyInterceptors };
