import { FC } from 'react';
import styles from '@/core/assets/scss/components/Layouts/FullScreen.module.scss';
import { FullPageWithHeaderT } from '@/core/types';
import Header from '@/core/components/Header';
import PageContent from '@/core/components/PageContent';

const FullScreen: FC<FullPageWithHeaderT> = ({
  title,
  actions,
  main,
  onClose,
  mainSize = 'narrow',
  aside,
  contentClassName,
}) => {
  return (
    <div className={styles['full-screen']}>
      <PageContent
        contentSize={mainSize}
        header={<Header onClose={onClose} title={title} actions={actions} />}
        aside={aside}
        contentClassName={contentClassName}
      >
        {main}
      </PageContent>
    </div>
  );
};

export default FullScreen;
