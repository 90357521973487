import React, { useMemo, FC } from 'react';
import { Column, Paragraph, Row, Separator, Alert } from '@gourban/ui-components';
import { useTypedSelector } from '@/core/redux/hooks';
import { getVehicleCategoryAdditions } from '@/features/vehicles/redux/Vehicles.selector';
import { useFormikContext } from 'formik';
import { BookingOverviewForm } from '@/features/booking/types';
import { MergedAvailableCategoriesData } from '@/features/vehicles/types';
import { Trans, t } from '@lingui/macro';
import AdditionPriceBreakdown from '@/features/booking/components/AdditionsSelection/AdditionPriceBreakdown';
import { useRequestBookingSettingQuery } from '@/core/services/SettingsManagement.service';
import CurrencyDisplay from '@/core/components/CurrencyDisplay';
import UnitDisplay from '@/core/components/UnitDisplay';
import { useLingui } from '@lingui/react';
import { getNormalizedUnit } from '@/core/utils/getNormalizedUnit';
import styles from '@/features/booking/assets/scss/components/PricingBreakdown.module.scss';

interface PricingBreakdownT {
  vehicleCategoryData: MergedAvailableCategoriesData;
}

const PricingBreakdown: FC<PricingBreakdownT> = ({ vehicleCategoryData }) => {
  const { values } = useFormikContext<BookingOverviewForm>();
  const additionsData = useTypedSelector(getVehicleCategoryAdditions);
  const { data: bookingSettings } = useRequestBookingSettingQuery();
  const { showGrossPrice } = bookingSettings?.value ?? {};
  const { i18n } = useLingui();

  const selectedAdditions = useMemo(() => {
    const selected =
      values.selectedAdditions && additionsData
        ? values.selectedAdditions
            .map((code) => additionsData.find((addition) => addition.code === code))
            .filter(Boolean)
        : [];
    return [...selected];
  }, [additionsData, values.selectedAdditions]);

  const totalPrice = useMemo(() => {
    if (!vehicleCategoryData) return { net: 0, gross: 0 };

    const additionsPriceSum = selectedAdditions.reduce(
      (priceSum, addition) => {
        priceSum.net += addition!.pricingInformation.estimation.net;
        priceSum.gross += addition!.pricingInformation.estimation.gross;

        return priceSum;
      },
      { net: 0, gross: 0 },
    );

    return {
      gross: vehicleCategoryData.grossPrice + additionsPriceSum.gross,
      net: vehicleCategoryData.netPrice + additionsPriceSum.net,
    };
  }, [vehicleCategoryData, selectedAdditions]);

  if (!vehicleCategoryData) return null;

  return (
    <>
      <Row justify="space-between">
        <Column>
          <Paragraph marginBottom={0} weight="medium">
            <Trans id="bookings.createSummary.rentalPrice">Rental price</Trans>
          </Paragraph>
        </Column>
        <Column>
          <Paragraph marginBottom={0} weight="medium">
            <CurrencyDisplay
              value={
                showGrossPrice ? vehicleCategoryData?.grossPrice : vehicleCategoryData?.netPrice
              }
              currency={vehicleCategoryData.currency}
            />
          </Paragraph>
        </Column>
      </Row>
      {selectedAdditions.map((addition) => {
        const estimatedTotal = addition!.pricingInformation.estimation;

        return (
          <Row key={addition!.code} justify="space-between">
            <Column>
              <Paragraph marginBottom={0} weight="medium">
                {addition!.name}
              </Paragraph>
            </Column>
            <Column>
              <Paragraph marginBottom={0} weight="medium">
                <AdditionPriceBreakdown
                  addition={addition!}
                  currency={vehicleCategoryData.currency}
                />
                <CurrencyDisplay
                  value={showGrossPrice ? estimatedTotal.gross : estimatedTotal.net}
                  currency={vehicleCategoryData.currency}
                />
              </Paragraph>
            </Column>
          </Row>
        );
      })}
      {!showGrossPrice && (
        <>
          <Separator backgroundColor="var(--gs-200)" gapBottom={16} gapTop={8} />
          <Row justify="space-between" marginBottom={16}>
            <Column>
              <Paragraph size={2} marginBottom={0} weight="medium">
                <Trans id="bookings.createSummary.subTotal">Subtotal</Trans>
              </Paragraph>
            </Column>
            <Column>
              <Paragraph marginBottom={0} size={2} weight="medium">
                <CurrencyDisplay value={totalPrice.net} currency={vehicleCategoryData.currency} />
              </Paragraph>
            </Column>
          </Row>

          <Row justify="space-between" marginBottom={16}>
            <Column>
              <Paragraph size={2} marginBottom={0} weight="medium">
                <Trans id="bookings.createSummary.VAT">VAT</Trans>
              </Paragraph>
            </Column>
            <Column>
              <Paragraph marginBottom={0} size={2} weight="medium">
                <CurrencyDisplay
                  value={totalPrice.gross - totalPrice.net}
                  currency={vehicleCategoryData.currency}
                />
              </Paragraph>
            </Column>
          </Row>
        </>
      )}
      <Separator backgroundColor="var(--gs-200)" gapBottom={16} gapTop={8} />
      <Row justify="space-between" marginBottom={16}>
        <Column>
          <Paragraph size={2} marginBottom={0} weight="bold">
            <Trans id="bookings.createSummary.totalPrice">Total price</Trans>
            <span className={styles['pricing-breakdown__tax']}>
              (<Trans id="general.includeTaxShort">Incl. VAT</Trans>)
            </span>
          </Paragraph>
        </Column>
        <Column>
          <Paragraph marginBottom={0} size={2} weight="bold">
            <CurrencyDisplay value={totalPrice.gross} currency={vehicleCategoryData.currency} />
          </Paragraph>
        </Column>
      </Row>
      {vehicleCategoryData.pricing?.distancePrice?.rate?.rate && (
        <Alert
          type="info"
          canBeClosed={false}
          full
          className={styles['pricing-breakdown__distance']}
          icon="info"
          title={t({ id: 'bookings.createSummary.distancePrice', message: 'Distance price' })}
          description={
            <>
              {vehicleCategoryData.pricing?.distancePrice?.rate?.included ? (
                <Trans id="bookings.createSummary.distancePriceTooltip">
                  After the included distance of{' '}
                  {i18n.number(vehicleCategoryData.pricing.distancePrice.rate.included, {
                    unit: getNormalizedUnit(vehicleCategoryData.pricing.distancePrice.unit),
                    style: 'unit',
                    unitDisplay: 'short',
                  })}
                  , a rate of{' '}
                  <CurrencyDisplay
                    value={vehicleCategoryData.pricing?.distancePrice?.rate?.rate}
                    currency={vehicleCategoryData.currency}
                  />
                  <UnitDisplay
                    unit={vehicleCategoryData.pricing?.distancePrice?.unit}
                    unitDisplay="shortPer"
                  />{' '}
                  applies.
                </Trans>
              ) : (
                <>
                  <CurrencyDisplay
                    value={vehicleCategoryData.pricing?.distancePrice?.rate?.rate}
                    currency={vehicleCategoryData.currency}
                  />
                  <UnitDisplay
                    unit={vehicleCategoryData.pricing?.distancePrice?.unit}
                    unitDisplay="shortPer"
                  />
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default PricingBreakdown;
