import { useEffect } from 'react';
import { AccountService } from '@/features/account/services/Account.service';
import { AccountCacheTags } from '@/features/account/enums';
import { useDispatch } from 'react-redux';
import { VerificationStatus } from '@/features/account/hooks/useVerificationStatus';
import { useTypedSelector } from '@/core/redux/hooks';
import { getIsStepCompleted } from '@/features/account/redux/account.selectors';

const useAccountDataPolling = (verificationStatus: VerificationStatus) => {
  const dispatch = useDispatch();
  const isVerificationCompleted = useTypedSelector((state) =>
    getIsStepCompleted(state, 'verification'),
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      verificationStatus === 'pending' ||
      // Sometimes webhooks need more time to change the status to pending ( processing ) and it remains in created session state
      // In this case we need to enable pooling as well because he is verified, but not yet caught from webhooks by backend
      (isVerificationCompleted && verificationStatus === 'uninitialized')
    ) {
      const intervalId = setInterval(() => {
        dispatch(AccountService.util.invalidateTags([AccountCacheTags.REQUEST_ME_DATA]));
      }, 15 * 1000); // check every 15 seconds
      return () => clearInterval(intervalId);
    }
  }, [dispatch, verificationStatus, isVerificationCompleted]);
};

export default useAccountDataPolling;
