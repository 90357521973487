import { Route } from '@/core/types';
import { booking, bookingSingle } from './routes';
import Booking from '@/core/pages/Booking/Booking';
import BookingOverview from '@/core/pages/Booking/BookingOverview';

const bookingRoutes: Route = {
  ...booking,
  element: <Booking />,
  routes: [{ ...bookingSingle, element: <BookingOverview /> }],
};

export default [bookingRoutes];
