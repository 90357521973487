import { useCallback } from 'react';
import { useRequestTenantInfoQuery } from '@/features/auth/services/Tenant.service';
import { TenantFeatures } from '@/features/auth/enums';

const useFeatureCheck = () => {
  const { data: tenantData } = useRequestTenantInfoQuery();

  return useCallback(
    (feature: TenantFeatures) => {
      if (!tenantData) return false;

      return !!tenantData.features?.find((feat) => feat.key === feature);
    },
    [tenantData],
  );
};

export default useFeatureCheck;
