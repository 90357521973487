import restClient from '@/core/api/restClient';
import { AvailableLocales } from '@/core/enums';
import { i18n } from '@lingui/core';

export const activateLocale = async (locale: AvailableLocales = AvailableLocales.ENGLISH) => {
  restClient.defaults.headers['Accept-Language'] = locale;
  const { messages } = await import(`../locales/${locale}.po`);

  i18n.load(locale, messages);
  i18n.activate(locale);
};
