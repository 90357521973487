import { FC } from 'react';
import styles from '@/features/booking/assets/scss/components/Header.module.scss';
import { Button, Column, Row, Svg } from '@gourban/ui-components';
import { useNavigate } from 'react-router-dom';
import WhiteLabelLogo from '@/core/components/UI/WhiteLabelLogo';
import UserAvatarDropdown from '@/core/components/UI/UserAvatarDropdown';

export interface HeaderT {
  hasBackButton?: boolean;
  onBackButtonClick?: () => void;
}

const Header: FC<HeaderT> = ({ hasBackButton, onBackButtonClick }) => {
  const navigate = useNavigate();

  return (
    <Row noWrap alignItems="center" className={styles.header}>
      <Column sm={6} md={6} lg={2} className={styles.header__logo}>
        <Button
          variation="link"
          className={styles['header__logo--expand-clickable-area']}
          noPadding
          onClick={() => (onBackButtonClick ? onBackButtonClick() : navigate('/'))}
        >
          {hasBackButton && <Svg icon="arrowLeft" />}
          <WhiteLabelLogo />
        </Button>
      </Column>
      <Column grow className={styles.header__filters}>
        {/* Filters are being portaled here from feature/bookings/components/Filters */}
        <div id="booking-filters" className={styles['header__filters-container']} />
      </Column>
      <Column sm={6} md={6} lg={2} className={styles.header__buttons}>
        <Row gapSm="xs" alignItems="center" justify="flex-end">
          <Column>
            <UserAvatarDropdown />
          </Column>
        </Row>
      </Column>
    </Row>
  );
};

export default Header;
