import { entries } from 'lodash';
import { BookingFiltersForm } from '@/features/booking/types';

export const getNumberOfActiveFilters = (
  vehicleAttributes: BookingFiltersForm['vehicleAttributes'],
): null | number => {
  if (!vehicleAttributes) return null;

  return entries(vehicleAttributes).reduce((activeFilters, [, attributeValue]) => {
    if (attributeValue) {
      activeFilters += 1;
    }

    return activeFilters;
  }, 0);
};
